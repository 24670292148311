import axiosInstance from '../common/instance';

import { TopupPrizeCancelDataType, TopupPrizeDataType, TopupPrizeGenerateDataType } from './types';

export const getAllTopupPrizeService = async (params?: CountingPrizeParamsType):
  Promise<TopupPrizeDataType> => {
  const res = await axiosInstance.get('topup-prizes/count', { params });
  return res.data.data;
};

export const cancelTopupPrizeService = async (params: TopupPrizeCancelDataType) => {
  const formData = new FormData();
  if (params.file) {
    formData.append('file', params.file);
  }
  formData.append('reason', params.reason);
  const res = await axiosInstance.post('topup-prizes/cancel', formData);
  return res.data;
};

export const createTopupPrizeService = async (params: TopupPrizeGenerateDataType) => {
  const res = await axiosInstance.post('topup-prizes/generate', params);
  return res.data;
};
