import {
  DeleteOutlined, EditOutlined, PlusOutlined, UnlockOutlined
} from '@ant-design/icons';
import {
  Button, message, Modal, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps, FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import {
  changeStatusProvinceLeaderManyIdService,
  clearLimitReachedProvinceLeaderService,
  deleteProvinceLeaderService,
  getProvinceLeaderService
} from 'common/services/provinceLeader';
import { ROUTE_PATHS } from 'common/utils/constant';
import { detectError, formatDateTime } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

export type ProvinceLeaderType = {
  id: number;
  name: string;
  username?: string;
  bankName?: string;
  bankAccNo?: string;
  createdAt: string;
  updatedAt: string;
  status: number;
  codeVerificationLimitReached: boolean;
};

const ProvinceLeader: React.FC<ActiveRoles> = ({
  roleCreate, roleView, roleDelete, roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );
  const formatStatus = (data: number) => {
    switch (data) {
      case 1: return t('system.active');
      case 7: return t('system.inactive');
      default: return t('system.inactive');
    }
  };
  /* Selectors */
  const {
    system: {
      defaultPageSize,
      advancedFilter,
      defaultWebsiteLanguage,
    },
    auth: {
      roles: roleUser
    }
  } = useAppSelector((state) => state);

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');

  /* Queries */
  const { data: provinceLeader, isLoading } = useQuery(
    ['getAllProvinceLeader', keyword, currentPage, currentView, selectedFilterList],
    () => getProvinceLeaderService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
  );

  const { mutate: deleteProvinceLeaderMutate, isLoading: deleteStaticBlockLoading } = useMutation(
    ['provinceLeaderDelete'],
    async (ids: number[]) => deleteProvinceLeaderService({ ids }),
    {
      onSuccess: () => {
        message.success(t('message.deleteSuccess'));
        queryClient.invalidateQueries(['getAllProvinceLeader']);
      },
      onError: () => {
        message.error(t('message.deleteError'));
      }
    }
  );

  const { mutate: changeStatusMutate, isLoading: changeStatusLoading } = useMutation(
    ['changeStatusProvinceLeaderManyIdService'],
    async (
      data:
        {
          ids: number[],
          status: number
        }
    ) => changeStatusProvinceLeaderManyIdService(data.ids, data.status),
    {
      onSuccess: () => {
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries('getAllProvinceLeader');
      },
      onError: () => {
        message.error(t('message.updateStatusError'));
      }
    }
  );

  const { mutate: clearLimitedMutate, isLoading: clearLimitedLoading } = useMutation(
    ['clear-limit-reached'],
    (id: string) => clearLimitReachedProvinceLeaderService(id),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['getAllProvinceLeader', keyword, currentPage, currentView, selectedFilterList]);
      },
      onError(err: unknown) {
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(`${t('system.data')} ${t(detectError(Number(err.response?.status)))}`);
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        }
      }
    }
  );

  /* Variables */
  const columns: ColumnsType<ProvinceLeaderType> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: ProvinceLeaderType, b: ProvinceLeaderType) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ProvinceLeaderType) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ProvinceLeaderType) => (
        <Typography.Text
          onClick={() => roleView && navigate(`${ROUTE_PATHS.PROVINCE_LEADER_DETAIL}?id=${data.id}`)}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('system.username'),
      dataIndex: 'username',
      key: 'username',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ProvinceLeaderType) => (
        <Typography.Text
          onClick={() => roleView && navigate(`${ROUTE_PATHS.PROVINCE_LEADER_DETAIL}?id=${data.id}`)}
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.username}
        </Typography.Text>
      ),
    },
    {
      title: t('provinceLeader.bankName'),
      dataIndex: 'bankName',
      key: 'bankName',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ProvinceLeaderType) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.bankName}
        </Typography.Text>
      ),
    },
    {
      title: t('provinceLeader.bankAccNo'),
      dataIndex: 'bankAccNo',
      key: 'bankAccNo',
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: ProvinceLeaderType) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.bankAccNo}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: {
        compare: (a: ProvinceLeaderType, b: ProvinceLeaderType) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ProvinceLeaderType) => (
        <Typography.Text>
          {formatStatus(data.status)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: ProvinceLeaderType, b: ProvinceLeaderType) => {
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ProvinceLeaderType) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: ProvinceLeaderType, b: ProvinceLeaderType) => {
          const aDate = new Date(a.updatedAt);
          const bDate = new Date(b.updatedAt);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: ProvinceLeaderType) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },

    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: ProvinceLeaderType) => (
        <Space>
          <Button
            disabled={!roleUpdate}
            icon={<EditOutlined />}
            onClick={() => navigate(`${ROUTE_PATHS.PROVINCE_LEADER_DETAIL}?id=${data.id}`)}
          />
          <Button
            disabled={!roleDelete}
            icon={<DeleteOutlined />}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                title: t('message.confirmDeleteRecord'),
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                onOk: () => {
                  deleteProvinceLeaderMutate([data.id]);
                },
              });
            }}
          />
          <Button
            disabled={!(data.codeVerificationLimitReached
              && getPermission(roleUser, roles.PROVINCE_LEADER_BLOCK_CLEAR_LIMITED))}
            loading={clearLimitedLoading}
            icon={<UnlockOutlined />}
            onClick={() => {
              Modal.confirm({
                className: 't-pagetable_deleteRecordModal',
                autoFocusButton: 'cancel',
                title: t('message.confirmClearLimited'),
                okText: t('system.ok'),
                cancelText: t('system.cancel'),
                cancelButtonProps: {
                  type: 'primary',
                },
                okButtonProps: {
                  type: 'default',
                },
                onOk: () => {
                  clearLimitedMutate(data.id.toString());
                },
              });
            }}
          />
        </Space>
      ),
    },
  ];

  const provinceLeaderData: ProvinceLeaderType[] = useMemo(() => (
    provinceLeader?.data?.map((val) => ({
      ...val.provinceLeaderData,
      codeVerificationLimitReached: val.codeVerificationLimitReached
    })) || []), [provinceLeader]);

  /* Functions */
  const onDelete = async (data: ProvinceLeaderType[], code?: string) => {
    switch (code) {
      case 'allRow':
      case 'all': {
        deleteProvinceLeaderMutate(data.map((item) => item.id));
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('provinceLeader', advancedFilter),
    [advancedFilter]
  );

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
    handleSetCurrentPage(1);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
    handleSetCurrentPage(1);
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('provinceLeader.provinceLeader')}
        rightHeader={(
          <Button
            type="primary"
            disabled={!roleCreate}
            onClick={() => navigate(`${ROUTE_PATHS.PROVINCE_LEADER_DETAIL}?locale=${defaultWebsiteLanguage}`)}
          >
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleDelete={onDelete}
          handleSearch={setKeyword}
          isLoading={isLoading || deleteStaticBlockLoading}
          isActiveStatus
          roles={{
            roleCreate: false,
            roleDelete,
            roleUpdate
          }}
          tableProps={{
            initShowColumns: ['id',
              'name',
              'username',
              'bankName',
              'bankAccNo',
              'status',
              'action'],
            columns,
            pageData: provinceLeaderData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: provinceLeader?.meta.total,
            noDeleteLanguage: true,
            noBaseCol: true,
            filterFields
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          statusDataTable={{
            handleChangeStatus:
              (data, status) => changeStatusMutate({ ids: data.map((item) => item.id), status }),
            changeStatusLoading,
          }}
        />
      </div>
    </>
  );
};

export default ProvinceLeader;
