import {
  CreatePrizeExchangeParamsTypes,
  PrizeExchangesTypes,
  CreatePrizeExchangeTypes,
  ExportPrizeExchangeType,
  CheckExportPrizeExchangeType,
  CancelPrizeExchangeRequestType,
  ReportedPrizeExchangeRequestTypes,
  UpdatePrizeExchangeParamsTypes,
  SummaryStatisticsPrizeExchangeTypes,
  InfoByPhoneTypes,
  PrizeExchangeDetailTypes,
  WeekStatisticsPrizeExchangeTypes,
  WeekStatisticsPrizeExchangeParamsTypes,
  StatisticFilterTypes,
  RegionStatisticsPrizeExchangeParamsTypes,
  RegionStatisticsPrizeExchangeTypes,
  ProvinceStatisticsPrizeExchangeParamsTypes,
  ProvinceStatisticsPrizeExchangeTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getPrizeExchangesList = async (params?: Pick<BaseFilterParams, 'limit' | 'page'>)
  : Promise<APIPaginationResponse<PrizeExchangesTypes[]>> => {
  const res = await axiosInstance.get('prize-exchanges', { params });
  return res.data;
};

export const getPrizeExchangeById = async (id: number)
  : Promise<PrizeExchangeDetailTypes> => {
  const res = await axiosInstance.get(`prize-exchanges/${id}`);
  return res.data.data;
};

export const createPrizeExchange = async (
  params: CreatePrizeExchangeParamsTypes
): Promise<CreatePrizeExchangeTypes> => {
  const res = await axiosInstance.post('prize-exchanges', params);
  return res.data;
};

export const updatePrizeExchange = async (
  params: {
    id: number;
    data: UpdatePrizeExchangeParamsTypes
  }
): Promise<void> => {
  const { data, id } = params;
  await axiosInstance.put(`prize-exchanges/${id}`, data);
};

export const exportPrizeExchange = async (params?: any): Promise<ExportPrizeExchangeType> => {
  const res = await axiosInstance.post('prize-exchanges/export', null, { params });
  return res.data;
};

export const exportPrizeExchangeByProvince = async (params?: any):
  Promise<ExportPrizeExchangeType> => {
  const res = await axiosInstance.post('prize-exchanges/export-by-province', null, { params });
  return res.data;
};

export const checkExportPrizeExchangeByProvince = async ():
  Promise<CheckExportPrizeExchangeType> => {
  const res = await axiosInstance.get('prize-exchanges/check-export-by-province');
  return res.data;
};

export const cancelPrizeExchange = async (data: CancelPrizeExchangeRequestType) => {
  const { id, reason } = data;
  await axiosInstance.put(`prize-exchanges/${id}/cancel`, { reason });
};

export const reportedPrizeExchange = async (
  params: ReportedPrizeExchangeRequestTypes
) => {
  const { id, data } = params;
  await axiosInstance.post(`prize-exchanges/${id}/exchange`, data);
};

export const summaryStatisticsPrizeExchange = async ():
  Promise<SummaryStatisticsPrizeExchangeTypes> => {
  const res = await axiosInstance.get('prize-exchanges/summary-statistics');
  return res.data.data;
};

export const weekStatisticsPrizeExchange = async (params?: WeekStatisticsPrizeExchangeParamsTypes):
  Promise<WeekStatisticsPrizeExchangeTypes[]> => {
  const res = await axiosInstance.get('prize-exchanges/week-statistics', { params });
  return res.data.data;
};

export const getInfoPrizeExchangeByPhone = async (data: {
  phone: string,
  params?: BaseFilterParams
}):
  Promise<APIPaginationResponse<InfoByPhoneTypes[]>> => {
  const res = await axiosInstance.get(`prize-exchanges/phone/${data.phone}`, { params: data.params });
  return res.data;
};

export const approvePrizeExchangeDocuments = async (id: number, order: number):
  Promise<void> => {
  await axiosInstance.post(`prize-exchanges/${id}/approve-document`, { order });
};

export const rejectPrizeExchangeDocuments = async (id: number):
  Promise<void> => {
  await axiosInstance.post(`prize-exchanges/${id}/reject-document`);
};

export const receivePrizeExchangeHardDocuments = async (id: number):
  Promise<void> => {
  await axiosInstance.post(`prize-exchanges/${id}/receive-hard-document`);
};

export const getStatisticFilterPrizeExchange = async ():
  Promise<StatisticFilterTypes> => {
  const res = await axiosInstance.get('prize-exchanges/statistics-filters');
  return res.data;
};

export const regionStatisticsPrizeExchange = async (
  params?: RegionStatisticsPrizeExchangeParamsTypes
):
  Promise<RegionStatisticsPrizeExchangeTypes[]> => {
  const res = await axiosInstance.get('prize-exchanges/region-statistics', { params });
  return res.data.data;
};

export const provinceStatisticsPrizeExchange = async (
  params?: ProvinceStatisticsPrizeExchangeParamsTypes
):
  Promise<ProvinceStatisticsPrizeExchangeTypes[]> => {
  const res = await axiosInstance.get('prize-exchanges/province-statistics', { params });
  return res.data.data;
};

export const updateAgentNotePrizeExchange = async (params: {
  id: number, data: {
    content: string
  }
}):
  Promise<void> => {
  await axiosInstance.put(`prize-exchanges/${params.id}/call-center-agent-note`, params.data);
};

export const idCardPrizeExchange = async (id: number):
  Promise<void> => {
  await axiosInstance.post(`prize-exchanges/${id}/id-card`);
};
