import {
  CashFlowDataTypes, CashFlowRequestType, SaleCashFlowDataTypes, SaleCashFlowRequestType
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getCashFlowsService = async (params: Pick<BaseFilterParams, 'limit' | 'page'>):
  Promise<APIPaginationResponse<CashFlowDataTypes[]>> => {
  const res = await axiosInstance.get('cashflows', { params });
  return res.data;
};

export const getSaleCashFlowsService = async (params: Pick<BaseFilterParams, 'limit' | 'page'>):
  Promise<APIPaginationResponse<SaleCashFlowDataTypes[]>> => {
  const res = await axiosInstance.get('sale-cashflows', { params });
  return res.data;
};

export const createCashFlowService = async (data: CashFlowRequestType):
  Promise<{ data: { id: number } }> => {
  const res = await axiosInstance.post('cashflows', data);
  return res.data;
};

export const postCashFlowAllotService = async (data: SaleCashFlowRequestType):
  Promise<void> => {
  await axiosInstance.post('cashflows/allot', data);
};
