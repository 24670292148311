import axiosInstance from '../common/instance';

import { CreateParamsTypes, DeleteParamsTypes, GetProvinceLeaderDataType } from './types';

export const getProvinceLeaderService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<GetProvinceLeaderDataType[]>> => {
  const res = await axiosInstance.get('province-leaders', { params });
  return res.data;
};

export const deleteProvinceLeaderService = async (
  params: DeleteParamsTypes
) => axiosInstance.delete('province-leaders', { data: params });

export const getProvinceLeaderByIdService = async (
  id: string
): Promise<GetProvinceLeaderDataType> => {
  const res = await axiosInstance.get(`province-leaders/${id}`);
  return res.data.data;
};

export const createProvinceLeaderService = async (
  params: CreateParamsTypes
): Promise<{ id: number }> => {
  const res = await axiosInstance.post('province-leaders', params);
  return res.data.data;
};

export const updateProvinceLeaderService = async (
  id: number,
  params?: CreateParamsTypes
): Promise<unknown> => {
  const res = await axiosInstance.put(`province-leaders/${id}`, params);
  return res.data;
};

export const updateStatusProvinceLeaderService = async (id: number, status: number)
  : Promise<void> => {
  await axiosInstance.put(`province-leaders/${id}/update-status`, { status });
};

export const changeStatusProvinceLeaderManyIdService = async (ids: number[], status: number)
  : Promise<void> => {
  await axiosInstance.put('province-leaders/update-status-with-many-id', { status, ids });
};

export const clearLimitReachedProvinceLeaderService = async (
  id: string
): Promise<void> => {
  await axiosInstance.post(`province-leaders/${id}/clear-code-verification-limiter`);
};
