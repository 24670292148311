import {
  Button, Col, Row, Typography
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import PopupCancelCode from './PopupCancelCode';
import PopupCreateCode from './PopupCreateCode';

import { getAllTopupPrizeService } from 'common/services/topupPrize';

const TopupPrizeManagement: React.FC<ActiveRoles> = () => {
  const [isPopupCancelCode, setIsPopupCancelCode] = useState(false);
  const [isPopupCreateCode, setIsPopupCreateCode] = useState(false);

  /* Hooks */
  const { t } = useTranslation();

  const { data: topupPrize } = useQuery(
    ['getAllTopupPrizeService'],
    () => getAllTopupPrizeService(),
  );

  /* Render */
  return (
    <>
      <div className="p-topupPrize_submit">
        <Button
          type="default"
          style={{
            background: '#015714',
            height: 44,
            borderRadius: 4,
            color: '#fff',
            fontSize: 14
          }}
          onClick={() => setIsPopupCreateCode(true)}
        >
          {t('topupPrize.createCode')}
        </Button>
        <div className="p-topupPrize_button">
          <Button
            style={{
              background: '#015714',
              height: 44,
              borderRadius: 4,
              color: '#fff',
              fontSize: 14
            }}
            type="default"
            onClick={() => setIsPopupCancelCode(true)}
          >
            {t('topupPrize.cancelCode')}
          </Button>
        </div>
      </div>
      <Row className="t-mainlayout_wrapper">
        <Col xxl={6} xl={8} lg={8}>
          <div className="p-topupPrize_wrapText">
            <Typography.Text
              style={
                {
                  fontSize: 16,
                  color: '#222'
                }
              }
            >
              {t('topupPrize.totalCodeNumber')}
            </Typography.Text>
            <Typography.Text
              style={
                {
                  fontSize: 40,
                  color: '#222'
                }
              }
              className="u-ml-21"
            >
              {topupPrize?.total}
            </Typography.Text>
          </div>
        </Col>
        <Col xxl={6} xl={8} lg={8}>
          <div className="p-topupPrize_wrapText">
            <Typography.Text style={
              {
                fontSize: 16,
                color: '#222'
              }
            }
            >
              {t('topupPrize.numberOfVerifiedCode')}
            </Typography.Text>
            <Typography.Text
              style={
                {
                  fontSize: 40,
                  color: '#222'
                }
              }
              className="u-ml-21"
            >
              {topupPrize?.totalWon}
            </Typography.Text>
          </div>
        </Col>
        <Col xxl={6} xl={8} lg={8}>
          <div className="p-topupPrize_wrapText">
            <Typography.Text style={
              {
                fontSize: 16,
                color: '#222'
              }
            }
            >
              {t('topupPrize.numberOfCodesCanceled')}
            </Typography.Text>
            <Typography.Text
              style={
                {
                  fontSize: 40,
                  color: '#222'
                }
              }
              className="u-ml-21"
            >
              {topupPrize?.totalCanceled}
            </Typography.Text>
          </div>
        </Col>
      </Row>
      <PopupCancelCode
        isShow={isPopupCancelCode}
        handleClose={() => setIsPopupCancelCode(false)}
      />
      <PopupCreateCode
        isShow={isPopupCreateCode}
        handleClose={(callback) => {
          if (callback) callback();
          setIsPopupCreateCode(false);
        }}
      />
    </>
  );
};

export default TopupPrizeManagement;
