import {
  Button, Col, DatePicker, Row, Space, Typography
} from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { useAppSelector } from 'app/store';
import { ConvertReward } from 'common/components/ConvertReward';
import { getAllFirstPrizeService } from 'common/services/firstPrize';
import { getAllSecondPrizeService } from 'common/services/secondPrize';
import { getAllTopupPrizeService } from 'common/services/topupPrize';
import { formatDateYYYYMMDD } from 'common/utils/functions';
import roles from 'configs/roles';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
const curTime = `${moment().get('hour')}:${moment().get('minute')}`;
const defaultDateValue = [
  moment(new Date()).subtract(7, 'days').format(dateFormat),
  `${moment(new Date()).subtract(1, 'day').format(dateFormat)} 23:59`,
];

const HeadingPrizeSection: React.FC = () => {
  const rolesUser = useAppSelector((state) => state.auth.roles);
  const [dateRange, setDateRange] = useState(defaultDateValue);

  const { t } = useTranslation();

  const { mutate: getAllFirstPrizeMutate, data: firstPrize } = useMutation(
    ['getAllFirstPrizeService'],
    () => getAllFirstPrizeService({
      fromDate: moment(dateRange[0]).toISOString(),
      toDate: moment(dateRange[1]).toISOString(),
    })
  );

  const { mutate: getAllSecondPrizeMutate, data: secondPrize } = useMutation(
    ['getAllSecondPrizeService'],
    () => getAllSecondPrizeService({
      fromDate: moment(dateRange[0]).toISOString(),
      toDate: moment(dateRange[1]).toISOString(),
    })
  );

  const { mutate: getAllTopupPrizeMutate, data: topupPrize } = useMutation(
    ['getAllTopupPrizeService'],
    () => getAllTopupPrizeService({
      fromDate: moment(dateRange[0]).toISOString(),
      toDate: moment(dateRange[1]).toISOString(),
    })
  );
  const onCountingPrize = () => {
    getAllFirstPrizeMutate();
    getAllSecondPrizeMutate();
    getAllTopupPrizeMutate();
  };

  const calGrowthRate = (curVal: number, pastVal: number) => {
    if (!pastVal) return NaN;
    return Math.round(((curVal - pastVal)
      / pastVal) * 100);
  };

  const growthRate = useMemo(() => ({
    firstPrize: calGrowthRate((firstPrize?.totalWon || 0), (firstPrize?.totalWonPrevious || 0)),
    secondPrize: calGrowthRate(
      (secondPrize?.totalQuantity || 0),
      (secondPrize?.totalWonPrevious || 0)
    ),
    topupPrize: calGrowthRate((topupPrize?.totalWon || 0), (topupPrize?.totalWonPrevious || 0)),
  }), [firstPrize, secondPrize, topupPrize]);

  useEffect(() => {
    if (
      rolesUser.includes('*')
      || rolesUser.includes(roles.FIRST_PRIZE_BLOCK_STATISTICS)
    ) { getAllFirstPrizeMutate(); }
    if (
      rolesUser.includes('*')
      || rolesUser.includes(roles.SECOND_PRIZE_BLOCK_STATISTICS)
    ) { getAllSecondPrizeMutate(); }
    if (
      rolesUser.includes('*')
      || rolesUser.includes(roles.TOPUP_PRIZE_STATISTICS)
    ) { getAllTopupPrizeMutate(); }
  }, []);

  return (
    <>
      <Space style={{ width: '100%', justifyContent: 'space-between' }}>
        <Typography.Title level={3}>{t('dashboard.general')}</Typography.Title>
        <Space>
          <RangePicker
            style={{ width: '100%' }}
            defaultValue={[moment(dateRange[0], dateFormat),
            moment(dateRange[1], dateFormat)]}
            disabledDate={(cur) => cur > moment()}
            onChange={(val) => {
              if (!val) return;
              const res = val.map((item, idx) => {
                if (idx === 1) {
                  const isToday = formatDateYYYYMMDD(item) === formatDateYYYYMMDD(moment());
                  return `${formatDateYYYYMMDD(item)} ${isToday ? curTime : '23:59'}`;
                }
                return formatDateYYYYMMDD(item);
              });
              setDateRange(res);
            }}
          />
          <Button type="primary" onClick={onCountingPrize}>Lọc</Button>
        </Space>
      </Space>
      <div className="u-mt-20">
        <Row gutter={24}>
          <Col lg={8}>
            <ConvertReward
              title={t('dashboard.titleFirstReward')}
              sended={firstPrize?.totalWon || 0}
              total={(firstPrize?.total || 0) - (firstPrize?.totalCanceled || 0)}
              growthRate={growthRate.firstPrize}
              type="gold"
            />
          </Col>
          <Col lg={8}>
            <ConvertReward
              sended={secondPrize?.totalRedeemed || 0}
              title={t('dashboard.titleSecondReward')}
              total={(Number(secondPrize?.totalQuantity) || 0)
                - (Number(secondPrize?.totalCanceledQuantity) || 0)}
              growthRate={growthRate.secondPrize}
              type="cash"
            />
          </Col>
          <Col lg={8}>
            <ConvertReward
              sended={topupPrize?.totalWon || 0}
              title={t('dashboard.titleThirdReward')}
              total={(topupPrize?.total || 0) - (topupPrize?.totalCanceled || 0)}
              growthRate={growthRate.topupPrize}
              type="phone"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HeadingPrizeSection;
