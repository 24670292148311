import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Space,
  Switch,
  Typography,
  message
} from 'antd';
import { AxiosError } from 'axios';
import moment from 'moment';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Controller, FormProvider, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import ModalConfirm from 'common/components/ModalConfirm';
import {
  clearLimitReachedProvinceLeaderService,
  createProvinceLeaderService,
  getProvinceLeaderByIdService,
  updateProvinceLeaderService,
  updateStatusProvinceLeaderService
} from 'common/services/provinceLeader';
import { CreateParamsTypes } from 'common/services/provinceLeader/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { detectError } from 'common/utils/functions';
import { editProvinceLeaderData } from 'common/utils/schemas';
import roles, { getPermission } from 'configs/roles';

type EditProvinceTypes = {
  name: string,
  username: string,
  password?: string,
  provinceCodes: any[],
  bankName: string,
  bankAccNo: string,
};

const ACTIVE_STATUS = 1;
const UN_ACTIVE_STATUS = 7;
const EditProvinceLeader: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate, roleOther }) => {
  const { t } = useTranslation();
  const method = useForm<EditProvinceTypes>({
    resolver: yupResolver(editProvinceLeaderData),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      username: '',
      password: '',
      provinceCodes: [],
      bankName: '',
      bankAccNo: '',
    }
  });
  /* Hooks */

  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const idParam = Number(searchParams.get('id'));
  /* States */
  const [confirm, setConfirm] = useState<string | undefined>(undefined);
  const [checkedStatus, setCheckedStatus] = useState(false);

  /* Queries */
  const queryKeyDetail = ['getDataProvinceLead', idParam];
  const queryClient = useQueryClient();
  const { data: dataProvinceLead, isLoading } = useQuery(
    queryKeyDetail,
    () => {
      if (idParam) {
        return getProvinceLeaderByIdService(String(idParam));
      }
      return undefined;
    }
  );

  const { mutate: updateProvinceLeaderByIdMutate, isLoading: updateLoading } = useMutation(
    'updateProvinceLeaderService',
    (
      params: { id: number; data: CreateParamsTypes }
    ) => updateProvinceLeaderService(params.id, params.data),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: (error: any) => {
        if (error.length > 0) {
          error.forEach((element: ErrorResponse) => {
            method.setError((element.field as any), { message: element.message });
          });
        } else message.error(t('message.anErrorHasOccurred'));
      }
    }
  );

  const { mutate: createProvinceLeaderMutate, isLoading: createLoading } = useMutation(
    'createRate',
    createProvinceLeaderService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigator(`${ROUTE_PATHS.PROVINCE_LEADER_MANAGEMENT}`);
      },
      onError: (err: ErrorResponse[]) => {
        if (err.length > 0) {
          err.forEach((
            e
          ) => method.setError(e.field as keyof EditProvinceTypes, { message: e.message }));
        }
        message.error(t('message.createError'));
      }
    }
  );

  /* Functions */

  const handleSubmit = async (callback?: () => void) => {
    const result = await method.trigger();
    if (!result) {
      return;
    }

    const mainData = method.getValues();

    const dataSubmit: CreateParamsTypes = {
      name: mainData.name,
      username: mainData.username,
      ...mainData.password && { password: mainData.password },
      provinceCodes: mainData.provinceCodes
        .reduce((val, curr) => (
          curr?.value ? [...val, curr.value.toString()] : [...val, curr]), [] as string[]),
      bankName: mainData.bankName,
      bankAccNo: mainData.bankAccNo,
    };
    if (idParam) {
      updateProvinceLeaderByIdMutate({ id: idParam, data: dataSubmit }, {
        onSuccess: () => {
          if (callback) callback();
        }
      });
    } else {
      createProvinceLeaderMutate(dataSubmit);
    }
  };
  /* Effects */
  useEffect(() => {
    if (dataProvinceLead) {
      setCheckedStatus(dataProvinceLead.provinceLeaderData.status === 1);
      method.reset({
        name: dataProvinceLead.provinceLeaderData.name,
        username: dataProvinceLead.provinceLeaderData.username,
        provinceCodes: dataProvinceLead.provinces?.map(
          (item) => ({ label: item.name, value: item.code })
        ),
        bankName: dataProvinceLead.provinceLeaderData.bankName,
        bankAccNo: dataProvinceLead.provinceLeaderData.bankAccNo,
      });
    } else {
      method.reset();
    }
  }, [dataProvinceLead, method]);

  const { mutate: updateStatusMutate } = useMutation(
    'updateStatusProvinceLeaderService',
    async (data: {
      id: number, statusId: number
    }) => updateStatusProvinceLeaderService(data.id, data.statusId),
    {
      onSuccess: () => {
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries(queryKeyDetail);
      },
      onError: () => {
        message.error(t('message.updateStatusError'));
      }
    }
  );

  const { mutate: clearLimitedMutate, isLoading: clearLimitedLoading } = useMutation(
    ['clear-limit-reached'],
    (id: string) => clearLimitReachedProvinceLeaderService(id),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryKeyDetail);
      },
      onError(err: unknown) {
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(`${t('system.data')} ${t(detectError(Number(err.response?.status)))}`);
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        }
      }
    }
  );

  const { isDirty } = method.formState;

  const handleChangeStatusAndSave = async () => {
    if (!idParam) {
      await handleSubmit();
    } else if (isDirty) {
      await handleSubmit(() => {
        updateStatusMutate(
          { id: idParam, statusId: checkedStatus ? UN_ACTIVE_STATUS : ACTIVE_STATUS }
        );
      });
    } else {
      updateStatusMutate(
        { id: idParam, statusId: checkedStatus ? UN_ACTIVE_STATUS : ACTIVE_STATUS }
      );
    }
    setCheckedStatus(!checkedStatus);
  };

  const submitForm = async () => {
    if (isDirty) {
      await handleSubmit(() => {
        updateStatusMutate({ id: idParam, statusId: 1 });
      });
    } else {
      await handleSubmit();
    }
  };

  const updateLangeState = (val: string) => {
    setSearchParams({
      id: String(idParam),
      locale: val,
    });
    setConfirm(undefined);
  };

  const exceptionSlugs = useMemo(() => dataProvinceLead?.provinces.map((item) => item.code).join(','), [dataProvinceLead]);

  return (
    <>
      <HeaderPage
        fixed
        title={idParam ? t('provinceLeader.pageEdit') : t('provinceLeader.pageCreate')}
        rightHeader={(
          <Space size={16}>
            <Typography.Text strong>
              {t('system.status')}
            </Typography.Text>
            <Switch
              checked={checkedStatus}
              onChange={handleChangeStatusAndSave}
            />
            <Button
              disabled={!(dataProvinceLead?.codeVerificationLimitReached
                && getPermission(roleOther, roles.PROVINCE_LEADER_BLOCK_CLEAR_LIMITED))}
              loading={clearLimitedLoading}
              onClick={() => {
                Modal.confirm({
                  className: 't-pagetable_deleteRecordModal',
                  autoFocusButton: 'cancel',
                  title: t('message.confirmClearLimited'),
                  okText: t('system.ok'),
                  cancelText: t('system.cancel'),
                  cancelButtonProps: {
                    type: 'primary',
                  },
                  okButtonProps: {
                    type: 'default',
                  },
                  onOk: () => {
                    if (dataProvinceLead?.provinceLeaderData) {
                      clearLimitedMutate(dataProvinceLead?.provinceLeaderData.id.toString());
                    }
                  },
                });
              }}
            >
              Clear Limited

            </Button>
            <Button
              type="primary"
              disabled={(idParam && !roleUpdate) || (!idParam && !roleCreate)}
              onClick={method.handleSubmit(submitForm)}
              loading={updateLoading || isLoading || createLoading}
            >
              <SaveOutlined />
              {t('system.save')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <FormProvider {...method}>
          <Row gutter={16}>
            <Col xxl={18} xl={16} lg={16}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Typography.Text strong>
                      {t('system.name')}
                    </Typography.Text>
                    {' '}
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="name"
                      control={method.control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={12}>
                    <Typography.Text strong>
                      {t('system.username')}
                    </Typography.Text>
                    {' '}
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="username"
                      control={method.control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={12} className="u-mt-10">
                    <Typography.Text strong>
                      {t('login.password')}
                    </Typography.Text>
                    {' '}
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="password"
                      control={method.control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          type="password"
                          className="u-mt-8"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={12} className="u-mt-10">
                    <Typography.Text strong>
                      {t('provinceLeader.bankName')}
                    </Typography.Text>
                    {' '}
                    <Controller
                      name="bankName"
                      control={method.control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={24} className="u-mt-10">
                    <Typography.Text strong>
                      {t('provinceLeader.bankAccNo')}
                    </Typography.Text>
                    {' '}
                    <Controller
                      name="bankAccNo"
                      control={method.control}
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <Input
                          className="u-mt-8"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          size="large"
                        />
                      )}
                    />
                  </Col>
                  <Col span={24} className="u-mt-10">
                    <Typography.Text strong>
                      {t('provinceLeader.provinceCode')}
                    </Typography.Text>
                    <Typography.Text strong type="danger">
                      *
                    </Typography.Text>
                    <Controller
                      name="provinceCodes"
                      render={({
                        field: { value, onChange },
                        fieldState: { error },
                      }) => (
                        <DropdownElement
                          type="province"
                          placeholder="-----"
                          locale="vi"
                          value={value}
                          onChange={onChange}
                          error={error?.message}
                          isValueSlug
                          isShowSearch
                          slugs={exceptionSlugs}
                          // filterParams={idParam.toString()}
                          multiple={{
                            allowClear: true,
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xxl={6} xl={8} lg={8}>
              <ManagementInfo
                createdDate={dataProvinceLead ? moment(dataProvinceLead.provinceLeaderData.createdAt).fromNow() : ''}
                createdBy={dataProvinceLead?.creator?.name || ''}
                lastUpdated={dataProvinceLead ? moment(dataProvinceLead.provinceLeaderData.updatedAt).fromNow() : ''}
                lastUpdatedBy={dataProvinceLead?.updater?.name || ''}
              />
            </Col>
          </Row>
        </FormProvider>
        <ModalConfirm
          isShow={!!confirm}
          handleCancel={() => setConfirm(undefined)}
          handleConfirm={() => confirm && updateLangeState(confirm)}
          handleClose={() => setConfirm(undefined)}
        >
          {t('message.confirmSkipInfo')}
        </ModalConfirm>
      </div>
    </>

  );
};

export default EditProvinceLeader;
