import {
  ExportTopUpSmsType, InfoByPhoneTopUpSMSTypes, TopUpAttemptTypes, TopUpSMSTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getTopupSMSList = async (params?: Pick<BaseFilterParams, 'limit' | 'page'>)
  : Promise<APIPaginationResponse<TopUpSMSTypes[]>> => {
  const res = await axiosInstance.get('topup-prizes/sms', { params });
  return res.data;
};

export const getTopupAttemptList = async ({ id, params }:
  { id: string, params: Pick<BaseFilterParams, 'limit' | 'page'> })
  : Promise<APIPaginationResponse<TopUpAttemptTypes[]>> => {
  const res = await axiosInstance.get(`topup-prizes/sms/${id}/topup-attempts`, { params });
  return res.data;
};

export const getInfoTopUpSMSByPhone = async (data: { phone: string, params?: BaseFilterParams }):
  Promise<APIPaginationResponse<InfoByPhoneTopUpSMSTypes[]>> => {
  const res = await axiosInstance.get(`topup-prizes/sms/phone/${data.phone}`, { params: data.params });
  return res.data;
};

export const exportTopUpSms = async (params?: any): Promise<ExportTopUpSmsType> => {
  const res = await axiosInstance.post('topup-prizes/sms/export', null, { params });
  return res.data;
};

export const retryTopUpSms = async (id: string): Promise<void> => {
  await axiosInstance.post(`topup-prizes/sms/${id}/retry-topup`);
};

export const buyCardTopUpSms = async (id: string): Promise<void> => {
  await axiosInstance.post(`topup-prizes/sms/${id}/buy-card`);
};

export const bulkRetryTopUpSms = async ({ ids, params }: {
  ids?: string[],
  params?: any
}): Promise<void> => {
  await axiosInstance.post('topup-prizes/sms/retry-topup', ids ? { ids } : null, { params });
};

export const bulkBuyCardTopUpSms = async ({ ids, params }: {
  ids?: string[],
  params?: any
}): Promise<void> => {
  await axiosInstance.post('topup-prizes/sms/buy-card', ids ? { ids } : null, { params });
};

export const exportIncludeAccountTopUpSms = async (params?: any): Promise<ExportTopUpSmsType> => {
  const res = await axiosInstance.post('topup-prizes/sms/export-include-account', null, { params });
  return res.data;
};
