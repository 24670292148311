import {
  TopUpAttemptTypes, TopUpSLADataTypes, TopUpSLAParamTypes, TopUpWinnerTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getTopupWinnerList = async (params?: Pick<BaseFilterParams, 'limit' | 'page'>)
  : Promise<APIPaginationResponse<TopUpWinnerTypes[]>> => {
  const res = await axiosInstance.get('topup-prizes/winners', { params });
  return res.data;
};

export const getTopupAttemptList = async ({ id, params }:
  { id: number, params: Pick<BaseFilterParams, 'limit' | 'page'> })
  : Promise<APIPaginationResponse<TopUpAttemptTypes[]>> => {
  const res = await axiosInstance.get(`topup-prizes/winners/${id}/topup-attempts`, { params });
  return res.data;
};

export const getTopupSla = async (params: TopUpSLAParamTypes)
  : Promise<APIPaginationResponse<TopUpSLADataTypes[]>> => {
  const res = await axiosInstance.get('topup-winners/sla', { params });
  return res.data;
};

export const importCancelThirdAccountTopup = async (params: { file?: File }) => {
  const formData = new FormData();
  if (params.file) {
    formData.append('file', params.file);
  }
  await axiosInstance.post('topup-winners/cancel-third-account-topup', formData);
};
