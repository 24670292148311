import {
  Card,
  Empty, Space, Spin, Typography
} from 'antd';
import React from 'react';

import ChartCustom from '../Chart';

export type ReportRewardProps = {
  title: string;
  options?: any;
  data?: any;
  idLegendTooltip?: string;
};

export const ReportReward: React.FC<ReportRewardProps> = ({
  title,
  data,
  options,
  idLegendTooltip
}) => (
  <Space direction="vertical" size={12} className="o-reportReward">
    <Typography.Title level={4} className="o-reportReward_title">{title}</Typography.Title>
    {/* <div className="o-reportReward_filter">
        <RangePicker
          style={{ width: '70%' }}
          defaultValue={[moment(dateRange[0], dateFormat),
          moment(dateRange[1], dateFormat)]}
          onChange={(val) => {
            if (!val) return;
            const res = val.map((item) => (formatDateYYYYMMDD(item)));
            setDateRange(res);
          }}
        />
        <Button type="primary">Tìm kiếm</Button>
      </div> */}
    <Card type="inner">
      <Spin size="large" spinning={false}>
        <div>
          {data
            ? (
              <ChartCustom
                type="doughnut"
                data={data}
                options={options}
                height={100}
                width={100}
                idLegendTooltip={idLegendTooltip}
              />
            ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>

      </Spin>
    </Card>
  </Space>
);
