import { InboxOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Modal, Space, Typography, message
} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import Input from 'common/components/Input';
import { createSecondPrizeService } from 'common/services/secondPrize';
import { SecondPrizeCreateDataType } from 'common/services/secondPrize/types';
import { createData } from 'common/utils/schemas';

interface PopupCreateProps {
  isShow: boolean;
  handleClose: () => void;
}

interface FormTypes {
  file: File[],
  quantity:number,
}

const PopupCreate: React.FC<PopupCreateProps> = ({ isShow, handleClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const method = useForm<FormTypes>({
    resolver: yupResolver(createData),
    mode: 'onChange',
    defaultValues: {
      file: [],
      quantity: 0,
    }
  });

  const handleCustomClose = () => {
    method.reset();
    handleClose();
  };

  const { mutate: createDataMutate } = useMutation(
    [''],
    async (params: SecondPrizeCreateDataType) => createSecondPrizeService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['getAllSecondPrizeService']);
        handleCustomClose();
        setLoading(false);
      },
      onError: (error) => {
        if (error && Array.isArray(error)) {
          error.forEach((item: ErrorResponse) => {
            if (item.field as keyof FormTypes) {
              const filedData = `${item.field?.charAt(0)?.toUpperCase()}${item.field?.slice(1)}`;
              method.setError(
                item.field as keyof FormTypes,
                { message: `${filedData} ${item.message}` }
              );
            }
          });
        } else {
          handleCustomClose();
        }
      },
    }
  );

  const onSubmit = async () => {
    const formData = method.getValues();
    createDataMutate({
      file: formData?.file?.[0],
      quantity: formData.quantity,
    });
  };

  return (
    <Modal
      title={t('firstPrize.popupCreate')}
      visible={isShow}
      footer={null}
      closable={false}
    >
      <div className="m-modalConfirm">
        <FormProvider {...method}>
          <form noValidate onSubmit={method.handleSubmit(onSubmit)}>
            <Typography.Text
              style={{ fontSize: 14 }}
              strong
            >
              quantity
            </Typography.Text>
            <Typography.Text
              style={{ fontSize: 14 }}
              strong
              type="danger"
            >
              *
            </Typography.Text>
            <Controller
              name="quantity"
              defaultValue=""
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Input
                  type="number"
                  className="u-mt-8"
                  name="quantity"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
            <div className="u-mt-12">
              <Typography.Text
                style={{ fontSize: 14 }}
                strong
              >
                {t('firstPrize.file')}
              </Typography.Text>
              <Typography.Text
                style={{ fontSize: 14 }}
                strong
                type="danger"
              >
                *
              </Typography.Text>
              <Controller
                name="file"
                render={(
                  {
                    field,
                    fieldState: { error },
                  },
                ) => (
                  <Dragger
                    accept=".xls,.xlsx"
                    fileList={field.value}
                    beforeUpload={() => false}
                    onChange={(file) => field.onChange([file.file])}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {' '}
                      {t('firstPrize.clickFile')}
                    </p>
                    {error && (
                      <span
                        className="a-input_errorMessage"
                      >
                        {error.message}
                      </span>
                    )}
                  </Dragger>
                )}
              />
            </div>
            <Space className="u-mt-12">
              <Button type="primary" loading={loading} htmlType="submit">
                {t('firstPrize.next')}
              </Button>
              <Button onClick={handleCustomClose}>
                {t('firstPrize.cancel')}
              </Button>
            </Space>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default PopupCreate;
