import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Space,
  Typography,
  message
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import StatusLabel from 'common/components/StatusLabel';
import { deleteRegionByIdService, getRegionsService } from 'common/services/location';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type RegionProps = {
  id: number;
  name: string;
  status: number;
  createdAt?: string;
  updatedAt?: string;
};

const RegionManagement: React.FC<ActiveRoles> = ({ roleCreate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [keyword, setKeyword] = useState('');

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    ['regionManagement-list', currentPage, currentView, keyword],
    () => getRegionsService({ page: currentPage, keyword, limit: currentView }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  const {
    mutate: deleteMutate,
    isLoading: deleteLoading
  } = useMutation(
    ['deleteRegion'],
    (ids: number[]) => deleteRegionByIdService({ ids }),
    {
      onSuccess() {
        message.success(t('message.deleteSuccess'));
        setDeleteId(0);
        queryClient.invalidateQueries(['regionManagement-list', currentPage, keyword]);
      },
      onError() {
        message.success(t('message.deleteError'));
      },
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<RegionProps> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      render: (_name: string, data: RegionProps) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên người dùng
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_name: string, data: RegionProps) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    // --- Trạng thái
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_name: string, data: RegionProps) => (
        <StatusLabel status={data.status} />
      ),
    },
    // --- Cập nhật
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: RegionProps, b: RegionProps) => {
          const aDate = new Date(a.createdAt || 0);
          const bDate = new Date(b.createdAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: RegionProps) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Xem
    {
      title: t('system.edit'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, _data: RegionProps) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              navigate(`${ROUTE_PATHS.REGION_DETAIL}?id=${_data.id}`);
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => setDeleteId(_data.id)}
          />
        </Space>
      ),
    },
  ]), [t, navigate]);

  const tableData: RegionProps[] = useMemo(() => (
    listData?.data?.map((item) => ({
      ...item.regionData,
      createdAt: item.regionData.createdAt || '',
    })) || []), [listData]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.activityLogs')}
        rightHeader={(
          <Button type="primary" disabled={!roleCreate} onClick={() => navigate(`${ROUTE_PATHS.REGION_DETAIL}`)}>
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || deleteLoading}
          handleSearch={handleSearch}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'name', 'status', 'createdAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
      <Modal
        visible={!!deleteId}
        onCancel={() => setDeleteId(0)}
        onOk={() => deleteMutate([deleteId])}
      >
        {t('message.confirmDeleteRecord')}
      </Modal>
    </>
  );
};

export default RegionManagement;
