import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Modal, Select, Space, Typography, message
} from 'antd';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';

import { topupAmountDummy } from 'common/assets/dummyData/system';
import Input from 'common/components/Input';
import { createTopupPrizeService } from 'common/services/topupPrize';
import { TopupPrizeGenerateDataType } from 'common/services/topupPrize/types';
import { detectError } from 'common/utils/functions';
import { crateCodeData } from 'common/utils/schemas';

interface PopupCreateCodeProps {
  isShow: boolean;
  handleClose: (callback?: () => void) => void;
}

interface FormTypes {
  email: string,
  quantity: number,
  amount: number
}

const PopupCreateCode: React.FC<PopupCreateCodeProps> = ({ isShow, handleClose }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const method = useForm<FormTypes>({
    resolver: yupResolver(crateCodeData),
    mode: 'onChange',
    defaultValues: {
      quantity: 0,
      email: '',
      amount: topupAmountDummy[0].value
    }
  });

  console.log(method.watch('amount'));

  const { mutate: updateDataMutate } = useMutation(
    [''],
    async (params: TopupPrizeGenerateDataType) => createTopupPrizeService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['getAllTopupPrizeService']);
        handleClose(() => method.reset());
        setLoading(false);
      },
      onError(err: unknown) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else {
          (err as ErrorResponse[]).forEach((e) => method.setError(e.field as keyof FormTypes, { message: t(`errorMess.${e.field}.${e.code}`) }));
        }
      }
    }
  );

  const onSubmit = async () => {
    const formData = method.getValues();
    console.log('🚀 ~ file: PopupCreateCode.tsx:68 ~ onSubmit ~ formData:', formData);
    updateDataMutate(formData);
  };

  return (
    <Modal
      title={t('topupPrize.createCode')}
      visible={isShow}
      footer={null}
      closable={false}
    >
      <div className="m-modalConfirm">
        <FormProvider {...method}>
          <form noValidate onSubmit={method.handleSubmit(onSubmit)}>
            <Typography.Text
              style={{ fontSize: 14 }}
              strong
            >
              email
            </Typography.Text>
            <Typography.Text
              style={{ fontSize: 14 }}
              strong
              type="danger"
            >
              *
            </Typography.Text>
            <Controller
              name="email"
              defaultValue=""
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Input
                  className="u-mt-8"
                  name="email"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
            <Typography.Text
              style={{ fontSize: 14 }}
              strong
            >
              {t('topupPrize.quantity')}
            </Typography.Text>
            <Typography.Text
              style={{ fontSize: 14 }}
              strong
              type="danger"
            >
              *
            </Typography.Text>
            <Controller
              name="quantity"
              defaultValue=""
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Input
                  className="u-mt-8"
                  name="quantity"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              )}
            />
            <Typography.Text
              style={{ fontSize: 14 }}
              strong
            >
              {t('topupPrize.amount')}
            </Typography.Text>
            <Typography.Text
              style={{ fontSize: 14 }}
              strong
              type="danger"
            >
              *
            </Typography.Text>
            <Controller
              name="amount"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  <Select
                    size="large"
                    style={{ width: '100%' }}
                    className="u-mt-8"
                    value={value}
                    onChange={onChange}
                  >
                    {
                      topupAmountDummy.map((item, index) => (
                        <Select.Option value={item.value} key={`option-${index.toString()}`}>
                          {item.label}
                        </Select.Option>
                      ))
                    }
                  </Select>
                  {error && (
                    <span
                      className="a-input_errorMessage"
                    >
                      {error.message}
                    </span>
                  )}
                </>
              )}
            />
            <Space className="u-mt-12">
              <Button type="primary" loading={loading} htmlType="submit">
                {t('topupPrize.next')}
              </Button>
              <Button onClick={() => handleClose(() => method.reset())}>
                {t('topupPrize.cancel')}
              </Button>
            </Space>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default PopupCreateCode;
