import {
  RegionDataTypes, ParamsTypes, RequestDataTypes, RegionDetailTypes
} from './types';

import axiosInstance from 'common/services/common/instance';

export const getRegionsService = async (params: ParamsTypes):
  Promise<APIPaginationResponse<RegionDataTypes[]>> => {
  const res = await axiosInstance.get('regions', { params });
  return res.data;
};

export const getRegionsByIdService = async (id: number):
  Promise<RegionDetailTypes> => {
  const res = await axiosInstance.get(`regions/${id}`);
  return res.data.data;
};

export const createRegionService = async (data: RequestDataTypes):
  Promise<{ data: { id: number } }> => {
  const res = await axiosInstance.post('regions', data);
  return res.data;
};

export const updateRegionService = async (params: { id: number, data: RequestDataTypes }):
  Promise<void> => {
  await axiosInstance.put(`regions/${params.id}`, params.data);
};

export const deleteRegionByIdService = async (params: { ids: number[] }):
  Promise<void> => {
  await axiosInstance.delete('regions', { data: params });
};

export const updateRegionStatusService = async (params: { id: number, status: number }):
  Promise<void> => {
  await axiosInstance.put(`regions/${params.id}/update-status`, { status: params.status });
};

export const updateRegionStatusWithManyIdsService = async (params:
  { ids: number[], status: number }):
  Promise<void> => {
  await axiosInstance.put('regions/update-status-with-many-id', params);
};
