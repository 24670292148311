/* eslint-disable no-param-reassign */
import {
  ArcElement,
  Chart,
  registerables,
} from 'chart.js';
import DOMPurify from 'dompurify';
import React, { forwardRef, useCallback, useMemo } from 'react';
import {
  Bar,
  Doughnut,
  Line,
  Pie,
} from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import htmlLegendPiePlugin from 'common/utils/chart';
import { legendTooltips } from 'common/utils/constant';

type ShapeType = 'bar' | 'line' | 'pie' | 'doughnut';
export interface ChartCustomProps {
  data: any;
  options?: any;
  height?: number;
  width?: number;
  legendCustomId?: string;
  type?: ShapeType;
  isMultipleType?: boolean;
  idLegendTooltip?: string;
}
// register Plugins
Chart.register(ArcElement);
Chart.register(...registerables);
// config datalabels: https://chartjs-plugin-datalabels.netlify.app/guide/options.html#scriptable-options

let hovering = false;
const ChartCustom: React.ForwardRefRenderFunction<any, ChartCustomProps> = ({
  data, options, height, width, type, legendCustomId, isMultipleType, idLegendTooltip
}, ref) => {
  const { t } = useTranslation();
  const defaultOptions = useMemo(() => ({
    ...options,
    plugins: {
      ...options?.plugins,
      legend: {
        ...options?.plugins?.legend,
        display: legendCustomId ? false : options?.plugins?.legend?.display,
      }
    },
  }), [options, legendCustomId]);

  const renderChartType = useCallback(() => {
    const tooltip = idLegendTooltip ? document.getElementById(idLegendTooltip) : null;
    switch (type) {
      case 'line':
        return (
          <Line
            ref={ref}
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
            plugins={
              legendCustomId ? [htmlLegendPiePlugin(legendCustomId, isMultipleType)]
                : undefined
            }
          />
        );
      case 'bar':
        return (
          <Bar
            ref={ref}
            data={data}
            options={defaultOptions}
            height={height}
            width={width}
            plugins={
              legendCustomId ? [htmlLegendPiePlugin(legendCustomId, isMultipleType)]
                : undefined
            }
          />
        );
      case 'pie':
        return (
          <Pie
            ref={ref}
            plugins={
              legendCustomId ? [htmlLegendPiePlugin(legendCustomId, isMultipleType)]
                : undefined
            }
            data={data}
            options={
              {
                ...defaultOptions,
                plugins: {
                  legend: {
                    ...options?.plugins?.legend,
                    display: legendCustomId ? false : options?.plugins?.legend?.display,
                    onHover(e, legendItem) {
                      if (hovering || !tooltip) return;
                      hovering = true;
                      tooltip.innerHTML = DOMPurify.sanitize(
                        t(legendTooltips[legendItem.index as number])
                      );
                      tooltip.style.left = `${e.x}px`;
                      tooltip.style.top = `calc(${e.y}px + 70px)`;
                      tooltip.style.opacity = '1';
                    },
                    onLeave() {
                      if (!tooltip) return;
                      hovering = false;
                      tooltip.style.opacity = '0';
                    },
                  }
                }
              }
            }
            height={height}
            width={width}
          />
        );
      case 'doughnut':
        return (
          <Doughnut
            data={data}
            options={
              {
                ...defaultOptions,
                plugins: {
                  legend: {
                    ...options?.plugins?.legend,
                    display: legendCustomId ? false : options?.plugins?.legend?.display,
                    onHover(e, legendItem) {
                      if (hovering || !tooltip) return;
                      hovering = true;
                      tooltip.innerHTML = DOMPurify.sanitize(
                        t(legendTooltips[legendItem.index as number])
                      );
                      tooltip.style.left = `${e.x}px`;
                      tooltip.style.top = `calc(${e.y}px + 70px)`;
                      tooltip.style.opacity = '1';
                    },
                    onLeave() {
                      if (!tooltip) return;
                      hovering = false;
                      tooltip.style.opacity = '0';
                    },
                  },
                },
              }
            }
            height={height}
            width={width}
            plugins={
              [legendCustomId ? htmlLegendPiePlugin(legendCustomId, isMultipleType) : { id: '' },
              {
                id: 'htmlMiddleText',
                beforeDatasetDraw(chart) {
                  const { ctx, data: { datasets } } = chart;
                  const dataCount = (datasets[0].data as unknown as number[]).reduce((acc, cur) => {
                    acc += cur;
                    return acc;
                  }, 0);

                  ctx.save();
                  const { x, y } = chart.getDatasetMeta(0).data[0];
                  ctx.font = 'bold 20px sans-serif';
                  ctx.fillStyle = '#000';
                  ctx.textAlign = 'center';
                  ctx.textBaseline = 'middle';
                  ctx.fillText(dataCount.toString(), x, y);
                }
              }]
            }
          />
        );
      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, data, height, width,
    legendCustomId, isMultipleType, defaultOptions, idLegendTooltip]);

  return (
    <div className={`o-chart o-chart-${type}`}>
      <div
        className={`o-chart_panel ${!(data && data?.datasets?.length && data?.labels?.length) && 'empty'}`}
      >
        {data
          && data?.datasets?.length
          && data?.labels?.length > 0 ? renderChartType()
          : null}
      </div>
      {
        legendCustomId && <div id={legendCustomId} className="o-chart_legendCustom" />
      }
    </div>
  );
};

const ChartRef = forwardRef(ChartCustom);

ChartRef.defaultProps = {
  options: {},
  height: undefined,
  width: undefined,
  legendCustomId: '',
};

export default ChartRef;
