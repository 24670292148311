/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ExportOutlined, EyeOutlined, RedoOutlined, ShoppingOutlined
} from '@ant-design/icons';
import {
  Button, Space, Tooltip, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import PopupImportCancelThirdAccounts from './popupImportCancelThirdAccounts';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps,
  FilterValueProps,
  mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter,
  mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { StatusTopUpLabel, StatusTopUpSMSLabel } from 'common/components/StatusLabel';
import {
  bulkBuyCardTopUpSms,
  bulkRetryTopUpSms,
  buyCardTopUpSms, exportIncludeAccountTopUpSms, exportTopUpSms, getTopupSMSList, retryTopUpSms
} from 'common/services/topupSMS';
import { TopUpSMSItemTypes } from 'common/services/topupSMS/types';
import { BASE_URL, ROUTE_PATHS } from 'common/utils/constant';
import { detectError, downloadFile, formatDateTime } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

const TopUpSMSManagementContainer: React.FC<ActiveRoles> = ({
  roleIndex,
  roleCreate, roleUpdate,
  roleOther
}) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));
  const [isPopupImportTransactions, setIsPopupImportTransactions] = useState(false);

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    ['topup-sms-list', currentPage, currentView, selectedFilterList],
    () => getTopupSMSList({ page: currentPage, limit: currentView, ...returnParamsFilter }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['topup-sms-export'],
    () => exportTopUpSms({ ...returnParamsFilter }),
    {
      onSuccess(res) {
        if (res.data.link) downloadFile(`${BASE_URL}${res.data.link}`);
      },
    }
  );

  const { mutate: retryMutate, isLoading: retryLoading } = useMutation(
    ['topup-sms-retry'],
    (id: string) => retryTopUpSms(id),
    {
      onSuccess() {
        message.success(t('message.retrySuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        }
      }
    }
  );

  const { mutate: buyCardMutate, isLoading: buyCardLoading } = useMutation(
    ['topup-sms-buyCard'],
    (id: string) => buyCardTopUpSms(id),
    {
      onSuccess() {
        message.success(t('message.buyCardSuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        }
      }
    }
  );

  const { mutate: bulkBuyCardMutate, isLoading: bulkBuyCardLoading } = useMutation(
    ['topup-sms-bulk-buyCard'],
    ({ ids, params: buyCardParams }: { ids?: string[], params?: any }) => bulkBuyCardTopUpSms({
      ...ids && { ids },
      ...buyCardParams && { params: buyCardParams },
    }),
    {
      onSuccess() {
        message.success(t('message.buyCardSuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else if (Array.isArray(err) && err.length > 0) {
          message.error(t(`message.${err[0].message.toLowerCase().replaceAll(' ', '_')}`));
        }
      }
    }
  );

  const { mutate: bulkRetryMutate, isLoading: bulkRetryLoading } = useMutation(
    ['topup-sms--bulk-retry'],
    ({ ids, params: retryParams }: { ids?: string[], params?: any }) => bulkRetryTopUpSms({
      ...ids && { ids },
      ...retryParams && { params: retryParams },
    }),
    {
      onSuccess() {
        message.success(t('message.retrySuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else if (Array.isArray(err) && err.length > 0) {
          message.error(t(`message.${err[0].message.toLowerCase().replaceAll(' ', '_')}`));
        }
      }
    }
  );

  const { mutate: exportIncludeAccMutate, isLoading: exportIncludeAccLoading } = useMutation(
    ['topup-sms-export-include-account'],
    () => exportIncludeAccountTopUpSms({ ...returnParamsFilter }),
    {
      onSuccess(res) {
        if (res.data.link) downloadFile(`${BASE_URL}${res.data.link}`);
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        }
      }
    }
  );

  /* Datas */
  const columns: ColumnsType<TopUpSMSItemTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên người dùng
    {
      title: 'MoId',
      dataIndex: 'moId',
      key: 'moId',
      sorter: {
        compare: (a: TopUpSMSItemTypes, b: TopUpSMSItemTypes) => a.moId - b.moId,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {data.moId}
        </Typography.Text>
      ),
    },
    {
      title: t('topupSMS.telco'),
      dataIndex: 'telco',
      key: 'telco',
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {data.telco}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {data.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('topupSMS.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: {
        compare: (a: TopUpSMSItemTypes, b: TopUpSMSItemTypes) => a.status - b.status,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpSMSItemTypes) => (
        data.status && (
          <StatusTopUpSMSLabel
            status={data.status}
          />
        )
      ),
    },
    {
      title: t('topupSMS.topupStatus'),
      dataIndex: 'topupStatus',
      key: 'topupStatus',
      sorter: {
        compare: (a: TopUpSMSItemTypes, b: TopUpSMSItemTypes) => a.topupStatus - b.topupStatus,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpSMSItemTypes) => (
        data.topupStatus && (
          <StatusTopUpLabel
            status={data.topupStatus}
          />
        )
      ),
    },
    {
      title: t('topupSMS.limitExceeded'),
      dataIndex: 'limitExceeded',
      key: 'limitExceeded',
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <div className={`p-topupWinner_limitExceeded${data.limitExceeded ? ' active' : ''}`}>
          <Typography.Text strong style={{ color: '#fff' }}>{data.limitExceeded ? t('topupSMS.active') : t('topupSMS.inactive')}</Typography.Text>
        </div>
      ),
    },
    {
      title: t('callCenter.postPaid'),
      dataIndex: 'postPaid',
      key: 'postPaid',
      width: 100,
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>{data.postPaid ? 'Thuê bao trả sau' : 'Thuê bao trả trước'}</Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('topupSMS.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: TopUpSMSItemTypes, b: TopUpSMSItemTypes) => {
          const aDate = new Date(a.createdAt || 0);
          const bDate = new Date(b.createdAt || 0);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('topupSMS.finishedAt'),
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      sorter: {
        compare: (a: TopUpSMSItemTypes, b: TopUpSMSItemTypes) => {
          const aDate = new Date(a.finishedAt || 0);
          const bDate = new Date(b.finishedAt || 0);
          return Number(aDate) - Number(bDate);
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.finishedAt)}
        </Typography.Text>
      ),
    },
    // --- Xem
    {
      title: t('system.action'),
      key: 'action',
      align: 'center',
      render: (_name: string, _data: TopUpSMSItemTypes) => {
        const {
          id, status, limitExceeded, topupStatus
        } = _data;
        const condition = status === 1 && topupStatus === 3 && !limitExceeded;
        return (
          <Space>
            <Tooltip title={t('system.seeAttempt')}>
              <Button
                disabled={!roleIndex}
                icon={<EyeOutlined />}
                onClick={() => {
                  navigate(`${ROUTE_PATHS.TOPUP_ATTEMPT_MANAGEMENT}?id=${_data.id}`);
                }}
              />
            </Tooltip>
            <Tooltip title={t('system.retryTopUpSms')}>
              <Button
                loading={retryLoading}
                disabled={!(getPermission(roleOther, roles.TOPUP_SMS_RETRY) && condition)}
                icon={<RedoOutlined />}
                onClick={() => retryMutate(id)}
              />
            </Tooltip>
            <Tooltip title={t('system.buyCardTopUpSms')}>
              <Button
                loading={buyCardLoading}
                disabled={!(getPermission(roleOther, roles.TOPUP_SMS_BUY_CARD) && condition)}
                icon={<ShoppingOutlined />}
                onClick={() => buyCardMutate(id)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ]), [t, navigate, roleIndex]);

  const tableData: TopUpSMSItemTypes[] = useMemo(() => (
    listData?.data?.map((item) => ({
      ...item.topupSmsData,
      createdAt: item.topupSmsData.createdAt || '',
    })) || []), [listData]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
    handleSetCurrentPage(1);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
    handleSetCurrentPage(1);
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('topupSms', advancedFilter),
    [advancedFilter]
  );

  const extraOptions = useMemo(() => {
    const initOptions = [
      {
        isActive: getPermission(roleOther, roles.TOPUP_SMS_BULK_BUY_CARD),
        data: {
          label: t('system.buyCardTopUpSms'),
          value: 'buyCardAll'
        }
      },
      {
        isActive: getPermission(roleOther, roles.TOPUP_SMS_BULK_RETRY_CARD),
        data: {
          label: t('system.retryTopUpSms'),
          value: 'retryAll'
        }
      },
      {
        isActive: getPermission(roleOther, roles.TOPUP_SMS_BULK_BUY_CARD),
        data: {
          label: t('system.buyCardTopUpSmsAllPages'),
          value: 'buyCardAllPages',
          isExecuted: Object.keys(returnParamsFilter).length > 0
        }
      },
      {
        isActive: getPermission(roleOther, roles.TOPUP_SMS_BULK_RETRY_CARD),
        data: {
          label: t('system.retryTopUpSmsAllPages'),
          value: 'retryAllPages',
          isExecuted: Object.keys(returnParamsFilter).length > 0
        }
      }
    ];
    return initOptions.filter((o) => o.isActive).map((o) => ({ ...o.data }));
  }, [roleOther, t, returnParamsFilter]);

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.topupSMS')}
        rightHeader={(
          <Space>
            <Button
              loading={exportLoading}
              disabled={!getPermission(roleOther, roles.TOPUP_SMS_EXPORT)}
              onClick={() => exportMutate()}
            >
              {t('system.exportReport')}
            </Button>
            {getPermission(roleOther, roles.TOPUP_SMS_EXPORT_INCLUDE_ACCOUNT) && (
              <Button
                loading={exportIncludeAccLoading}
                onClick={() => exportIncludeAccMutate()}
              >
                {t('system.exportIncludeAccount')}
              </Button>
            )}
            {getPermission(roleOther, roles.TOPUP_WINNER_CANCEL_THIRD_ACCOUNT) && (
              <Button
                onClick={() => setIsPopupImportTransactions(true)}
              >
                {t('system.importCancelTransactions')}
              </Button>
            )}
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || bulkBuyCardLoading || bulkRetryLoading}
          handleSearch={() => undefined}
          noCheckbox={false}
          roles={{
            roleCreate,
            roleUpdate,
            roleDelete: true,
          }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          tableProps={{
            initShowColumns: ['id', 'moId', 'telco', 'phone', 'status', 'topupStatus', 'postPaid', 'limitExceeded', 'createdAt', 'finishedAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields,
          }}
          buckActions={extraOptions}
          handleChangeBulkAction={(action, selected) => {
            switch (action) {
              case 'retryAll':
                bulkRetryMutate({ ids: selected.map((item) => item.id) });
                break;
              case 'buyCardAll':
                bulkBuyCardMutate({ ids: selected.map((item) => item.id) });
                break;
              case 'retryAllPages':
                bulkRetryMutate({ params: { ...returnParamsFilter } });
                break;
              case 'buyCardAllPages':
                bulkBuyCardMutate({ params: { ...returnParamsFilter } });
                break;
              default:
                break;
            }
          }}
        />
        <PopupImportCancelThirdAccounts
          isShow={isPopupImportTransactions}
          handleClose={(callback) => {
            if (callback) callback();
            setIsPopupImportTransactions(false);
          }}
        />
      </div>
    </>
  );
};

export default TopUpSMSManagementContainer;
