import { EyeOutlined } from '@ant-design/icons';
import {
  Button, Input, Modal, Space, Typography
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps,
  FilterValueProps, mappingFilterFields,
  mappingFilterToQuery, mappingParamsFilter, mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { getProvinceService } from 'common/services/provinces';

export type TableDataTypes = {
  id: number,
  name: string,
  code: string,
  region: string,
};

const ProvincesManagement: React.FC<ActiveRoles> = ({
  roleIndex
}) => {
  /* Hooks */
  const method = useForm<TableDataTypes>({
    mode: 'onSubmit',
    defaultValues: {
      id: 0,
      name: '',
      code: '',
      region: '',
    },
  });
  const { t } = useTranslation();
  const {
    defaultPageSize, advancedFilter
  } = useAppSelector((state) => state.system);
  const [searchParams, setSearchParams] = useSearchParams();

  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* States */
  const [currentPage, setCurrentPage] = useState(1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [keyword, setKeyword] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const queryKey = ['getDataProvincesManagement', keyword, currentPage, currentView, selectedFilterList];

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Queries */
  const { data: listRes, isLoading } = useQuery(
    queryKey,
    () => getProvinceService({
      keyword, page: currentPage, limit: currentView, ...returnParamsFilter
    }),
    {
      keepPreviousData: true
    }
  );

  /* Datas */
  const tableData: TableDataTypes[] = useMemo(() => (
    listRes?.data.map((val) => ({
      id: val.provinceData.id,
      name: val.provinceData.name,
      code: val.provinceData.code,
      region: val?.region?.name,
    })) || []), [listRes]);

  /* Variables */
  const columns: ColumnsType<TableDataTypes> = [
    {
      title: 'ID',
      key: 'id',
      width: 55,
      align: 'center',
      fixed: 'left',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TableDataTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.name.localeCompare(b.name),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: 'region',
      dataIndex: 'region',
      key: 'region',
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.region}
        </Typography.Text>
      ),
    },
    {
      title: t('system.code'),
      dataIndex: 'code',
      key: 'code',
      sorter: {
        compare: (a: TableDataTypes, b: TableDataTypes) => a.code.localeCompare(b.code),
      },
      sortDirections: ['descend', 'ascend'],
      render: (_: string, data: TableDataTypes) => (
        <Typography.Text
          style={{ color: '#4a4a4a', cursor: 'pointer' }}
        >
          {data.code}
        </Typography.Text>
      ),
    },
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: TableDataTypes) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EyeOutlined />}
            onClick={() => {
              method.setValue('id', data.id);
              method.setValue('name', data?.name || '');
              method.setValue('code', data?.code || '');
              setIsOpen(true);
            }}
          />
        </Space>
      ),
    },
  ];

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
    handleSetCurrentPage(1);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
    handleSetCurrentPage(1);
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('province', advancedFilter),
    [advancedFilter]
  );

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        fixed
        title={t('provinces.provinces')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          handleSearch={setKeyword}
          isLoading={isLoading}
          noCheckbox
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          tableProps={{
            initShowColumns: ['id', 'name', 'region', 'code', 'action'],
            columns,
            pageData: tableData || [],
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listRes?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields
          }}
        />
      </div>
      <Modal
        title={<Typography.Title level={3}>{t('provinces.provincesDetail')}</Typography.Title>}
        visible={isOpen}
        centered
        footer={null}
        onCancel={() => {
          setIsOpen(false);
          method.reset();
        }}
      >
        <FormProvider<TableDataTypes> {...method}>
          <form noValidate>
            <Controller
              name="name"
              render={({
                field: { onChange, value, ref },
              }) => (
                <>
                  <Typography.Text strong>
                    {t('system.name')}
                  </Typography.Text>
                  <Input
                    ref={ref}
                    onChange={onChange}
                    value={value}
                    readOnly
                  />
                </>
              )}
            />
            <div className="u-mt-16">
              <Controller
                name="code"
                render={({
                  field: { onChange, value, ref },
                }) => (
                  <>
                    <Typography.Text strong>
                      {t('system.code')}
                    </Typography.Text>
                    <Input
                      ref={ref}
                      onChange={onChange}
                      value={value}
                      readOnly
                    />
                  </>
                )}
              />
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default ProvincesManagement;
