import { SaleDataTypes, SaleRequestDataTypes } from './types';

import axiosInstance from 'common/services/common/instance';

export const getSaleListService = async (params: BaseFilterParams):
  Promise<APIPaginationResponse<SaleDataTypes[]>> => {
  const res = await axiosInstance.get('sales', { params });
  return res.data;
};

export const getDetailSaleService = async (id: number):
  Promise<SaleDataTypes> => {
  const res = await axiosInstance.get(`sales/${id}`);
  return res.data.data;
};

export const createSaleService = async (data: SaleRequestDataTypes):
  Promise<{ id: number }> => {
  const res = await axiosInstance.post('sales', data);
  return res.data;
};

export const deleteSaleService = async (ids: number[]): Promise<void> => {
  await axiosInstance.delete('sales', { data: { ids } });
};

export const updateSaleService = async (data: { id: number, reqData: SaleRequestDataTypes }) => {
  const { id, reqData } = data;
  await axiosInstance.put(`sales/${id}`, reqData);
};

export const updateSaleStatusService = async (data: { id: number, status: number }) => {
  const { id, status } = data;
  await axiosInstance.put(`sales/${id}/update-status`, { status });
};

export const changeStatusSaleManyIdService = async (data: { ids: number[], status: number })
  : Promise<void> => {
  await axiosInstance.put('sales/update-status-with-many-id', data);
};
