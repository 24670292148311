import axiosInstance from '../common/instance';

import {
  FirstPrizeCancelDataType, FirstPrizeDataType, FirstPrizeCreateDataType
} from './types';

export const getAllFirstPrizeService = async (params?: CountingPrizeParamsType):
  Promise<FirstPrizeDataType> => {
  const res = await axiosInstance.get('first-prizes/count', { params });
  return res.data.data;
};

export const cancelFirstPrizeService = async (params: FirstPrizeCancelDataType) => {
  const formData = new FormData();
  if (params.file) {
    formData.append('file', params.file);
  }
  formData.append('reason', params.reason);
  const res = await axiosInstance.post('first-prizes/cancel', formData);
  return res.data;
};

export const createFirstPrizeService = async (params: FirstPrizeCreateDataType) => {
  const formData = new FormData();
  if (params.file) {
    formData.append('file', params.file);
  }
  const res = await axiosInstance.post('first-prizes/import', formData);
  return res.data;
};
