/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Col,
  Modal, Row
} from 'antd';
import { useEffect } from 'react';
import {
  Controller, FormProvider, UseFormReturn, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DropdownElement } from 'common/components/DropdownType';
import Input from 'common/components/Input';

export type CheckCodeFormType = {
  code: string;
};

interface Props {
  isOpen: boolean;
  method: UseFormReturn<CheckCodeFormType>;
  handleSubmit(): void;
  handleClose(): void;
}

const ReportedModal: React.FC<Props> = ({
  handleClose, handleSubmit, method, isOpen
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t('prizeExchange.titleCheckCodeModal')}
      visible={isOpen}
      closable
      onOk={method.handleSubmit(handleSubmit)}
      onCancel={handleClose}
    >
      <FormProvider {...method}>
        <Row gutter={16}>
          <Col span={24}>
            <Controller
              name="code"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  {t('prizeExchange.code')}
                  <Input
                    {...field}
                    error={error?.message}
                  />
                </>
              )}
            />
          </Col>
          {/* <Col span={24} className="u-mt-8">
            <Controller
              name="saleId"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <>
                  {t('role.sale')}
                  <DropdownElement
                    type="sale"
                    placeholder="-----"
                    locale="vi"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    isShowSearch
                  />
                </>
              )}
            />
          </Col> */}
        </Row>
      </FormProvider>
    </Modal>
  );
};

export default ReportedModal;
