import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Typography,
  message,
  Select,
  Spin
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Controller, FormProvider, useForm
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import { prizeExchangePrizeDummy } from 'common/assets/dummyData/system';
import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ModalConfirm from 'common/components/ModalConfirm';
import {
  createPrizeExchange, getPrizeExchangeById, updateAgentNotePrizeExchange, updatePrizeExchange
} from 'common/services/prizeExchange';
import { CreatePrizeExchangeParamsTypes, PrizeExchangePrizeTypes } from 'common/services/prizeExchange/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { createPrizeExchangeData } from 'common/utils/schemas';
import roles, { getPermission } from 'configs/roles';

type FormTypes = {
  phone: string,
  name: string,
  address: string,
  provinceCode: string,
  prize: PrizeExchangePrizeTypes,
  contactedAt: any,
  callId?: string,
  note?: string
  ccAgentNote?: string
};

const CreatePrizeExchange: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate, roleOther }) => {
  const { auth: { roles: totalRoles } } = useAppSelector((store) => store);
  const { t } = useTranslation();
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const idParam = searchParams.get('id');
  const { callId, phone: socketPhone } = state ? (state as { callId?: string, phone?: string }) : { callId: '', phone: '' };
  const isAgentUser = getPermission(roleOther, roles.PRIZE_EXCHANGE_INDEX_BY_PHONE) && !totalRoles.includes('*');
  const method = useForm<FormTypes>({
    resolver: yupResolver(createPrizeExchangeData),
    mode: 'onSubmit',
    defaultValues: {
      phone: '',
      name: '',
      address: '',
      provinceCode: '',
      prize: 1,
      contactedAt: moment(),
      note: '',
      ccAgentNote: ''
    }
  });
  /* Hooks */

  const navigator = useNavigate();
  /* States */
  const [confirm, setConfirm] = useState<string | undefined>(undefined);

  /* Queries */
  const { data: prizeData } = useQuery(
    ['get-prize-detail', idParam],
    () => getPrizeExchangeById(Number(idParam)),
    {
      enabled: !!idParam
    }
  );

  const { mutate: createPrizeExchangeMutate, isLoading: createLoading } = useMutation(
    'createPrizeExchange',
    createPrizeExchange,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigator(`${ROUTE_PATHS.PRIZE_EXCHANGE_MANAGEMENT}`);
      },
      onError: (err: ErrorResponse[]) => {
        if (err.length > 0) {
          err.forEach((
            e
          ) => method.setError(
            e.field as keyof CreatePrizeExchangeParamsTypes,
            { message: e.message }
          ));
        }
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updatePrizeExchangeMutate, isLoading: updateLoading } = useMutation(
    'updatePrizeExchange',
    updatePrizeExchange,
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: (err: ErrorResponse[]) => {
        if (err.length > 0) {
          err.forEach((
            e
          ) => method.setError(
            e.field as keyof CreatePrizeExchangeParamsTypes,
            { message: e.message }
          ));
        }
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updateAgentNoteMutate } = useMutation(
    ['updateAgentNote'],
    updateAgentNotePrizeExchange,
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
      },
      onError: (err: ErrorResponse[]) => {
        if (err.length > 0) {
          err.forEach((
            e
          ) => method.setError(
            e.field as keyof CreatePrizeExchangeParamsTypes,
            { message: e.message }
          ));
        }
        message.error(t('message.createError'));
      }
    }
  );

  /* Functions */

  const handleSubmit = async () => {
    const result = await method.trigger();
    if (!result) {
      return;
    }

    const mainData = method.getValues();

    if (idParam) {
      if (isAgentUser) {
        updateAgentNoteMutate({
          id: Number(idParam),
          data: {
            content: mainData.ccAgentNote || ''
          }
        });
      } else {
        updatePrizeExchangeMutate({
          id: Number(idParam),
          data: mainData
        });
      }
    } else {
      createPrizeExchangeMutate({ ...mainData, ...(callId && { callId }) });
    }
  };

  /* Submit */

  const submitForm = async () => {
    await handleSubmit();
  };

  const exceptionSlugs = useMemo(() => prizeData?.province.code, [prizeData]);

  useEffect(() => {
    if (prizeData) {
      const { prizeExchangeData, province } = prizeData;
      method.reset({
        name: prizeExchangeData.name,
        address: prizeExchangeData.address,
        contactedAt: prizeExchangeData.contactedAt,
        phone: prizeExchangeData.phone,
        prize: prizeExchangeData.prize,
        provinceCode: province.code,
        note: prizeExchangeData.note,
        ccAgentNote: prizeExchangeData.ccAgentNote
      });
    }
  }, [method, prizeData]);

  useEffect(() => {
    if (socketPhone) {
      method.setValue('phone', socketPhone);
    }
  }, [method, socketPhone]);
  return (
    <>
      <HeaderPage
        fixed
        title={idParam ? t('prizeExchange.pageEdit') : t('prizeExchange.pageCreate')}
        rightHeader={(
          <Button
            type="primary"
            disabled={idParam
              ? !roleUpdate && !roleOther.includes(
                roles.PRIZE_EXCHANGE_INDEX_BY_PHONE
              ) : !roleCreate}
            onClick={method.handleSubmit(submitForm)}
            loading={createLoading || updateLoading}
          >
            <SaveOutlined />
            {t('system.save')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <Spin size="large" spinning={createLoading || updateLoading}>
          <FormProvider {...method}>
            <Row>
              <Col>
                <Card>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('system.name')}
                      </Typography.Text>
                      {' '}
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="name"
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                            disabled={isAgentUser && !!idParam}
                          />
                        )}
                      />
                    </Col>
                    <Col span={12}>
                      <Typography.Text strong>
                        {t('system.phone')}
                      </Typography.Text>
                      {' '}
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="phone"
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                            disabled={isAgentUser && !!idParam}
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-10">
                      <Typography.Text strong>
                        {t('prizeExchange.address')}
                      </Typography.Text>
                      {' '}
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="address"
                        control={method.control}
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <Input
                            className="u-mt-8"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            size="large"
                            disabled={isAgentUser && !!idParam}
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-10">
                      <Typography.Text strong>
                        {t('prizeExchange.contactedAt')}
                      </Typography.Text>
                      {' '}
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="contactedAt"
                        control={method.control}
                        render={({ field }) => (
                          <DatePicker
                            disabled={!!idParam}
                            size="large"
                            value={moment(field.value)}
                            onChange={field.onChange}
                            format="DD/MM/YYYY HH:mm"
                            defaultValue={moment()}
                            showTime
                            style={{ width: '100%' }}
                          />
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-10">
                      <Typography.Text strong>
                        {t('prizeExchange.prize')}
                      </Typography.Text>
                      {' '}
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="prize"
                        control={method.control}
                        render={({ field: { value, onChange } }) => (
                          <Select
                            disabled={!!idParam}
                            className="u-mt-8"
                            style={{ width: '100%' }}
                            placeholder="---"
                            value={value}
                            onChange={onChange}
                          >
                            {
                              prizeExchangePrizeDummy.map((val, idx) => (
                                <Select.Option value={val.value} key={`option-${idx.toString()}`}>
                                  {t(val.label)}
                                </Select.Option>
                              ))
                            }
                          </Select>
                        )}
                      />
                    </Col>
                    <Col span={12} className="u-mt-10">
                      <Typography.Text strong>
                        {t('prizeExchange.province')}
                      </Typography.Text>
                      <Typography.Text strong type="danger">
                        *
                      </Typography.Text>
                      <Controller
                        name="provinceCode"
                        render={({
                          field: { value, onChange },
                          fieldState: { error },
                        }) => (
                          <DropdownElement
                            disabled={!!idParam}
                            type="province"
                            placeholder="-----"
                            locale="vi"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            slugs={exceptionSlugs}
                            isValueSlug
                            isShowSearch
                          />
                        )}
                      />
                    </Col>
                    {((getPermission(roleOther, roles.PRIZE_EXCHANGE_INDEX_BY_PHONE) && !totalRoles.includes('*')) || idParam) && (
                      <Col span={24} className="u-mt-10">
                        <Typography.Text strong>
                          {t('prizeExchange.ccAgentNote')}
                        </Typography.Text>
                        <Controller
                          name="ccAgentNote"
                          control={method.control}
                          render={({
                            field,
                          }) => (
                            <TextArea
                              {...field}
                              disabled={!isAgentUser}
                            />
                          )}
                        />
                      </Col>
                    )}
                    {(!totalRoles.includes(roles.PRIZE_EXCHANGE_INDEX_BY_PHONE)) && (
                      <Col span={24} className="u-mt-10">
                        <Typography.Text strong>
                          {t('prizeExchange.note')}
                        </Typography.Text>
                        <Controller
                          name="note"
                          control={method.control}
                          render={({
                            field,
                          }) => (
                            <TextArea
                              {...field}
                            />
                          )}
                        />
                      </Col>
                    )}
                  </Row>
                </Card>
              </Col>
            </Row>
          </FormProvider>
        </Spin>
        <ModalConfirm
          isShow={!!confirm}
          handleCancel={() => setConfirm(undefined)}
          handleClose={() => setConfirm(undefined)}
        >
          {t('message.confirmSkipInfo')}
        </ModalConfirm>
      </div>
    </>

  );
};

export default CreatePrizeExchange;
