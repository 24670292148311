import { RedoOutlined, ShoppingOutlined } from '@ant-design/icons';
import {
  Button, Space, Tooltip, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import PopupImportCancelThirdAccounts from './popupImportCancelThirdAccounts';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { StatusTopUpLabel, StatusTopUpSMSLabel } from 'common/components/StatusLabel';
import {
  bulkBuyCardTopUpSms, bulkRetryTopUpSms, buyCardTopUpSms, getInfoTopUpSMSByPhone, retryTopUpSms
} from 'common/services/topupSMS';
import { TopUpSMSItemTypes } from 'common/services/topupSMS/types';
import { detectError, formatDateTime } from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

const TopUpSMSManagementByPhoneContainer: React.FC<ActiveRoles> = ({
  roleOther,
  roleCreate,
  roleUpdate
}) => {
  /* Hooks */
  const { t } = useTranslation();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();

  const pageParam = searchParams.get('page');
  const keywordParam = searchParams.get('keyword');

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [keyword, setKeyword] = useState(keywordParam || '');
  const [currentPage, setCurrentPage] = useState(Number(pageParam) || 1);
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [isPopupImportTransactions, setIsPopupImportTransactions] = useState(false);
  /* React-query */
  const queryKeys = ['topup-sms-by-phone-list', currentPage, currentView, keyword];
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKeys,
    () => getInfoTopUpSMSByPhone({
      phone: keyword,
      params: { page: currentPage, limit: currentView }
    }),
    { keepPreviousData: true, enabled: !!currentPage && !!keyword }
  );

  const { mutate: retryMutate, isLoading: retryLoading } = useMutation(
    ['topup-sms-retry'],
    (id: string) => retryTopUpSms(id),
    {
      onSuccess() {
        message.success(t('topupSms.retrySuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        }
      }
    }
  );

  const { mutate: buyCardMutate, isLoading: buyCardLoading } = useMutation(
    ['topup-sms-buyCard'],
    (id: string) => buyCardTopUpSms(id),
    {
      onSuccess() {
        message.success(t('topupSms.buyCardSuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        }
      }
    }
  );

  const { mutate: bulkBuyCardMutate, isLoading: bulkBuyCardLoading } = useMutation(
    ['topup-sms-bulk-buyCard-by-phone'],
    ({ ids, params: buyCardParams }: { ids?: string[], params?: any }) => bulkBuyCardTopUpSms({
      ...ids && { ids },
      ...buyCardParams && { params: buyCardParams },
    }),
    {
      onSuccess() {
        message.success(t('message.buyCardSuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else if (Array.isArray(err) && err.length > 0) {
          message.error(t(`message.${err[0].message.toLowerCase().replaceAll(' ', '_')}`));
        }
      }
    }
  );

  const { mutate: bulkRetryMutate, isLoading: bulkRetryLoading } = useMutation(
    ['topup-sms-bulk-retry-by-phone'],
    ({ ids, params: retryParams }: { ids?: string[], params?: any }) => bulkRetryTopUpSms({
      ...ids && { ids },
      ...retryParams && { params: retryParams },
    }),
    {
      onSuccess() {
        message.success(t('message.retrySuccess'));
      },
      onError(err) {
        if (err instanceof AxiosError) {
          message.error(detectError(Number(err.response?.status)));
        } else if (Array.isArray(err) && err.length > 0) {
          message.error(t(`message.${err[0].message.toLowerCase().replaceAll(' ', '_')}`));
        }
      }
    }
  );

  /* Datas */
  const columns: ColumnsType<TopUpSMSItemTypes> = useMemo(() => ([
    {
      title: t('topupSMS.telco'),
      dataIndex: 'telco',
      key: 'telco',
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {data.telco}
        </Typography.Text>
      ),
    },
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {data.phone}
        </Typography.Text>
      ),
    },
    {
      title: t('topupSMS.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: {
        compare: (a: TopUpSMSItemTypes, b: TopUpSMSItemTypes) => a.status - b.status,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpSMSItemTypes) => (
        data.status && (
          <StatusTopUpSMSLabel
            status={data.status}
          />
        )
      ),
    },
    {
      title: t('topupSMS.topupStatus'),
      dataIndex: 'topupStatus',
      key: 'topupStatus',
      sorter: {
        compare: (a: TopUpSMSItemTypes, b: TopUpSMSItemTypes) => a.topupStatus - b.topupStatus,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpSMSItemTypes) => (
        data.topupStatus && (
          <StatusTopUpLabel
            status={data.topupStatus}
          />
        )
      ),
    },
    {
      title: t('topupSMS.limitExceeded'),
      dataIndex: 'limitExceeded',
      key: 'limitExceeded',
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <div className={`p-topupWinner_limitExceeded${data.limitExceeded ? ' active' : ''}`}>
          <Typography.Text strong style={{ color: '#fff' }}>{data.limitExceeded ? t('topupSMS.active') : t('topupSMS.inactive')}</Typography.Text>
        </div>
      ),
    },
    {
      title: t('callCenter.postPaid'),
      dataIndex: 'postPaid',
      key: 'postPaid',
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>{data.postPaid ? 'Thuê bao trả sau' : 'Thuê bao trả trước'}</Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('topupSMS.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: TopUpSMSItemTypes, b: TopUpSMSItemTypes) => {
          const aDate = new Date(a.createdAt || 0);
          const bDate = new Date(b.createdAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('topupSMS.finishedAt'),
      dataIndex: 'finishedAt',
      key: 'finishedAt',
      sorter: {
        compare: (a: TopUpSMSItemTypes, b: TopUpSMSItemTypes) => {
          const aDate = new Date(a.finishedAt || 0);
          const bDate = new Date(b.finishedAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: TopUpSMSItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.finishedAt)}
        </Typography.Text>
      ),
    },
    // --- Xem
    {
      title: t('system.action'),
      key: 'action',
      align: 'center',
      render: (_name: string, _data: TopUpSMSItemTypes) => {
        const {
          id, status, limitExceeded, topupStatus
        } = _data;
        const condition = status === 1 && topupStatus === 3 && !limitExceeded;
        return (
          <Space>
            <Tooltip title={t('system.retryTopUpSms')}>
              <Button
                loading={retryLoading}
                disabled={!(getPermission(roleOther, roles.TOPUP_SMS_RETRY) && condition)}
                icon={<RedoOutlined />}
                onClick={() => retryMutate(id)}
              />
            </Tooltip>
            <Tooltip title={t('system.buyCardTopUpSms')}>
              <Button
                loading={buyCardLoading}
                disabled={!(getPermission(roleOther, roles.TOPUP_SMS_BUY_CARD) && condition)}
                icon={<ShoppingOutlined />}
                onClick={() => buyCardMutate(id)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ]), [t]);

  const tableData: TopUpSMSItemTypes[] = useMemo(() => (
    listData?.data?.map((item) => ({
      ...item.topupSmsData,
      createdAt: item.topupSmsData.createdAt || '',
    })) || []), [listData]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const extraOptions = useMemo(() => {
    const initOptions = [
      {
        isActive: getPermission(roleOther, roles.TOPUP_SMS_BULK_BUY_CARD),
        data: {
          label: t('system.buyCardTopUpSms'),
          value: 'buyCardAll'
        }
      },
      {
        isActive: getPermission(roleOther, roles.TOPUP_SMS_BULK_RETRY_CARD),
        data: {
          label: t('system.retryTopUpSms'),
          value: 'retryAll'
        }
      },
    ];
    return initOptions.filter((o) => o.isActive).map((o) => ({ ...o.data }));
  }, [roleOther, t]);

  useEffect(() => {
    setSearchParams({
      ...pageParam && { page: pageParam },
      ...keywordParam && { page: keywordParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.topupSMS')}
        rightHeader={getPermission(roleOther, roles.TOPUP_WINNER_CANCEL_THIRD_ACCOUNT) && (
          <Button
            onClick={() => setIsPopupImportTransactions(true)}
          >
            {t('system.importCancelTransactions')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || bulkBuyCardLoading || bulkRetryLoading}
          noCheckbox={false}
          handleSearch={(key) => setKeyword(key)}
          tableProps={{
            initShowColumns: ['telco', 'phone', 'status', 'topupStatus', 'postPaid', 'limitExceeded', 'createdAt', 'finishedAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
          roles={{
            roleCreate,
            roleUpdate,
            roleDelete: true,
          }}
          buckActions={extraOptions}
          handleChangeBulkAction={(action, selected) => {
            switch (action) {
              case 'retryAll':
                bulkRetryMutate({ ids: selected.map((item) => item.id) });
                break;
              case 'buyCardAll':
                bulkBuyCardMutate({ ids: selected.map((item) => item.id) });
                break;
              default:
                break;
            }
          }}
        />
        <PopupImportCancelThirdAccounts
          isShow={isPopupImportTransactions}
          handleClose={(callback) => {
            if (callback) callback();
            setIsPopupImportTransactions(false);
          }}
        />
      </div>
    </>
  );
};

export default TopUpSMSManagementByPhoneContainer;
