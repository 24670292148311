import React from 'react';

import PrizeExchangeByPhoneManagement from './prizeExchangeByPhoneContainer';
import PrizeExchangeManagement from './prizeExchangeContainer';

import roles, { getPermission } from 'configs/roles';

const PrizeExchangeContainer: React.FC<ActiveRoles> = (props) => {
  const { roleOther, roleIndex } = props;
  const isFetchByPhone = !roleIndex
    && getPermission(roleOther, roles.PRIZE_EXCHANGE_INDEX_BY_PHONE);

  return isFetchByPhone ? (
    <PrizeExchangeByPhoneManagement
      {...props}
    />
  )
    : <PrizeExchangeManagement {...props} />;
};

export default PrizeExchangeContainer;
