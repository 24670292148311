import axiosInstance from '../common/instance';

import { ProvincesType } from './types';

export const getProvinceService = async (params?: BaseFilterParams):
  Promise<APIPaginationResponse<ProvincesType[]>> => {
  const res = await axiosInstance.get('provinces', { params });
  return res.data;
};

export default undefined;
