import {
  Space, Typography
} from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import mapModifiers from 'common/utils/functions';

export type ConvertRewardProps = {
  title: string;
  sended: number;
  total: number;
  growthRate: number;
  type: 'gold' | 'cash' | 'phone';
};

export const ConvertReward: React.FC<ConvertRewardProps> = ({
  title,
  sended,
  total,
  growthRate,
  type
}) => {
  const { t } = useTranslation();
  return (
    <div className="o-convertReward">
      <Typography.Title level={4} className={mapModifiers('o-convertReward_title', type)}>{title}</Typography.Title>
      <div className="o-convertReward_content">
        <div className="o-convertReward_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="o-convertReward_content_item-heading" type="secondary">
              {t('dashboard.awards')}
            </Typography.Text>
            <Typography.Text className="o-convertReward_content_item-value">{sended}</Typography.Text>
          </Space>
        </div>
        <div className="o-convertReward_content_item">
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Text className="o-convertReward_content_item-heading" type="secondary">
              {t('dashboard.totalPrizes')}
            </Typography.Text>
            <Typography.Text className="o-convertReward_content_item-value">{total}</Typography.Text>
          </Space>
        </div>
      </div>
      <div className="o-convertReward_growthRate">
        <Space direction="vertical" style={{ width: '100%' }}>
          <Typography.Text className="o-convertReward_content_item-heading" type="secondary">
            {t('dashboard.growthRate')}
          </Typography.Text>
          <Typography.Text className="o-convertReward_content_item-value">{!Number.isNaN(growthRate) ? `${growthRate}%` : growthRate}</Typography.Text>
        </Space>
      </div>
    </div>
  );
};
