import { numberWithPrefix } from 'common/utils/functions';

const getOrCreateLegendList = (_: any, id: any) => {
  const legendContainer = document.getElementById(id);
  if (!legendContainer) return null;
  let listContainer = legendContainer.querySelector('ul');

  if (!listContainer) {
    listContainer = document.createElement('ul');
    listContainer.classList.add('o-chart_legendCustom_container');
    legendContainer.appendChild(listContainer);
  }

  return listContainer;
};

const generateLabel = (chart: any, item: any, isMultipleType?: boolean) => {
  const li = document.createElement('li');
  li.classList.add('o-chart_legendCustom_item');

  li.onclick = () => {
    const chartConfig = chart.config;
    if (chartConfig.type === 'pie') {
      // Pie and doughnut charts only have a single dataset and visibility is per item
      chart.toggleDataVisibility(item.index);
    } else {
      chart.setDatasetVisibility(
        item.datasetIndex,
        !chart.isDatasetVisible(item.datasetIndex)
      );
      li.style.textDecoration = chart?.isDatasetVisible(item.datasetIndex) ? '' : 'line-through';
    }
    chart.update();
  };

  // Color box
  const boxSpan = document.createElement('span');
  boxSpan.className = isMultipleType ? 'o-chart_legendCustom_box o-chart_legendCustom_box-multiple' : 'o-chart_legendCustom_box';
  boxSpan.style.background = item.fillStyle;
  boxSpan.style.borderColor = item.strokeStyle;
  boxSpan.style.borderWidth = `${item.lineWidth}px`;

  // Text
  const textContainer = document.createElement('span');
  const wrapper = document.createElement('div');
  textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

  const text = document.createTextNode(item.text);
  textContainer.appendChild(text);
  wrapper.appendChild(textContainer);

  if (chart?.data.datasets[0].data[item.index]) {
    const decsContainer = document.createElement('p');
    const desc = document.createTextNode(
      numberWithPrefix(chart.data.datasets[0].data[item.index], '.'),
    );
    decsContainer.appendChild(desc);
    wrapper.appendChild(decsContainer);
  }

  li.appendChild(boxSpan);
  li.appendChild(wrapper);
  return li;
};

const htmlLegendPiePlugin = (
  legendCustomId: string,
  isMultipleType?: boolean,
) => ({
  id: 'htmlLegend',
  afterUpdate(chart: any) {
    const ul = getOrCreateLegendList(chart, legendCustomId);

    if (!ul) return;
    // Remove old legend items
    while (ul.firstChild) {
      ul.firstChild.remove();
    }

    // Reuse the built-in legendItems generator
    const items = chart.options.plugins.legend.labels.generateLabels(chart);
    ul.className = 'o-chart_legendCustom';
    items?.forEach((item: any) => {
      const li = generateLabel(chart, item, isMultipleType);
      ul.appendChild(li);
    });
  },
});

export const htmlMiddleTitleDoughnut = (content: string) => ({
  id: 'htmlMiddleText',
  beforeDraw: (chart: any) => {
    const { width, height, ctx } = chart;
    console.log('🚀 ~ file: chart.ts:94 ~ htmlMiddleTitleDoughnut ~ width:', width);

    ctx.restore();
    const fontSize = (height / 114).toFixed(2);
    ctx.font = `${fontSize}em sans-serif`;
    ctx.textBaseline = 'middle';

    const textX = Math.round((width - ctx.measureText(content).width) / 2);
    const textY = height / 2;

    ctx.fillText(content, textX, textY);
    ctx.save();
  }
});

export default htmlLegendPiePlugin;
