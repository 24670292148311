import React from 'react';

import TopUpSMSManagementByPhoneContainer from './topupSMSByPhoneContainer';
import TopUpSMSManagementContainer from './topupSMSContainer';

import roles, { getPermission } from 'configs/roles';

const TopUpSMSManagement: React.FC<ActiveRoles> = (props) => {
  const { roleOther, roleIndex } = props;
  const isFetchByPhone = !roleIndex
    && getPermission(roleOther, roles.TOPUP_SMS_INDEX_BY_PHONE);
  return isFetchByPhone ? <TopUpSMSManagementByPhoneContainer {...props} />
    : <TopUpSMSManagementContainer {...props} />;
};

export default TopUpSMSManagement;
