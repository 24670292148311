import {
  Button,
  Card,
  DatePicker,
  Empty,
  Select,
  Space,
  Spin,
  Typography
} from 'antd';
import { Chart } from 'chart.js';
import moment from 'moment';
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import ChartCustom from 'common/components/Chart';
import { getTopupSla } from 'common/services/topupWinner';
import { TopUpSLADataTypes } from 'common/services/topupWinner/types';
import { formatDateYYYYMMDD } from 'common/utils/functions';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
const curTime = `${moment().get('hour')}:${moment().get('minute')}`;
const defaultDateValue = [moment(new Date()).subtract(7, 'days').format(dateFormat), `${moment(new Date()).subtract(1, 'day').format(dateFormat)} 23:59`];

let init = true;

const staticCriteriaOptions = [
  {
    label: 'Dưới 3m',
    value: 1
  },
  {
    label: 'Từ 3m-20m',
    value: 2
  },
  {
    label: 'Từ 20m-24h',
    value: 3
  },
  {
    label: 'Sau 24h',
    value: 4
  }
];

const groupByOptions = [
  {
    label: 'Xem theo ngày',
    value: 1
  },
  {
    label: 'Xem theo tháng',
    value: 2
  },
];

const ReportTopUpWinnerSLA: React.FC = () => {
  const { t } = useTranslation();

  const [dateRange, setDateRange] = useState(defaultDateValue);
  const [level, setLevel] = useState(staticCriteriaOptions[0].value);
  const [groupBy, setGroupBy] = useState(groupByOptions[0].value);

  const chartRef = useRef<Chart>(null);

  /* Queries */
  const { mutate: SlaMutate, data: statisticData, isLoading: SlaLoading } = useMutation(
    ['get-topup-sla'],
    getTopupSla
  );

  const convertGroupByData = useCallback((data: TopUpSLADataTypes[]) => {
    const endData = {
      label: data.map((item) => item.date),
      value: data.map((item) => item.total)
    };
    if (groupBy === 2) {
      const uniqMonth = Array.from(new Set(data.map((item) => `${moment(item.date).year()}-${moment(item.date).month() + 1}`)));
      const valByMonth: number[] = uniqMonth.reduce((acc, cur, idx) => {
        data.forEach((item) => {
          const itemMoment = `${moment(item.date).year()}-${moment(item.date).month() + 1}`;
          // eslint-disable-next-line no-param-reassign
          if (itemMoment === cur) acc[idx] += item.total;
        });
        return acc;
      }, uniqMonth.map(() => 0));
      endData.label = uniqMonth;
      endData.value = valByMonth;
    }
    endData.label.unshift('');
    endData.value.unshift(0);
    return endData;
  }, [groupBy]);

  const data = useMemo(() => ({
    labels: convertGroupByData(statisticData?.data || []).label,
    datasets: [{
      label: t('dashboard.totalTopupCalling'),
      data: convertGroupByData(statisticData?.data || []).value
    }]
  }), [statisticData, convertGroupByData, t]);

  const options = useMemo(() => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 12
          }
        },
      },
    }
  }), []);

  const onSubmit = useCallback(() => {
    SlaMutate({
      fromDate: moment(dateRange[0]).toISOString(),
      toDate: moment(dateRange[1]).toISOString(),
      level
    });
    init = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, level]);

  useEffect(() => {
    if (init) {
      onSubmit();
    }
  }, [onSubmit]);

  return (
    <Space direction="vertical" style={{ gap: 12, width: '100%' }}>
      <Typography.Title level={4}>{t('dashboard.reportTopUpWinnerSLATitle')}</Typography.Title>
      <Card type="inner">
        <div className="t-reportTopUpWinnerSLA">
          <div className="t-reportTopUpWinnerSLA_filter">
            <div style={{ flex: 1 }}>
              <Select
                size="middle"
                style={{ width: '100%' }}
                className="u-mt-8"
                placeholder="---"
                value={level}
                onChange={(val) => setLevel(val)}
              >
                {
                  staticCriteriaOptions.map((item, index) => (
                    <Select.Option value={item.value} key={`option-${index.toString()}`}>
                      {item.label}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
            <div style={{ flex: 2 }}>
              <RangePicker
                style={{ width: '100%' }}
                defaultValue={[moment(dateRange[0], dateFormat),
                moment(dateRange[1], dateFormat)]}
                disabledDate={(cur) => cur > moment()}
                onChange={(val) => {
                  if (!val) return;
                  const res = val.map((item, idx) => {
                    if (idx === 1) {
                      const isToday = formatDateYYYYMMDD(item) === formatDateYYYYMMDD(moment());
                      return `${formatDateYYYYMMDD(item)} ${isToday ? curTime : '23:59'}`;
                    }
                    return formatDateYYYYMMDD(item);
                  });
                  setDateRange(res);
                }}
              />
            </div>
            <div style={{ flex: 1 }}>
              <Select
                size="middle"
                style={{ width: '100%' }}
                className="u-mt-8"
                placeholder="---"
                value={groupBy}
                onChange={(val) => setGroupBy(val)}
              >
                {
                  groupByOptions.map((item, index) => (
                    <Select.Option value={item.value} key={`option-${index.toString()}`}>
                      {item.label}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
            <Button type="primary" onClick={onSubmit}>Tìm kiếm</Button>
          </div>
        </div>
        <div className="u-mt-20" style={{ minHeight: '21.6875rem' }}>
          <Spin size="large" spinning={SlaLoading}>
            <div>
              {data
                ? (
                  <ChartCustom
                    ref={chartRef}
                    type="line"
                    data={data}
                    options={options}
                    height={100}
                  />
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </div>
          </Spin>
        </div>
      </Card>
    </Space>
  );
};

export default ReportTopUpWinnerSLA;
