import {
  Modal,
  Switch, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, {
  useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import PageTable from 'common/components/PageTable';
import { getTopupQuotasService, toggleActiveTopupQuotasService, updateTopupQuotasService } from 'common/services/topupQuotas';
import { QuotasItemTypes } from 'common/services/topupQuotas/types';
// import { ROUTE_PATHS } from 'common/utils/constant';
import { telcos } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

const FormUpdateQuota: React.FC<{
  data: QuotasItemTypes,
  roleUpdate: boolean,
  handleSubmit?(quota: number): void
}> = ({ data, roleUpdate, handleSubmit }) => {
  const { isActive, quota } = data;
  const oldValue = useRef<number>(0);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (oldValue.current !== Number(e.target.value) && handleSubmit) {
      handleSubmit(Number(e.target.value));
    }
  };

  useEffect(() => {
    oldValue.current = data.quota;
  }, [data]);

  return (
    <Input
      type="number"
      disabled={!roleUpdate || !isActive}
      defaultValue={quota}
      onBlur={handleBlur}
    />
  );
};

const TopUpQuotasManagement: React.FC<ActiveRoles> = ({ roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    ['topup-quotas-list', currentPage, currentView],
    () => getTopupQuotasService({ page: currentPage, limit: currentView }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { mutate: updateQuotaMutate } = useMutation(
    ['topup-quotas-update'],
    (data: { id: number, quota: number }) => updateTopupQuotasService(data),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['topup-quotas-list', currentPage]);
      }
    }
  );

  const { mutate: toggleMutate } = useMutation(
    ['toggle-active-quotas'],
    (data: {
      id: number,
      isActive: boolean
    }) => toggleActiveTopupQuotasService(data),
    {
      onSuccess() {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['topup-quotas-list', currentPage]);
      },
    }
  );

  /* Datas */
  const columns: ColumnsType<QuotasItemTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      render: (_name: string, data: QuotasItemTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    {
      title: t('topupSMS.telco'),
      dataIndex: 'telco',
      width: 150,
      key: 'telco',
      render: (_name: string, data: QuotasItemTypes) => (
        <Typography.Text>
          {telcos[data.telco as keyof typeof telcos]}
        </Typography.Text>
      ),
    },
    {
      title: t('topupQuota.quota'),
      dataIndex: 'quota',
      key: 'quota',
      render: (_name: string, data: QuotasItemTypes) => (
        <FormUpdateQuota
          data={data}
          roleUpdate={roleUpdate}
          handleSubmit={(quota) => {
            Modal.confirm({
              className: 't-pagetable_deleteRecordModal',
              autoFocusButton: 'cancel',
              title: t('message.confirmUpdateRecord'),
              okText: t('system.ok'),
              cancelText: t('system.cancel'),
              cancelButtonProps: {
                type: 'primary',
              },
              okButtonProps: {
                type: 'default',
              },
              onOk: () => updateQuotaMutate({ id: data.id, quota }),
            });
          }}
        />
      ),
    },
    {
      title: t('topupQuota.activeStatus'),
      dataIndex: 'isActive',
      key: 'isActive',
      width: 75,
      align: 'center',
      render: (_name: string, data: QuotasItemTypes) => {
        const { id, isActive } = data;
        return (
          <Switch
            defaultChecked={isActive}
            onChange={(e) => toggleMutate({ id, isActive: e })}
          />
        );
      },
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: QuotasItemTypes, b: QuotasItemTypes) => {
          const aDate = new Date(a.updatedAt || '');
          const bDate = new Date(b.updatedAt || '');
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: QuotasItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
  ]), [t, navigate]);

  const tableData: QuotasItemTypes[] = useMemo(() => (
    listData?.data?.map((item) => ({
      ...item.topupQuotaData,
    })) || []), [listData]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.topupQuota')}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          noCheckbox
          tableProps={{
            initShowColumns: ['id', 'telco', 'quota', 'isActive', 'updatedAt'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
    </>
  );
};

export default TopUpQuotasManagement;
