import { IdcardOutlined } from '@ant-design/icons';
import {
  Button,
  Card, Col, Image, Modal, Row, Space, Spin, Typography, message
} from 'antd';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Input from 'common/components/Input';
import { StatusPrizeExchangeLabel } from 'common/components/StatusLabel';
import {
  approvePrizeExchangeDocuments,
  getPrizeExchangeById,
  idCardPrizeExchange,
  receivePrizeExchangeHardDocuments,
  rejectPrizeExchangeDocuments
} from 'common/services/prizeExchange';
import {
  detectError, formatDateDDMMYYYY, formatDateTime, getImageURL
} from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

interface Props {
  openId?: number;
  handleClose(): void;
  roleOther: string[]
}

const DetailModal: React.FC<Props> = ({
  handleClose, openId, roleOther
}) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const queryKey = ['get-prizeExchange-detail-modal', openId];
  const [nextDocumentOrder, setNextDocumentOrder] = useState(1);
  const { data: detailData, isLoading: detailLoading } = useQuery(
    queryKey,
    () => getPrizeExchangeById(openId!),
    {
      enabled: !!openId
    }
  );

  const { mutate: approveMutate, isLoading } = useMutation(
    ['approve-document'],
    (data: { id: number, order: number }) => approvePrizeExchangeDocuments(data.id, data.order),
    {
      onSuccess() {
        message.success(t('message.approveSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError(err: unknown) {
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(
              `${t('system.data')} ${t(
                detectError(Number(err.response?.status))
              )}`
            );
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        }
      },
    }
  );

  const { mutate: rejectMutate, isLoading: rejectLoading } = useMutation(
    ['reject-document'],
    (id: number) => rejectPrizeExchangeDocuments(id),
    {
      onSuccess() {
        message.success(t('message.approveSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError(err: unknown) {
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(
              `${t('system.data')} ${t(
                detectError(Number(err.response?.status))
              )}`
            );
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        }
      },
    }
  );

  const { mutate: receiveMutate, isLoading: receiveLoading } = useMutation(
    ['receive-hard-document'],
    (id: number) => receivePrizeExchangeHardDocuments(id),
    {
      onSuccess() {
        message.success(t('message.approveSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError(err: unknown) {
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(
              `${t('system.data')} ${t(
                detectError(Number(err.response?.status))
              )}`
            );
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        }
      },
    }
  );

  const { mutate: idCardMutate, isLoading: loadingIdCard } = useMutation(
    ['update-id-card'],
    (id: number) => idCardPrizeExchange(id),
    {
      onSuccess() {
        message.success(t('message.updateSuccess'));
      },
      onError(err: unknown) {
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(
              `${t('system.data')} ${t(
                detectError(Number(err.response?.status))
              )}`
            );
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        }
      },
    }
  );

  const detectPrize = (prize: number) => {
    switch (prize) {
      case 1:
        return t('prizeExchange.firstPrize');
      case 2:
        return t('prizeExchange.secondPrize');

      default:
        return prize;
    }
  };

  const isAllowCondition = detailData?.prizeExchangeData.status === 3
    && !detailData?.prizeExchangeData.documentRejectedAt
    && !detailData?.prizeExchangeData.documentReviewedAt;

  useEffect(() => {
    setNextDocumentOrder(detailData?.nextDocumentOrder || 1);
  }, [detailData]);

  return (
    <Modal width="60vw" visible={!!openId} closable footer={null} onCancel={handleClose}>
      <Spin spinning={detailLoading}>
        <Card title={t('prizeExchange.customerInfo')} style={{ marginTop: '16px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.customerName')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.name}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('system.phone')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.phone}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.address')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.address}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.contactedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.contactedAt)}
              </Typography.Text>
            </Col>
          </Row>
        </Card>
        <Card title={t('prizeExchange.provinceLeaderInfo')} style={{ marginTop: '16px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.leaderName')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.province.leader?.name}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.province')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.province.name}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col>
              <Typography.Text strong>
                {t('role.region')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.province.region?.name}
              </Typography.Text>
            </Col>
          </Row>
        </Card>
        <Card title={t('role.sale')} style={{ marginTop: '16px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.saleName')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.sale?.name}
              </Typography.Text>
            </Col>
          </Row>
        </Card>
        <Card title={t('prizeExchange.prizeInfo')} style={{ marginTop: '16px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.prize')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detectPrize(detailData?.prizeExchangeData.prize || 0)}
              </Typography.Text>
            </Col>
            <Col span={12} style={{ display: 'flex' }}>
              <Typography.Text strong>
                {t('system.status')}
                :
              </Typography.Text>
              {detailData && (
                <div style={{ flex: 1, marginLeft: '.25rem' }}>
                  <StatusPrizeExchangeLabel status={detailData?.prizeExchangeData.status} />
                </div>
              )}
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('system.createdAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.createdAt)}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('system.updatedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.updatedAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.exportedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.exportedAt)}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.reportedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.reportedAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.verifiedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.verifiedAt)}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.exchangedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.exchangedAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.cancelReason')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.cancelReason}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.canceledAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.canceledAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.documentReviewedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.documentReviewedAt)}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.hardDocumentReceivedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.hardDocumentReceivedAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.ccAgentNote')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.ccAgentNote}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.note')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.note}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.actualAwardDate')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateDDMMYYYY(detailData?.prizeExchangeData.actualAwardDate)}
              </Typography.Text>
            </Col>
          </Row>
        </Card>
        <Card
          title={(
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Typography.Text>
                {t('prizeExchange.documentImages')}
              </Typography.Text>
              <Space direction="vertical">
                <Space>
                  <Button
                    type="default"
                    loading={receiveLoading}
                    disabled={!(getPermission(roleOther, roles.PRIZE_EXCHANGE_RECEIVE_HARD_DOCUMENT)
                      && !!detailData?.prizeExchangeData.documentAddedAt
                      && !detailData?.prizeExchangeData.hardDocumentReceivedAt)}
                    onClick={() => receiveMutate(detailData?.prizeExchangeData.id!)}
                  >
                    {t('prizeExchange.receiveHardDocuments')}
                  </Button>
                  <Button
                    type="default"
                    loading={rejectLoading}
                    disabled={!(getPermission(roleOther, roles.PRIZE_EXCHANGE_REJECT_DOCUMENT)
                      && isAllowCondition)}
                    onClick={() => rejectMutate(detailData?.prizeExchangeData.id!)}
                  >
                    {t('prizeExchange.rejectDocuments')}
                  </Button>
                  <Button
                    disabled={!(getPermission(roleOther, roles.PRIZE_EXCHANGE_APPROVE_DOCUMENT)
                      && detailData?.prizeExchangeData.documentReviewedAt)}
                    icon={<IdcardOutlined />}
                    loading={loadingIdCard}
                    onClick={() => idCardMutate(openId!)}
                  />
                </Space>
                <Space>
                  <Input
                    className="u-mt-8"
                    disabled={!(getPermission(roleOther, roles.PRIZE_EXCHANGE_APPROVE_DOCUMENT)
                      && isAllowCondition && !!detailData.prizeExchangeData.hardDocumentReceivedAt)}
                    value={nextDocumentOrder}
                    onChange={(e) => {
                      setNextDocumentOrder(Number(e.currentTarget.value));
                    }}
                    size="middle"
                  />
                  <Button
                    type="primary"
                    loading={isLoading}
                    disabled={!(getPermission(roleOther, roles.PRIZE_EXCHANGE_APPROVE_DOCUMENT)
                      && isAllowCondition && !!detailData.prizeExchangeData.hardDocumentReceivedAt)}
                    onClick={() => approveMutate({
                      id: detailData?.prizeExchangeData.id!,
                      order: nextDocumentOrder
                    })}
                  >
                    {t('prizeExchange.approveDocuments')}
                  </Button>
                </Space>
              </Space>
            </Space>
          )}
          style={{ marginTop: '16px' }}
        >
          <Row gutter={16}>
            {detailData?.prizeExchangeData.frontIdCard && (
              <Col span={12}>
                <Typography.Text strong>
                  {t('prizeExchange.frontIdCard')}
                  :
                </Typography.Text>
                <Image.PreviewGroup>
                  <div className="p-prizeExchange_img">
                    <Image
                      preview
                      alt="frontIdCard"
                      src={getImageURL(detailData.prizeExchangeData.frontIdCard)}
                    />
                  </div>
                </Image.PreviewGroup>
              </Col>
            )}
            {detailData?.prizeExchangeData.backIdCard && (
              <Col span={12}>
                <Typography.Text strong>
                  {t('prizeExchange.backIdCard')}
                  :
                </Typography.Text>
                <Image.PreviewGroup>
                  <div className="p-prizeExchange_img">
                    <Image
                      preview
                      alt="backIdCard"
                      src={
                        getImageURL(detailData.prizeExchangeData.backIdCard)
                      }
                    />
                  </div>
                </Image.PreviewGroup>
              </Col>
            )}
            {detailData?.prizeExchangeData.firstAwardReport && (
              <Col span={12}>
                <Typography.Text strong>
                  {t('prizeExchange.firstAwardReport')}
                  :
                </Typography.Text>
                <Image.PreviewGroup>
                  <div className="p-prizeExchange_img">
                    <Image
                      preview
                      alt="firstAwardReport"
                      src={getImageURL(detailData.prizeExchangeData.firstAwardReport)}
                    />
                  </div>
                </Image.PreviewGroup>
              </Col>
            )}
            {detailData?.prizeExchangeData.secondAwardReport && (
              <Col span={12}>
                <Typography.Text strong>
                  {t('prizeExchange.secondAwardReport')}
                  :
                </Typography.Text>
                <Image.PreviewGroup>
                  <div className="p-prizeExchange_img">
                    <Image
                      preview
                      alt="secondAwardReport"
                      src={getImageURL(detailData.prizeExchangeData.secondAwardReport)}
                    />
                  </div>
                </Image.PreviewGroup>
              </Col>
            )}
            {detailData?.prizeExchangeData.thirdAwardReport && (
              <Col span={12}>
                <Typography.Text strong>
                  {t('prizeExchange.thirdAwardReport')}
                  :
                </Typography.Text>
                <Image.PreviewGroup>
                  <div className="p-prizeExchange_img">
                    <Image
                      preview
                      alt="thirdAwardReport"
                      src={getImageURL(detailData.prizeExchangeData.thirdAwardReport)}
                    />
                  </div>
                </Image.PreviewGroup>
              </Col>
            )}
            {(detailData?.prizeExchangeData.prize === 1
              && detailData?.prizeExchangeData.fourthAwardReport) && (
                <Col span={12}>
                  <Typography.Text strong>
                    {t('prizeExchange.fourthAwardReport')}
                    :
                  </Typography.Text>
                  <Image.PreviewGroup>
                    <div className="p-prizeExchange_img">
                      <Image
                        preview
                        alt="fourthAwardReport"
                        src={getImageURL(detailData.prizeExchangeData.fourthAwardReport)}
                      />
                    </div>
                  </Image.PreviewGroup>
                </Col>
              )}
            {detailData?.prizeExchangeData.scratchCard && (
              <Col span={12}>
                <Typography.Text strong>
                  {t('prizeExchange.scratchCard')}
                  :
                </Typography.Text>
                <Image.PreviewGroup>
                  <div className="p-prizeExchange_img">
                    <Image
                      preview
                      alt="scratchCard"
                      src={getImageURL(detailData.prizeExchangeData.scratchCard)}
                    />
                  </div>
                </Image.PreviewGroup>
              </Col>
            )}
            {
              detailData?.prizeExchangeData.awardImage && (
                <Col span={12}>
                  <Typography.Text strong>
                    {t('prizeExchange.awardImage')}
                    :
                  </Typography.Text>
                  <Image.PreviewGroup>
                    <div className="p-prizeExchange_img">
                      <Image
                        preview
                        alt="awardImage"
                        src={getImageURL(detailData.prizeExchangeData.awardImage)}
                      />
                    </div>
                  </Image.PreviewGroup>
                </Col>
              )
            }
          </Row>
        </Card>
      </Spin>
    </Modal>
  );
};

export default DetailModal;
