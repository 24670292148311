import {
  Button,
  Card,
  DatePicker,
  Empty,
  Radio, RadioChangeEvent, Select,
  Space,
  Spin,
  Typography
} from 'antd';
import { Chart } from 'chart.js';
import moment from 'moment';
import React, {
  useCallback, useEffect, useMemo, useRef, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';

import ChartCustom from '../Chart';
import { DropdownElement } from '../DropdownType';

import { useAppSelector } from 'app/store';
import {
  getStatisticFilterPrizeExchange,
  provinceStatisticsPrizeExchange,
  regionStatisticsPrizeExchange,
  weekStatisticsPrizeExchange
} from 'common/services/prizeExchange';
import { ProvinceStatisticsPrizeExchangeParamsTypes, RegionStatisticsPrizeExchangeParamsTypes, WeekStatisticsPrizeExchangeParamsTypes } from 'common/services/prizeExchange/types';
import { formatDateYYYYMMDD } from 'common/utils/functions';
import roles from 'configs/roles';

const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
const curTime = `${moment().get('hour')}:${moment().get('minute')}`;
const defaultDateValue = [moment(new Date()).subtract(1, 'months').format(dateFormat), `${moment(new Date()).format(dateFormat)} ${curTime}`];

const staticPrizeOptions = [
  {
    value: 0,
    label: 'Tất cả',
  },
  {
    value: 1,
    label: 'Giải nhất',
  },
  {
    value: 2,
    label: 'Giải nhì',
  }
];

const optionAll = {
  label: 'Tất cả',
  value: 'All'
};

let init = true;

const ReportWeekArea: React.FC = () => {
  const { t } = useTranslation();

  const { auth: { roles: roleUser } } = useAppSelector((store) => store);
  const [valueRadio, setValueRadio] = useState(1);
  const [reward, setReward] = useState(0);
  const [area, setArea] = useState<OptionType | undefined>(undefined);
  const [province, setProvince] = useState<string[] | undefined>(undefined);
  const [dateRange, setDateRange] = useState(defaultDateValue);

  const chartRef = useRef<Chart>(null);

  /* Queries */
  const {
    mutate: weekStatisticMutate,
    data: weekStatisticData,
    isLoading: weekLoading
  } = useMutation(
    ['get-week-statistics', area, province, dateRange],
    (params: WeekStatisticsPrizeExchangeParamsTypes) => weekStatisticsPrizeExchange({
      ...(params.provinceCodes !== optionAll.value && { provinceCodes: params.provinceCodes }),
      ...(params.regionCodes !== optionAll.value && { regionCodes: params.regionCodes }),
      fromDate: params.fromDate,
      toDate: params.toDate
    }),
  );

  const {
    mutate: regionStatisticMutate,
    data: regionStatisticData,
    isLoading: regionLoading
  } = useMutation(
    ['get-region-statistics', area, dateRange],
    (params: RegionStatisticsPrizeExchangeParamsTypes) => regionStatisticsPrizeExchange({
      ...(params.provinceCodes !== optionAll.value && { provinceCodes: params.provinceCodes }),
      fromDate: params.fromDate,
      toDate: params.toDate
    }),
  );

  const {
    mutate: provinceStatisticMutate,
    data: provinceStatisticData,
    isLoading: provinceLoading
  } = useMutation(
    ['get-province-statistics', dateRange],
    (params: ProvinceStatisticsPrizeExchangeParamsTypes) => provinceStatisticsPrizeExchange(params),
  );

  const { data: statisticFilterData } = useQuery(
    ['get-statistic-filter'],
    getStatisticFilterPrizeExchange,
    {
      enabled: roleUser.includes('*') || roleUser.includes(roles.PRIZE_EXCHANGE_STATISTICS)
    }
  );

  const statisticData = useMemo(() => {
    switch (valueRadio) {
      case 2:
        return {
          label: regionStatisticData?.map((item) => item.name) || [],
          firstPrize: regionStatisticData?.map((item) => item.prizes.first) || [],
          secondPrize: regionStatisticData?.map((item) => item.prizes.second) || []
        };
      case 3:
        return {
          label: provinceStatisticData?.map((item) => item.name) || [],
          firstPrize: provinceStatisticData?.map((item) => item.prizes.first) || [],
          secondPrize: provinceStatisticData?.map((item) => item.prizes.second) || []
        };
      default:
        return {
          label: weekStatisticData?.map((_, idx) => `${t('system.week')} ${idx + 1}`) || [`${t('system.week')} 1`],
          firstPrize: weekStatisticData?.map((item) => item.first) || [],
          secondPrize: weekStatisticData?.map((item) => item.second) || []
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueRadio, weekStatisticData, regionStatisticData, provinceStatisticData]);

  const data = useMemo(() => ({
    labels: statisticData.label,
    datasets: [
      {
        label: t('sidebar.firstPrize'),
        data: statisticData.firstPrize,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: t('sidebar.secondPrize'),
        data: statisticData.secondPrize,
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [statisticData]);

  const options = useMemo(() => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          // This more specific font property overrides the global property
          font: {
            size: 12
          }
        },
      },
    }
  }), []);

  const regionOptions: OptionType[] = useMemo(() => (statisticFilterData
    ? [optionAll, ...statisticFilterData.data.regions.map((item) => ({
      label: item.name,
      value: item.code
    }))] : []), [statisticFilterData]);

  const provinceOptions = useMemo(() => {
    if (statisticFilterData) {
      return statisticFilterData.data.provinces
        .filter((p) => (area && valueRadio !== 2 && area.value !== optionAll.value
          ? p.regionCode === area?.value : true))
        .map((item) => ({
          label: item.name,
          value: item.code
        }));
    }
    return [];
  }, [area, valueRadio, statisticFilterData]);

  const onSubmit = useCallback((radioValue?: number) => {
    if (area && province) {
      init = false;
      switch (radioValue || valueRadio) {
        case 2:
          return regionStatisticMutate({
            provinceCodes: province?.join(','),
            fromDate: moment(dateRange[0]).toISOString(),
            toDate: moment(dateRange[1]).toISOString()
          });
        case 3:
          return provinceStatisticMutate({
            fromDate: moment(dateRange[0]).toISOString(),
            toDate: moment(dateRange[1]).toISOString()
          });
        default:
          return weekStatisticMutate({
            provinceCodes: province?.join(','),
            regionCodes: area?.value?.toString(),
            fromDate: moment(dateRange[0]).toISOString(),
            toDate: moment(dateRange[1]).toISOString()
          });
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area, province, dateRange, valueRadio]);

  const onRadioChange = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value);
    onSubmit(e.target.value);
  };

  // const onProvinceChange = (val: string[]) => {
  //   const spread = [...val];
  //   const allValIdx = spread.findIndex((i) => i === 'All');
  //   if (spread.length > 0 && allValIdx !== -1) spread.splice(allValIdx, 1);
  //   // console.log('🚀 ~ file: index.tsx:227 ~ onProvinceChange ~ spread:', spread);
  //   return spread;
  // };

  useEffect(() => {
    if (init) {
      onSubmit();
    }
  }, [onSubmit]);

  useEffect(() => {
    if (chartRef.current) {
      if (reward !== 0) {
        chartRef.current.getDatasetMeta(reward === 1 ? 1 : 0).hidden = true;
        chartRef.current.getDatasetMeta(reward === 1 ? 0 : 1).hidden = false;
        chartRef.current.update();
      } else {
        chartRef.current.getDatasetMeta(0).hidden = false;
        chartRef.current.getDatasetMeta(1).hidden = false;
        chartRef.current.update();
      }
    }
  }, [reward]);

  return (
    <Space direction="vertical" style={{ gap: 12, width: '100%' }}>
      <Typography.Title level={4}>{t('dashboard.titleReportWeekArea')}</Typography.Title>
      <Card type="inner">
        <div className="t-reportWeekArea">
          <Radio.Group onChange={onRadioChange} value={valueRadio}>
            <Radio value={1}>{t('dashboard.viewByWeek')}</Radio>
            <Radio value={2}>{t('dashboard.viewByArea')}</Radio>
            <Radio value={3}>{t('dashboard.viewByProvince')}</Radio>
          </Radio.Group>
          <div className="t-reportWeekArea_filter">
            <div style={{ flex: 1 }}>
              <Select
                size="middle"
                style={{ width: '100%' }}
                className="u-mt-8"
                placeholder="---"
                value={reward}
                onChange={(val) => setReward(val)}
              >
                {
                  staticPrizeOptions.map((item, index) => (
                    <Select.Option value={item.value} key={`option-${index.toString()}`}>
                      {item.label}
                    </Select.Option>
                  ))
                }
              </Select>
            </div>
            {(valueRadio === 1) && (
              <div style={{ flex: 1 }}>
                <DropdownElement
                  options={regionOptions}
                  placeholder="-----"
                  locale="vi"
                  value={area}
                  defaultValueIdx={0}
                  onChange={(val) => setArea(val)}
                  isShowSearch
                  isGetOption
                />
              </div>
            )}
            {valueRadio !== 3 && (
              <div style={{ flex: 2 }}>
                <DropdownElement
                  options={provinceOptions}
                  placeholder="-----"
                  locale="vi"
                  value={province}
                  defaultValueIdx={0}
                  customAllOption={optionAll}
                  onChange={(val) => setProvince((!Array.isArray(val) ? [val]
                    : val))}
                  isShowSearch
                  multiple={{
                    allowClear: true
                  }}
                />
              </div>
            )}
            <div style={{ flex: 2 }}>
              <RangePicker
                style={{ width: '100%' }}
                defaultValue={[moment(dateRange[0], dateFormat),
                moment(dateRange[1], dateFormat)]}
                disabledDate={(cur) => cur > moment()}
                onChange={(val) => {
                  if (!val) return;
                  const res = val.map((item, idx) => {
                    if (idx === 1) {
                      const isToday = formatDateYYYYMMDD(item) === formatDateYYYYMMDD(moment());
                      return `${formatDateYYYYMMDD(item)} ${isToday ? curTime : '23:59'}`;
                    }
                    return formatDateYYYYMMDD(item);
                  });
                  setDateRange(res);
                }}
              />
            </div>
            <Button type="primary" onClick={() => onSubmit()}>Tìm kiếm</Button>
          </div>
        </div>
        <div className="u-mt-20" style={{ minHeight: '21.6875rem' }}>
          <Spin size="large" spinning={weekLoading || regionLoading || provinceLoading}>
            <div>
              {data
                ? (
                  <ChartCustom
                    ref={chartRef}
                    type="bar"
                    data={data}
                    options={options}
                    height={100}
                  />
                ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </div>
          </Spin>
        </div>
      </Card>
    </Space>
  );
};

export default ReportWeekArea;
