/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Space, Spin, Typography
} from 'antd';
import moment from 'moment';
import React, {
  useEffect, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useMutation, useQuery, useQueryClient
} from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import StatusLabel, { StatusButtons } from 'common/components/StatusLabel';
import {
  createRegionService, getRegionsByIdService, updateRegionService, updateRegionStatusService
} from 'common/services/location';
import { RequestDataTypes } from 'common/services/location/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { updateRegionByIdSchema } from 'common/utils/schemas';

export type EditRegionFormTypes = {
  name: string;
  code: string;
  provinceCodes: string[]
};

const EditRegion: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /* States */
  const idParams = Number(searchParams.get('id'));
  const [status, setStatus] = useState<number>(1);

  /* Variables */
  const defaultValues = {
    name: '',
    code: '',
    provinceCodes: []
  };

  const method = useForm<EditRegionFormTypes>({
    resolver: yupResolver(updateRegionByIdSchema),
    defaultValues
  });
  const { isDirty } = method.formState;

  /* Queries */
  const queryKey = ['getRegionById', idParams];
  const { data: resRegionData, isLoading } = useQuery(
    queryKey,
    () => getRegionsByIdService(idParams),
    {
      enabled: !!idParams,
      refetchOnMount: 'always',
    }
  );

  const { mutate: updateRegionServiceMutate, isLoading: updateLoading } = useMutation(
    'updateNewsById',
    async (params: {
      id: number,
      data: RequestDataTypes
    }) => updateRegionService(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: (err: ErrorResponse[]) => {
        if (err.length > 0) {
          let uniqProvinceErrorMess = '';
          err.forEach((e) => {
            if (e.field.includes('provinceCodes') && !uniqProvinceErrorMess) {
              uniqProvinceErrorMess = e.message;
            }
            if (!e.field.includes('provinceCodes')) {
              method.setError(e.field as keyof EditRegionFormTypes, { message: e.message });
            }
          });
          if (uniqProvinceErrorMess) {
            method.setError('provinceCodes', { message: uniqProvinceErrorMess });
          }
        }
        message.error(t('message.updateError'));
      }
    }
  );

  const { mutate: createRegionMutate, isLoading: createLoading } = useMutation(
    'createNews',
    async (data: RequestDataTypes) => createRegionService(data),
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigate(`${ROUTE_PATHS.REGION_MANAGEMENT}`);
      },
      onError: (err: ErrorResponse[]) => {
        if (err.length > 0) {
          err.forEach((e) => {
            if (e.field.includes('provinceCodes')) return method.setError(e.field as keyof EditRegionFormTypes, { message: e.message });
            return method.setError(e.field as keyof EditRegionFormTypes, { message: e.message });
          });
        }
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updateStatusMutate, isLoading: updateStatusLoading } = useMutation(
    ['updateRegionStatus', idParams],
    async (data: {
      id: number, status: number
    }) => updateRegionStatusService(data),
    {
      onSuccess: (_, params) => {
        setStatus(params.status);
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries(queryKey);
      },
      onError: () => {
        message.error(t('message.updateStatusError'));
      }
    }
  );

  /* Functions */

  const onSubmit = async (addingFn?: (id: number) => void) => {
    const isValid = await method.trigger();
    if (!isValid || !isDirty) {
      return;
    }
    const formData = method.getValues();
    const provinceCodes = formData.provinceCodes.reduce((acc: string[], cur) => {
      const arr = resRegionData?.provinces.filter(
        (p) => p?.name === cur
      ).map((item) => item?.code);
      if (arr && arr.length > 0) {
        acc = [...acc, ...arr];
      } else {
        acc = [...acc, cur.toString()];
      }
      return acc;
    }, []);

    const requestData: RequestDataTypes = {
      name: formData.name,
      provinceCodes,
      ...(!idParams && { code: formData.code }),
    };

    if (idParams) {
      updateRegionServiceMutate({ id: idParams, data: requestData });
    } else {
      createRegionMutate(requestData, {
        onSuccess: (res) => addingFn && addingFn(res.data.id),
      });
    }
  };

  const handleChangeStatusAndSave = async (stat: number) => {
    if (!idParams) {
      await onSubmit((id) => {
        updateStatusMutate({ id, status: stat });
      });
    } else {
      if (isDirty) {
        await onSubmit();
      }
      updateStatusMutate({ id: idParams, status: stat });
    }
  };

  const submitForm = async () => {
    onSubmit();
    queryClient.invalidateQueries(queryKey);
  };

  /* Effects */
  useEffect(() => {
    if (resRegionData) {
      const { regionData: { code, name, status: stt }, provinces } = resRegionData;
      setStatus(stt);
      method.reset({
        code,
        name,
        provinceCodes: provinces.map((item) => item?.name)
      });
    } else method.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resRegionData, method]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('newsDetail.editRegion')}
        rightHeader={(
          <Space size={16}>
            {status && (
              <StatusButtons
                loading={updateStatusLoading}
                status={status}
                isApprove={roleUpdate}
                handleChangeStatus={(stat) => {
                  handleChangeStatusAndSave(stat);
                }}
              />
            )}
            {status !== 13 && (
              <Button
                type="primary"
                disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
                loading={updateLoading || createLoading}
                onClick={method.handleSubmit(submitForm)}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            )}
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <FormProvider {...method}>
          <Spin size="large" spinning={isLoading || updateLoading || createLoading}>
            <Row gutter={16} style={{ margin: '0px' }}>
              <Col xxl={18} xl={16} lg={16}>
                <Space direction="vertical" size={12} style={{ width: '100%' }}>
                  <Card type="inner">
                    {/* Tiêu đề  */}
                    <Row gutter={16}>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input">
                          <Typography.Text strong>
                            {t('system.name')}
                            {' '}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="name"
                            control={method.control}
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input">
                          <Typography.Text strong>
                            {t('system.code')}
                            {' '}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="code"
                            control={method.control}
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                                disabled={!!idParams}
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={24}>
                        <div className="p-editPageTemplate_input u-mt-8">
                          <Typography.Text strong>
                            {t('system.provinces')}
                            {' '}
                          </Typography.Text>
                          <Controller
                            name="provinceCodes"
                            control={method.control}
                            render={({ field: { value, onChange }, fieldState: { error } }) => (
                              <DropdownElement
                                value={value}
                                onChange={onChange}
                                type="province"
                                isValueSlug
                                isShowSearch
                                placeholder={`${t('system.select')} ${t('system.provinces')}`}
                                filterParams={idParams.toString()}
                                locale="vi"
                                multiple={{
                                  allowClear: true,
                                }}
                                error={error?.message}
                              />
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Space>
              </Col>
              <Col xxl={6} xl={8} lg={8}>
                <div className="u-mb-16">
                  {status && <StatusLabel status={status} bigger />}
                </div>
                <ManagementInfo
                  createdDate={resRegionData ? moment(resRegionData.regionData?.createdAt).fromNow() : ''}
                  createdBy={resRegionData?.creator?.name || ''}
                  lastUpdated={resRegionData ? moment(resRegionData.regionData?.updatedAt).fromNow() : ''}
                  lastUpdatedBy={resRegionData?.updater?.name || ''}
                />
              </Col>
            </Row>
          </Spin>
        </FormProvider>
      </div>
    </>
  );
};

export default EditRegion;
