import {
  Button, Col, Row, Typography
} from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import PopupCancel from './PopupCancel';
import PopupCreate from './PopupCreate';

import { getAllSecondPrizeService } from 'common/services/secondPrize';

const SecondPrizeManagement: React.FC<ActiveRoles> = () => {
  const [isPopupCancelCode, setIsPopupCancelCode] = useState(false);
  const [isPopupCreateCode, setIsPopupCreateCode] = useState(false);

  const { t } = useTranslation();

  const { data: second } = useQuery(
    ['getAllSecondPrizeService'],
    () => getAllSecondPrizeService(),
  );

  return (
    <>
      <div className="p-second_submit">
        <Button
          type="default"
          style={{
            background: '#015714',
            height: 44,
            borderRadius: 4,
            color: '#fff',
            fontSize: 14
          }}
          onClick={() => setIsPopupCreateCode(true)}
        >
          {t('second.createCode')}
        </Button>
        <div className="p-second_button">
          <Button
            style={{
              background: '#015714',
              height: 44,
              borderRadius: 4,
              color: '#fff',
              fontSize: 14
            }}
            type="default"
            onClick={() => setIsPopupCancelCode(true)}
          >
            {t('second.cancelCode')}
          </Button>
        </div>
      </div>
      <Row className="t-mainlayout_wrapper">
        <Col xxl={6} xl={8} lg={8}>
          <div className="p-second_wrapText">
            <Typography.Text style={
              {
                fontSize: 16,
                color: '#222'
              }
            }
            >
              {t('second.totalQuantity')}
            </Typography.Text>
            <Typography.Text
              style={
                {
                  fontSize: 40,
                  color: '#222'
                }
              }
              className="u-ml-21"
            >
              {second?.totalQuantity}
            </Typography.Text>
          </div>
        </Col>
        <Col xxl={6} xl={8} lg={8}>
          <div className="p-second_wrapText">
            <Typography.Text style={
              {
                fontSize: 16,
                color: '#222'
              }
            }
            >
              {t('second.totalRedeemed')}
            </Typography.Text>
            <Typography.Text
              style={
                {
                  fontSize: 40,
                  color: '#222'
                }
              }
              className="u-ml-21"
            >
              {second?.totalRedeemed}
            </Typography.Text>
          </div>
        </Col>
        <Col xxl={6} xl={8} lg={8}>
          <div className="p-second_wrapText">
            <Typography.Text style={
              {
                fontSize: 16,
                color: '#222'
              }
            }
            >
              {t('second.totalCanceledQuantity')}
            </Typography.Text>
            <Typography.Text
              style={
                {
                  fontSize: 40,
                  color: '#222'
                }
              }
              className="u-ml-21"
            >
              {second?.totalCanceledQuantity}
            </Typography.Text>
          </div>
        </Col>
      </Row>
      <PopupCancel
        isShow={isPopupCancelCode}
        handleClose={() => setIsPopupCancelCode(false)}
      />
      <PopupCreate
        isShow={isPopupCreateCode}
        handleClose={() => setIsPopupCreateCode(false)}
      />
    </>
  );
};

export default SecondPrizeManagement;
