import {
  EditOutlined,
  ExclamationCircleFilled, ExportOutlined, EyeOutlined, InboxOutlined, PlusOutlined, StopOutlined
} from '@ant-design/icons';
import {
  Button, Modal, Space, Tooltip, Typography, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { AxiosError } from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import DetailModal from './detailModal';
import ReportedModal, { CheckCodeFormType } from './reportedModal';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Icon from 'common/components/Icon';
import Input from 'common/components/Input';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps,
  FilterValueProps,
  mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter,
  mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { StatusPrizeExchangeLabel } from 'common/components/StatusLabel';
import {
  checkExportPrizeExchangeByProvince,
  exportPrizeExchange,
  exportPrizeExchangeByProvince,
  getPrizeExchangesList,
  cancelPrizeExchange,
  reportedPrizeExchange
} from 'common/services/prizeExchange';
import {
  PrizeExchangeStatusTypes, PrizeExchangePrizeTypes,
  CancelPrizeExchangeRequestType, ReportedPrizeExchangeRequestTypes
} from 'common/services/prizeExchange/types';
import { BASE_URL, ROUTE_PATHS } from 'common/utils/constant';
import {
  detectError, downloadFile, formatDateDDMMYYYY, formatDateTime
} from 'common/utils/functions';
import roles, { getPermission } from 'configs/roles';

type FormType = {
  reason: string;
};

export type PrizeExchangeType = {
  id: number,
  phone: string,
  name: string,
  address: string,
  status: PrizeExchangeStatusTypes,
  prize: PrizeExchangePrizeTypes,
  leaderName: string,
  sale: {
    id: number,
    name: string
  } | null,
  reportedAt: string;
  createdAt: string;
  updatedAt: string;
  contactedAt: string;
  province: string;
  region: string;
  stalled: boolean;
  exception: boolean;
  exchangedAt: string;
  reportedReason: string;
  verifiedAt: string;
  documentAddedAt: string;
  actualAwardDate: string;
};

const PrizeExchangeManagement: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate, roleOther }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [activeIdx, setActiveIdx] = useState<number | undefined>(undefined);
  const [activeCancelIdx, setActiveCancelIdx] = useState(-1);
  const [activeReported, setActiveReported] = useState<PrizeExchangeType | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* Form Method */
  const method = useForm<FormType>({
    mode: 'onSubmit',
    defaultValues: {
      reason: ''
    }
  });

  const methodReported = useForm<CheckCodeFormType>({
    mode: 'onSubmit',
    defaultValues: {
      code: ''
    }
  });

  /* React-query */
  const queryKeys = ['prize-exchanges-list', currentPage, currentView, selectedFilterList];
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKeys,
    () => getPrizeExchangesList({ page: currentPage, limit: currentView, ...returnParamsFilter }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  const {
    isLoading: checkLoading,
    data: checkExportFlag,
  } = useQuery(
    ['check-export-province'],
    () => checkExportPrizeExchangeByProvince(),
    { enabled: getPermission(roleOther, roles.PRIZE_EXCHANGE_EXPORT_PROVINCE) }
  );

  const { mutate: exportMutate, isLoading: exportLoading } = useMutation(
    ['prize-exchange-export'],
    () => exportPrizeExchange({ ...returnParamsFilter }),
    {
      onSuccess(res) {
        if (res.data.link) downloadFile(`${BASE_URL}${res.data.link}`);
      },
    }
  );

  const { mutate: exportByProvinceMutate, isLoading: exportByProvinceLoading } = useMutation(
    ['prize-exchange-export-province'],
    () => exportPrizeExchangeByProvince({ ...returnParamsFilter }),
    {
      onSuccess(res) {
        if (res.data.link) downloadFile(`${BASE_URL}${res.data.link}`);
        queryClient.invalidateQueries(queryKeys);
        queryClient.invalidateQueries(['check-export-province']);
      },
    }
  );

  const { mutate: cancelMutate, } = useMutation(
    ['prize-exchange-cancel'],
    (data: CancelPrizeExchangeRequestType) => cancelPrizeExchange(data),
    {
      onSuccess() {
        setActiveCancelIdx(-1);
        message.success('message.success');
        queryClient.invalidateQueries(queryKeys);
      },
      onError(err: unknown) {
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(`${t('system.code')} ${t(detectError(Number(err.response?.status)))}`);
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        } else {
          (err as ErrorResponse[]).forEach((e) => methodReported.setError(e.field as keyof CheckCodeFormType, { message: t(`errorMess.${e.field}.${e.code}`) }));
        }
      }
    }
  );

  const { mutate: reportedMutate, } = useMutation(
    ['prize-exchange-reported'],
    (data: ReportedPrizeExchangeRequestTypes) => reportedPrizeExchange(data),
    {
      onSuccess() {
        setActiveReported(undefined);
        message.success('message.success');
        queryClient.invalidateQueries(queryKeys);
      },
      onError(err: unknown) {
        if (err instanceof AxiosError) {
          if (Number(err.response?.status) === 404) {
            message.error(`${t('system.code')} ${t(detectError(Number(err.response?.status)))}`);
          } else {
            message.error(detectError(Number(err.response?.status)));
          }
        } else {
          (err as ErrorResponse[]).forEach((e) => methodReported.setError(e.field as keyof CheckCodeFormType, { message: t(`errorMess.${e.field}.${e.code}`) }));
        }
      }
    }
  );

  /* Datas */
  const columns: ColumnsType<PrizeExchangeType> = useMemo(() => ([
    // --- ID
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Số điện thoại
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a: PrizeExchangeType, b: PrizeExchangeType) => a.phone.localeCompare(b.phone),
      },
      sortDirections: ['descend', 'ascend'],
    },
    // --- Tên người dùng
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    {
      title: t('prizeExchange.address'),
      dataIndex: 'address',
      key: 'address',
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {data.address}
        </Typography.Text>
      ),
    },
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      width: 150,
      sorter: {
        compare: (a: PrizeExchangeType, b: PrizeExchangeType) => a.status - b.status,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PrizeExchangeType) => {
        const tooltipContent = () => {
          switch (data.status) {
            case 1:
              return t('prizeExchange.tooltipPending');
            case 2:
              return t('prizeExchange.tooltipExchanging');
            case 3:
              return t('prizeExchange.tooltipExchanged');
            case 4:
              return t('prizeExchange.tooltipCanceled');
            default:
              return '';
          }
        };
        return (
          <Space>
            {tooltipContent() ? (
              <Tooltip title={tooltipContent()}>
                <div>
                  <StatusPrizeExchangeLabel status={data.status} />
                </div>
              </Tooltip>
            ) : (
              <StatusPrizeExchangeLabel status={data.status} />
            )}
            {data.stalled && (
              <Tooltip title={t('prizeExchange.expiredMess')}>
                <div>
                  <Icon iconName="flag" size="18" />
                </div>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      title: t('prizeExchange.prize'),
      dataIndex: 'prize',
      key: 'prize',
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {`${data.prize === 1 ? t('prizeExchange.firstPrize') : t('prizeExchange.secondPrize')}`}
        </Typography.Text>
      ),
    },
    {
      title: t('prizeExchange.reported'),
      dataIndex: 'reported',
      key: 'reported',
      render: (_name: string, data: PrizeExchangeType) => (
        data.reportedAt && (
          <Space>
            <Tooltip
              color="#3C3C3C"
              title={(
                <>
                  {data.reportedReason}
                  <br />
                  {formatDateTime(data.reportedAt)}
                </>
              )}
              overlayStyle={{
                borderRadius: '8px',
              }}
            >
              <ExclamationCircleFilled style={{ color: '#FF0000' }} />
            </Tooltip>
            {data.exception && (
              <Tooltip
                color="#3C3C3C"
                title={(
                  <>
                    {t('prizeExchange.reportedMess')}
                  </>
                )}
                overlayStyle={{
                  borderRadius: '8px',
                }}
              >
                <div>
                  <Icon iconName="exception" />
                </div>
              </Tooltip>
            )}
          </Space>
        )
      ),
    },
    {
      title: t('prizeExchange.saleName'),
      dataIndex: 'saleName',
      key: 'saleName',
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {data.sale?.name}
        </Typography.Text>
      ),
    },
    {
      title: t('prizeExchange.province'),
      dataIndex: 'province',
      key: 'province',
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {data.province}
        </Typography.Text>
      ),
    },
    {
      title: t('role.region'),
      dataIndex: 'region',
      key: 'region',
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {data.region}
        </Typography.Text>
      ),
    },
    // --- Liên lạc lúc
    {
      title: t('prizeExchange.contactedAt'),
      dataIndex: 'contactedAt',
      key: 'contactedAt',
      maxWidth: 100,
      sorter: {
        compare: (a: PrizeExchangeType, b: PrizeExchangeType) => {
          const aDate = new Date(a.contactedAt || 0);
          const bDate = new Date(b.contactedAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {formatDateTime(data.contactedAt)}
        </Typography.Text>
      ),
    },
    // --- Chứng từ mềm nhận lúc
    {
      title: t('filterField.documentAddedAt'),
      dataIndex: 'documentAddedAt',
      key: 'documentAddedAt',
      maxWidth: 100,
      sorter: {
        compare: (a: PrizeExchangeType, b: PrizeExchangeType) => {
          const aDate = new Date(a.documentAddedAt || 0);
          const bDate = new Date(b.documentAddedAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {formatDateTime(data.documentAddedAt)}
        </Typography.Text>
      ),
    },
    // --- Hoàn thành lúc
    {
      title: t('filterField.exchangedAt'),
      dataIndex: 'exchangedAt',
      key: 'exchangedAt',
      maxWidth: 100,
      sorter: {
        compare: (a: PrizeExchangeType, b: PrizeExchangeType) => {
          const aDate = new Date(a.exchangedAt || 0);
          const bDate = new Date(b.exchangedAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {formatDateTime(data.exchangedAt)}
        </Typography.Text>
      ),
    },
    // --- Ngày trao giải thực tế
    {
      title: t('prizeExchange.actualAwardDate'),
      dataIndex: 'actualAwardDate',
      key: 'actualAwardDate',
      maxWidth: 100,
      sorter: {
        compare: (a: PrizeExchangeType, b: PrizeExchangeType) => {
          const aDate = new Date(a.actualAwardDate || 0);
          const bDate = new Date(b.actualAwardDate || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {formatDateDDMMYYYY(data.actualAwardDate)}
        </Typography.Text>
      ),
    },
    // --- Tạo lúc
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      maxWidth: 100,
      sorter: {
        compare: (a: PrizeExchangeType, b: PrizeExchangeType) => {
          const aDate = new Date(a.createdAt || 0);
          const bDate = new Date(b.createdAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      maxWidth: 100,
      key: 'updatedAt',
      sorter: {
        compare: (a: PrizeExchangeType, b: PrizeExchangeType) => {
          const aDate = new Date(a.updatedAt || 0);
          const bDate = new Date(b.updatedAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: PrizeExchangeType) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Xem
    {
      title: t('system.action'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: PrizeExchangeType) => (
        <Space>
          <Tooltip
            title={t('prizeExchange.seeDetail')}
          >
            <Button
              icon={<EyeOutlined />}
              onClick={() => setActiveIdx(data.id)}
            />
          </Tooltip>
          <Tooltip
            title={t('prizeExchange.cancelExchange')}
          >
            <Button
              disabled={!(getPermission(roleOther, roles.PRIZE_EXCHANGE_CANCEL)
                && data.status !== 4)}
              icon={<StopOutlined />}
              onClick={() => setActiveCancelIdx(data.id)}
            />
          </Tooltip>
          <Tooltip title={t('prizeExchange.reportedCode')}>
            <Button
              disabled={!(getPermission(roleOther, roles.PRIZE_EXCHANGE_EXCHANGE)
                && !!data.reportedAt && !data.exception && data.status === 2 && !data.verifiedAt)}
              icon={<InboxOutlined />}
              onClick={() => setActiveReported(data)}
            />
          </Tooltip>
          <Tooltip title={t('system.edit')}>
            <Button
              disabled={!roleUpdate}
              icon={<EditOutlined />}
              onClick={() => navigate(`${ROUTE_PATHS.PRIZE_EXCHANGE_DETAIL}?id=${data.id}`)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]), [t, roleOther]);

  const tableData: PrizeExchangeType[] = useMemo(() => (
    listData?.data?.map((item) => ({
      id: item.prizeExchangeData.id,
      phone: item.prizeExchangeData.phone,
      name: item.prizeExchangeData.name,
      address: item.prizeExchangeData.address,
      status: item.prizeExchangeData.status,
      prize: item.prizeExchangeData.prize,
      leaderName: item.province.leader?.name || '',
      sale: item.sale,
      reportedAt: item.prizeExchangeData?.reportedAt || '',
      createdAt: item.prizeExchangeData.createdAt,
      updatedAt: item.prizeExchangeData.updatedAt,
      contactedAt: item.prizeExchangeData.contactedAt,
      province: item.province.name,
      region: item.province.region?.name || '',
      stalled: item.prizeExchangeData.stalled,
      exception: item.prizeExchangeData.exception,
      exchangedAt: item.prizeExchangeData.exchangedAt || '',
      reportedReason: item.prizeExchangeData.reportedReason || '',
      verifiedAt: item.prizeExchangeData.verifiedAt || '',
      documentAddedAt: item.prizeExchangeData.documentAddedAt || '',
      actualAwardDate: item.prizeExchangeData.actualAwardDate || ''
    })) || []), [listData]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
    handleSetCurrentPage(1);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
    handleSetCurrentPage(1);
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('prizeExchange', advancedFilter),
    [advancedFilter]
  );

  const onCancel = () => {
    const { reason } = method.getValues();
    cancelMutate({
      id: activeCancelIdx,
      reason
    });
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.prizeExchange')}
        rightHeader={(
          <Space>
            <Button
              loading={exportLoading}
              disabled={!getPermission(roleOther, roles.PRIZE_EXCHANGE_EXPORT)}
              onClick={() => exportMutate()}
            >
              <ExportOutlined />
              {t('system.exportReport')}
            </Button>
            <Button
              loading={exportByProvinceLoading || checkLoading}
              disabled={!checkExportFlag?.data}
              onClick={() => exportByProvinceMutate()}
            >
              <ExportOutlined />
              {t('system.exportReportProvince')}
            </Button>
            <Button
              type="primary"
              disabled={!roleCreate}
              onClick={() => navigate(`${ROUTE_PATHS.PRIZE_EXCHANGE_DETAIL}`)}
            >
              <PlusOutlined />
              {t('system.create')}
            </Button>
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          noCheckbox
          handleSearch={() => { }}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          tableProps={{
            initShowColumns: ['id', 'phone', 'name', 'address', 'status', 'prize', 'reported', 'saleName', 'province', 'region', 'contactedAt', 'documentAddedAt', 'exchangedAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields
          }}
        />
        <DetailModal
          roleOther={roleOther}
          openId={activeIdx}
          handleClose={() => setActiveIdx(undefined)}
        />
        <Modal
          title={t('prizeExchange.titleReasonModal')}
          visible={activeCancelIdx !== -1}
          closable
          onOk={method.handleSubmit(onCancel)}
          onCancel={() => {
            setActiveCancelIdx(-1);
            method.reset();
          }}
        >
          <FormProvider {...method}>
            <Controller
              name="reason"
              control={method.control}
              render={({ field, fieldState: { error } }) => (
                <>
                  {t('prizeExchange.cancelReason')}
                  <Input
                    {...field}
                    error={error?.message}
                  />
                </>
              )}
            />
          </FormProvider>
        </Modal>
        <ReportedModal
          method={methodReported}
          isOpen={!!activeReported}
          handleSubmit={() => reportedMutate({
            id: activeReported?.id!,
            data: methodReported.getValues()
          })}
          handleClose={() => setActiveReported(undefined)}
        />
      </div>
    </>
  );
};

export default PrizeExchangeManagement;
