import {
  Col, Row, Space, Typography
} from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import HeadingPrizeSection from './HeadingPrizeSection';

import { useAppSelector } from 'app/store';
import { ReportReward } from 'common/components/ReportReward';
import ReportTopUpSms from 'common/components/ReportTopUpSms';
import ReportWeekArea from 'common/components/ReportWeekArea';
import { summaryStatisticsPrizeExchange } from 'common/services/prizeExchange';
import roles, { getPermission } from 'configs/roles';

const BACKGROUND_COLOR = [
  'rgba(127, 224, 193, 1)',
  'rgba(254, 220, 102, 1)',
  'rgba(0, 255, 71, 1)',
  'rgba(255, 87, 34, 1)',
];

const Home: React.FC = () => {
  const rolesUser = useAppSelector((state) => state.auth.roles);
  const { t } = useTranslation();

  const roleHeadingPrizeSection = rolesUser.includes('*')
    || rolesUser.includes(roles.FIRST_PRIZE_BLOCK_STATISTICS)
    || rolesUser.includes(roles.SECOND_PRIZE_BLOCK_STATISTICS)
    || rolesUser.includes(roles.TOPUP_PRIZE_STATISTICS);

  const roleStatisticSection = getPermission(rolesUser, roles.PRIZE_EXCHANGE_STATISTICS);

  const roleStatisticSLASection = getPermission(rolesUser, roles.TOPUP_WINNER_STATISTIC);

  const { data: summaryStatistics } = useQuery(
    ['getSummaryStatisticsPrizeExchange'],
    () => summaryStatisticsPrizeExchange(),
    {
      enabled: roleStatisticSection,
    }
  );

  const dataFirst = useMemo(() => {
    if (!(summaryStatistics && summaryStatistics.first)) return [];
    return {
      labels: [
        t('prizeExchange.pendingStatus'),
        t('prizeExchange.exchangingStatus'),
        t('prizeExchange.exchangedStatus'),
        t('prizeExchange.canceledStatus'),
      ],
      datasets: [
        {
          data: [
            summaryStatistics.first.pending,
            summaryStatistics.first.exchanging,
            summaryStatistics.first.exchanged,
            summaryStatistics.first.canceled,
          ],
          backgroundColor: BACKGROUND_COLOR,
          borderColor: BACKGROUND_COLOR,
          borderWidth: 1,
        },
      ],
    };
  }, [summaryStatistics, t]);

  const dataSecond = useMemo(() => {
    if (!(summaryStatistics && summaryStatistics.second)) return [];
    return {
      labels: [
        t('prizeExchange.pendingStatus'),
        t('prizeExchange.exchangingStatus'),
        t('prizeExchange.exchangedStatus'),
        t('prizeExchange.canceledStatus'),
      ],
      datasets: [
        {
          data: [
            summaryStatistics.second.pending,
            summaryStatistics.second.exchanging,
            summaryStatistics.second.exchanged,
            summaryStatistics.second.canceled,
          ],
          backgroundColor: BACKGROUND_COLOR,
          borderColor: BACKGROUND_COLOR,
          borderWidth: 1,
        },
      ],
    };
  }, [summaryStatistics, t]);

  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom' as const,
          labels: {
            // This more specific font property overrides the global property
            font: {
              size: 12,
            },
          },
        },
      },
    }),
    []
  );

  return (
    <div className="p-home">
      <Space direction="vertical" size={40} style={{ width: '100%' }}>
        {roleHeadingPrizeSection && (
          <section className="p-home_section">
            <HeadingPrizeSection />
          </section>
        )}
        {roleStatisticSection && (
          <section className="p-home_section">
            <Typography.Title level={3}>
              {t('dashboard.prizeAwardedTitle')}
            </Typography.Title>
            <div className="u-mt-20">
              <ReportWeekArea />
            </div>
            <div className="u-mt-20">
              <Row gutter={24}>
                <Col span={12}>
                  <ReportReward
                    title={t('dashboard.titleReportFirstPrize')}
                    options={options}
                    data={dataFirst}
                    idLegendTooltip="tooltip-pieChart-first"
                  />
                  <div id="tooltip-pieChart-first" />
                </Col>
                <Col span={12}>
                  <ReportReward
                    title={t('dashboard.titleReportSecondPrize')}
                    options={options}
                    data={dataSecond}
                    idLegendTooltip="tooltip-pieChart-second"
                  />
                  <div id="tooltip-pieChart-second" />
                </Col>
              </Row>
            </div>
          </section>
        )}
        {roleStatisticSLASection && (
          <section className="p-home_section">
            <Typography.Title level={3}>
              {t('dashboard.topupWinner')}
            </Typography.Title>
            <div className="u-mt-20">
              <ReportTopUpSms />
            </div>
          </section>
        )}
      </Space>
    </div>
  );
};

export default Home;
