/* eslint-disable no-unsafe-optional-chaining */
import {
  Button, Card, Col, Modal, Pagination, Row, Space, Spin, Tabs, Typography
} from 'antd';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { StatusPrizeExchangeLabel, StatusTopUpLabel, StatusTopUpSMSLabel } from '../StatusLabel';

import { getInfoPrizeExchangeByPhone } from 'common/services/prizeExchange';
import { getInfoTopUpSMSByPhone } from 'common/services/topupSMS';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

export type CallCenterData = {
  phone: string;
  timestamp: number;
  callId: string
};
type TabKeysTypes = 'prizeExchange' | 'topUpSms';
type StatusTypes = 'status' | 'statusSms' | 'statusTopUp';
type RenderValTypes = {
  value: any;
  statusType?: StatusTypes
};

interface Props {
  socketData?: CallCenterData
  handleClose(): void
}

const SocketDataModal: React.FC<Props> = ({ socketData, handleClose }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState<TabKeysTypes>('prizeExchange');
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const {
    mutate: callCenterPrizeExchangeMutate,
    data: prizeExchangeData,
    isLoading: prizeExchangeLoading
  } = useMutation(
    ['prize-exchange-call-center'],
    (data: {
      phone: string,
      params: BaseFilterParams
    }) => getInfoPrizeExchangeByPhone(data),
    {
      onSuccess: (res) => {
        if (res.meta) setTotal(res.meta.totalPages);
      }
    }
  );

  const {
    mutate: callCenterTopUpSMSMutate,
    data: topUpSmsData,
    isLoading: topUpSmsLoading
  } = useMutation(
    ['topUp-sms-call-center'],
    (
      data: {
        phone: string,
        params: BaseFilterParams
      }
    ) => getInfoTopUpSMSByPhone(data),
    {
      onSuccess: (res) => {
        if (res.meta) setTotal(res.meta.totalPages);
      }
    }
  );

  const renderVal = (data: RenderValTypes) => {
    switch (data.statusType) {
      case 'status':
        return <StatusPrizeExchangeLabel status={data.value} />;
      case 'statusTopUp':
        return <StatusTopUpLabel status={data.value} />;
      case 'statusSms':
        return <StatusTopUpSMSLabel status={data.value} />;
      default:
        return (
          <Typography.Text>
            {data.value}
          </Typography.Text>
        );
    }
  };

  const formatPrizeExchange = useMemo(() => (prizeExchangeData?.data
    ? prizeExchangeData?.data.map((item) => ({
      id: { value: item.prizeExchangeData.id },
      name: { value: item.prizeExchangeData.name },
      address: { value: item.prizeExchangeData.address },
      status: { value: item.prizeExchangeData.status, statusType: 'status' },
      prize: { value: item.prizeExchangeData.prize === 1 ? t('prizeExchange.firstPrize') : t('prizeExchange.secondPrize') },
      contactedAt: { value: formatDateTime(item.prizeExchangeData.contactedAt) },
      documentReviewedAt: { value: formatDateTime(item.prizeExchangeData.documentReviewedAt) },
      verifiedAt: { value: formatDateTime(item.prizeExchangeData.verifiedAt) },
      exchangedAt: { value: formatDateTime(item.prizeExchangeData.exchangedAt) },
      canceledAt: { value: formatDateTime(item.prizeExchangeData.canceledAt) },
      cancelReason: { value: item.prizeExchangeData.cancelReason },
      reportedAt: { value: formatDateTime(item.prizeExchangeData.reportedAt) },
      reportedReason: { value: item.prizeExchangeData.reportedReason }
    })) : []), [prizeExchangeData]);

  const formatTopUpSMS = useMemo(() => (topUpSmsData?.data
    ? topUpSmsData.data.map((item) => ({
      attemptId: { value: item.topupSmsData.attemptId },
      phone: { value: item.topupSmsData.phone },
      telco: { value: item.topupSmsData.telco },
      status: { value: item.topupSmsData.status, statusType: 'statusSms' },
      topupStatus: { value: item.topupSmsData.topupStatus, statusType: 'statusTopUp' },
      limitExceeded: { value: item.topupSmsData.limitExceeded ? t('topupSMS.active') : t('topupSMS.inactive') },
      postPaid: { value: item.topupSmsData.postPaid ? t('topupSMS.postpaid') : t('topupSMS.prePaid') },
      finishedAt: { value: formatDateTime(item.topupSmsData.finishedAt) },
      createdAt: { value: formatDateTime(item.topupSmsData.createdAt) }
    })) : []), [topUpSmsData]);

  const onSwitchTab = useCallback((page: number) => {
    if (socketData?.phone) {
      if (activeTab === 'prizeExchange') {
        callCenterPrizeExchangeMutate({ phone: socketData.phone, params: { limit: 5, page } });
      } else {
        callCenterTopUpSMSMutate({ phone: socketData.phone, params: { limit: 5, page } });
      }
    }
  }, [socketData, activeTab, callCenterPrizeExchangeMutate, callCenterTopUpSMSMutate]);

  useEffect(() => {
    onSwitchTab(1);
  }, [onSwitchTab]);

  return (
    <Modal
      width="50vw"
      visible={!!socketData}
      footer={null}
      closable
      onCancel={handleClose}
    >
      <Card
        title={(
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Typography.Text>{t('sidebar.general')}</Typography.Text>
            <Button
              type="primary"
              onClick={() => {
                handleClose();
                navigate(
                  ROUTE_PATHS.PRIZE_EXCHANGE_DETAIL,
                  { state: { callId: socketData?.callId, phone: socketData?.phone } }
                );
              }}
            >
              {t('callCenter.createPrize')}

            </Button>
          </Space>
        )}
        style={{ marginTop: '1.5rem' }}
      >
        <Row>
          <Col span={12}>
            <Typography.Text strong>
              {t('callCenter.callID')}
              :
            </Typography.Text>
            <Typography.Text className="u-ml-4">
              {socketData?.callId}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text strong>
              {t('system.phone')}
              :
            </Typography.Text>
            <Typography.Text className="u-ml-4">
              {socketData?.phone}
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Typography.Text strong>
              {t('callCenter.calledAt')}
              :
            </Typography.Text>
            <Typography.Text className="u-ml-4">
              {socketData?.timestamp ? formatDateTime(new Date(socketData?.timestamp * 1000).toISOString()) : ''}
            </Typography.Text>
          </Col>
        </Row>
      </Card>
      <Tabs
        centered
        defaultActiveKey="prizeExchange"
        onChange={(key) => {
          setCurrentPage(1);
          setActiveTab(key as TabKeysTypes);
        }}
      >
        <Tabs.TabPane tab="Prize Exchange Info" key="prizeExchange">
          <Spin spinning={prizeExchangeLoading}>
            {formatPrizeExchange.length > 0 ? (
              <>
                <div className="t-mainlayout_table">
                  {formatPrizeExchange.length > 0 && formatPrizeExchange.map((item, idx) => (
                    <Card className="t-mainlayout_card">
                      <Row gutter={16}>
                        {
                          Object.keys(item).map((key, keyIdx) => (
                            <Col
                              key={`item-${keyIdx.toString()}-col-${idx.toString()}-prizeExchange`}
                              span={12}
                              className="u-mt-6"
                            >
                              <Space>
                                <Typography.Text strong>
                                  {t(`callCenter.${key}`)}
                                  :
                                </Typography.Text>
                                {renderVal(item[key as keyof typeof item] as RenderValTypes)}
                              </Space>
                            </Col>
                          ))
                        }
                      </Row>
                    </Card>
                  ))}
                </div>
                <div className="t-mainlayout_tab_pagination">
                  <Pagination
                    current={currentPage}
                    onChange={(page) => {
                      setCurrentPage(page);
                      onSwitchTab(page);
                    }}
                    total={total}
                    pageSize={1}
                  />
                </div>
              </>
            ) : <div className="t-mainlayout_table_text">{t('callCenter.nulllist')}</div>}
          </Spin>
        </Tabs.TabPane>
        <Tabs.TabPane tab="TopUp SMS Info" key="topUpSms">
          <Spin spinning={topUpSmsLoading}>
            {formatTopUpSMS.length > 0 ? (
              <>
                <div className="t-mainlayout_table">
                  {
                    formatTopUpSMS.map((item, idx) => (
                      <Card className="t-mainlayout_card">
                        <Row gutter={16}>
                          {
                            Object.keys(item).map((key, keyIdx) => (
                              <Col
                                key={`item-${keyIdx.toString()}-col-${idx.toString()}-prizeExchange`}
                                span={12}
                                className="u-mt-6"
                              >
                                <Space>
                                  <Typography.Text strong>
                                    {t(`callCenter.${key}`)}
                                    :
                                  </Typography.Text>
                                  {renderVal(item[key as keyof typeof item] as RenderValTypes)}
                                </Space>
                              </Col>
                            ))
                          }
                        </Row>
                      </Card>
                    ))
                  }
                </div>
                <div className="t-mainlayout_tab_pagination">
                  <Pagination
                    current={currentPage}
                    onChange={(page) => {
                      setCurrentPage(page);
                      onSwitchTab(page);
                    }}
                    total={total}
                    pageSize={1}
                  />
                </div>
              </>
            ) : <div className="t-mainlayout_table_text">{t('callCenter.nulllist')}</div>}
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default SocketDataModal;
