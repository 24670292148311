import axiosInstance from '../common/instance';

import { SecondPrizeCancelDataType, SecondPrizeCreateDataType, SecondPrizeDataType } from './types';

export const getAllSecondPrizeService = async (params?: CountingPrizeParamsType):
  Promise<SecondPrizeDataType> => {
  const res = await axiosInstance.get('second-prizes/count', { params });
  return res.data.data;
};

export const cancelSecondPrizeService = async (params: SecondPrizeCancelDataType) => {
  const formData = new FormData();
  if (params.file) {
    formData.append('file', params.file);
  }
  formData.append('reason', params.reason);
  const res = await axiosInstance.post('second-prizes/cancel', formData);
  return res.data;
};

export const createSecondPrizeService = async (params: SecondPrizeCreateDataType) => {
  const formData = new FormData();
  if (params.file) {
    formData.append('file', params.file);
  }
  formData.append('quantity', params.quantity.toString());
  const res = await axiosInstance.post('second-prizes/import', formData);
  return res.data;
};
