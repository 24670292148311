import { EyeOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import ModalAttemptDetail from './modalAttemptDetail';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import { StatusTopUpAttemptLabel } from 'common/components/StatusLabel';
import { getTopupAttemptList } from 'common/services/topupSMS';
import { TopUpAttemptItemTypes } from 'common/services/topupSMS/types';
import { formatDateTime } from 'common/utils/functions';

const defaultAttemptObj = {
  id: '',
  requestId: '',
  status: 0,
  response: undefined,
  postbackParams: '',
  postbackAt: '',
  createdAt: '',
  updatedAt: '',
};

const TopUpAttemptManagement: React.FC<ActiveRoles> = ({ roleIndex }) => {
  /* Hooks */
  const { t } = useTranslation();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get('page');
  const idParam = searchParams.get('id') || '';

  /* Selectors */
  const { defaultPageSize } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [openId, setOpenId] = useState<string>('');

  /* React-query */
  const { isLoading: listLoading, data: listData } = useQuery(
    ['topup-attempt-list', currentPage, currentView],
    () => getTopupAttemptList({
      id: idParam,
      params: { page: currentPage, limit: currentView },
    }),
    { keepPreviousData: true, enabled: !!currentPage && !!idParam }
  );

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const attemptDetail = useMemo(
    () => (listData
      ? listData.data.find((i) => i.topupAttemptData.id === openId)
        ?.topupAttemptData || defaultAttemptObj
      : defaultAttemptObj),
    [listData, openId]
  );

  /* Datas */
  const columns: ColumnsType<TopUpAttemptItemTypes> = useMemo(
    () => [
      // --- STT
      {
        title: 'ID',
        key: 'id',
        width: 75,
        align: 'center',
        render: (_name: string, data: TopUpAttemptItemTypes) => (
          <Typography.Text>{data.id}</Typography.Text>
        ),
      },
      // --- Tên người dùng
      {
        title: 'requestId',
        dataIndex: 'requestId',
        key: 'requestId',
        render: (_name: string, data: TopUpAttemptItemTypes) => (
          <Typography.Text>{data.requestId}</Typography.Text>
        ),
      },
      {
        title: t('system.status'),
        dataIndex: 'status',
        key: 'status',
        sorter: {
          compare: (a: TopUpAttemptItemTypes, b: TopUpAttemptItemTypes) => a.status - b.status,
        },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopUpAttemptItemTypes) => (
          <StatusTopUpAttemptLabel status={data.status} />
        ),
      },
      {
        title: t('system.responseCode'),
        dataIndex: 'responseCode',
        key: 'responseCode',
        align: 'center',
        sorter: {
          compare: (
            a: TopUpAttemptItemTypes,
            b: TopUpAttemptItemTypes
          ) => (a?.response?.data?.code || 0) - (b?.response?.data?.code || 0),
        },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopUpAttemptItemTypes) => (
          <Typography.Text>{data.response?.data?.code}</Typography.Text>
        ),
      },
      // --- Cập nhật
      {
        title: t('system.createdAt'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: {
          compare: (a: TopUpAttemptItemTypes, b: TopUpAttemptItemTypes) => {
            const aDate = new Date(a.createdAt || 0);
            const bDate = new Date(b.createdAt || 0);
            return aDate.getTime() - bDate.getTime();
          },
        },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopUpAttemptItemTypes) => (
          <Typography.Text>{formatDateTime(data.createdAt)}</Typography.Text>
        ),
      },
      {
        title: t('system.updatedAt'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        sorter: {
          compare: (a: TopUpAttemptItemTypes, b: TopUpAttemptItemTypes) => {
            const aDate = new Date(a.updatedAt || 0);
            const bDate = new Date(b.updatedAt || 0);
            return aDate.getTime() - bDate.getTime();
          },
        },
        sortDirections: ['descend', 'ascend'],
        render: (_name: string, data: TopUpAttemptItemTypes) => (
          <Typography.Text>{formatDateTime(data.updatedAt)}</Typography.Text>
        ),
      },
      // --- Xem
      {
        title: t('system.action'),
        key: 'action',
        width: 100,
        align: 'center',
        render: (_name: string, data: TopUpAttemptItemTypes) => (
          <Space>
            <Button
              disabled={!roleIndex}
              icon={<EyeOutlined />}
              onClick={() => setOpenId(data.id)}
            />
          </Space>
        ),
      },
    ],
    [t]
  );

  const tableData: TopUpAttemptItemTypes[] = useMemo(
    () => listData?.data?.map((item) => ({
      ...item.topupAttemptData,
      createdAt: item.topupAttemptData.createdAt || '',
    })) || [],
    [listData]
  );

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage fixed title={t('sidebar.topupAttempt')} />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          noCheckbox
          tableProps={{
            initShowColumns: [
              'id',
              'requestId',
              'status',
              'responseCode',
              'createdAt',
              'updatedAt',
              'action',
            ],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
          }}
        />
      </div>
      <ModalAttemptDetail
        isOpen={!!openId}
        info={attemptDetail}
        handleClose={() => setOpenId('')}
      />
    </>
  );
};

export default TopUpAttemptManagement;
