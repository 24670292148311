import { ParamQuotasTypes, QuotasDataTypes, QuotasHistoryDataTypes } from './types';

import axiosInstance from 'common/services/common/instance';

export const getTopupQuotasService = async (params?: ParamQuotasTypes):
  Promise<APIPaginationResponse<QuotasDataTypes[]>> => {
  const res = await axiosInstance.get('topup-quotas', { params });
  return res.data;
};

export const getTopupQuotasHistoryService = async (params?: ParamQuotasTypes):
  Promise<APIPaginationResponse<QuotasHistoryDataTypes[]>> => {
  const res = await axiosInstance.get('topup-quotas/histories', { params });
  return res.data;
};

export const updateTopupQuotasService = async (data: { id: number, quota: number }) => {
  const { id, quota } = data;
  await axiosInstance.put(`topup-quotas/${id}`, { quota });
};

export const toggleActiveTopupQuotasService = async (data: { id: number, isActive: boolean }) => {
  const { id, isActive } = data;
  await axiosInstance.put(`topup-quotas/${id}/toggle-active`, { isActive });
};
