import {
  Card, Col, Modal, Row, Spin, Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { StatusPrizeExchangeLabel } from 'common/components/StatusLabel';
import { getPrizeExchangeById } from 'common/services/prizeExchange';
import { formatDateTime } from 'common/utils/functions';

interface Props {
  openId?: number;
  handleClose(): void;
}

const DetailInfoByPhoneModal: React.FC<Props> = ({ handleClose, openId }) => {
  const { t } = useTranslation();

  const { data: detailData, isLoading: detailLoading } = useQuery(
    ['get-prizeExchange-detail-modal-by-phone', openId],
    () => getPrizeExchangeById(openId!),
    {
      enabled: !!openId
    }
  );

  const detectPrize = (prize: number) => {
    switch (prize) {
      case 1:
        return t('prizeExchange.firstPrize');
      case 2:
        return t('prizeExchange.secondPrize');

      default:
        return prize;
    }
  };

  return (
    <Modal width="60vw" visible={!!openId} closable footer={null} onCancel={handleClose}>
      <Spin spinning={detailLoading}>
        <Card title={t('prizeExchange.customerInfo')} style={{ marginTop: '16px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.customerName')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.name}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.address')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.address}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.contactedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.contactedAt)}
              </Typography.Text>
            </Col>
          </Row>
        </Card>
        <Card title={t('prizeExchange.prizeInfo')} style={{ marginTop: '16px' }}>
          <Row gutter={16}>
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.prize')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detectPrize(detailData?.prizeExchangeData.prize || 0)}
              </Typography.Text>
            </Col>
            <Col span={12} style={{ display: 'flex' }}>
              <Typography.Text strong>
                {t('system.status')}
                :
              </Typography.Text>
              {detailData && (
                <div style={{ flex: 1, marginLeft: '.25rem' }}>
                  <StatusPrizeExchangeLabel status={detailData?.prizeExchangeData.status} />
                </div>
              )}
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('system.createdAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.createdAt)}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('system.updatedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.updatedAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.documentReviewedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.documentReviewedAt)}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.reportedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.reportedAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.verifiedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.verifiedAt)}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.exchangedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.exchangedAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.cancelReason')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.cancelReason}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.canceledAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.canceledAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.documentReviewedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.documentReviewedAt)}
              </Typography.Text>
            </Col>
            <Col span={12}>
              <Typography.Text strong>
                {t('filterField.hardDocumentReceivedAt')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {formatDateTime(detailData?.prizeExchangeData.hardDocumentReceivedAt)}
              </Typography.Text>
            </Col>
          </Row>
          <Row gutter={16} className="u-mt-8">
            <Col span={12}>
              <Typography.Text strong>
                {t('prizeExchange.ccAgentNote')}
                :
              </Typography.Text>
              <Typography.Text className="u-ml-4">
                {detailData?.prizeExchangeData.ccAgentNote}
              </Typography.Text>
            </Col>
          </Row>
        </Card>
      </Spin>
    </Modal>
  );
};

export default DetailInfoByPhoneModal;
