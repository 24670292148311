import { PlusOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Typography, Modal, message
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps,
  FilterValueProps,
  mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter,
  mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import { createCashFlowService, getCashFlowsService } from 'common/services/cashFlow';
import { CashFlowItemTypes, CashFlowRequestType } from 'common/services/cashFlow/types';
import { formatDateTime, formatNumberWithSeparators, formatPlainNumber } from 'common/utils/functions';
import { createCashFlowData } from 'common/utils/schemas';

type FormType = {
  amount: string;
};
const CashFlowManagement: React.FC<ActiveRoles> = ({ roleCreate }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  /* Form Method */
  const method = useForm<FormType>({
    mode: 'onSubmit',
    defaultValues: {
      amount: '0'
    },
    resolver: yupResolver(createCashFlowData),
  });

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [isOpenCreateModal, setIsOpenCreateModal] = useState(false);
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* React-query */
  const queryKeyGetList = ['cashflow-list', currentPage, currentView, selectedFilterList];
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    queryKeyGetList,
    () => getCashFlowsService({ page: currentPage, limit: currentView, ...returnParamsFilter }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  const { mutate: createCashMutate } = useMutation(
    ['create-cash'],
    (data: { amount: number }) => createCashFlowService(data),
    {
      onSuccess() {
        setIsOpenCreateModal(false);
        method.reset();
        queryClient.invalidateQueries(queryKeyGetList);
      },
      onError: (err: ErrorResponse[]) => {
        if (err.length > 0) {
          err.forEach((
            e
          ) => method.setError(e.field as keyof CashFlowRequestType, { message: t(`errorMess.${e.field}.${e.code}`) }));
        }
        message.error(t('message.createError'));
      },
    }
  );

  /* Datas */
  const columns: ColumnsType<CashFlowItemTypes> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      sorter: {
        compare: (a: CashFlowItemTypes, b: CashFlowItemTypes) => a.id - b.id,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CashFlowItemTypes) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên người dùng
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: {
        compare: (a: CashFlowItemTypes, b: CashFlowItemTypes) => a.amount - b.amount,
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CashFlowItemTypes) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.amount)}
        </Typography.Text>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (_name: string, data: CashFlowItemTypes) => (
        <Typography.Text>
          {formatNumberWithSeparators(data.balance)}
        </Typography.Text>
      ),
    },
    // --- Cập nhật
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: CashFlowItemTypes, b: CashFlowItemTypes) => {
          const aDate = new Date(a.createdAt || 0);
          const bDate = new Date(b.createdAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CashFlowItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: CashFlowItemTypes, b: CashFlowItemTypes) => {
          const aDate = new Date(a.updatedAt || 0);
          const bDate = new Date(b.updatedAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: CashFlowItemTypes) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
  ]), [t, navigate]);

  const tableData: CashFlowItemTypes[] = useMemo(() => (
    listData?.data?.map((item) => ({
      ...item.cashflowData,
    })) || []), [listData]);

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
    handleSetCurrentPage(1);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
    handleSetCurrentPage(1);
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('cashflow', advancedFilter),
    [advancedFilter]
  );

  const onSubmit = () => {
    const value = method.getValues();
    console.log('🚀 ~ file: index.tsx:223 ~ onSubmit ~ value:', value);
    createCashMutate({ amount: formatPlainNumber(value.amount) });
  };

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.cashFlowsManagement')}
        rightHeader={(
          <Button
            type="primary"
            disabled={!roleCreate}
            onClick={() => setIsOpenCreateModal(true)}
          >
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading}
          noCheckbox
          handleSearch={() => undefined}
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          tableProps={{
            initShowColumns: ['id', 'amount', 'balance', 'createdAt', 'updatedAt'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields
          }}
        />
      </div>
      <Modal
        title="Creating new cash"
        visible={isOpenCreateModal}
        closable
        onOk={method.handleSubmit(onSubmit)}
        onCancel={() => {
          setIsOpenCreateModal(false);
          method.reset();
        }}
      >
        <FormProvider {...method}>
          <Controller
            name="amount"
            control={method.control}
            render={({ field, fieldState: { error } }) => (
              <>
                Amount
                <Input
                  {...field}
                  error={error?.message}
                  onChange={(e) => {
                    const val = formatNumberWithSeparators(formatPlainNumber(e.target.value));
                    field.onChange(val);
                  }}
                />
              </>
            )}
          />
        </FormProvider>
      </Modal>
    </>
  );
};

export default CashFlowManagement;
