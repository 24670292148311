import { SaveOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Card, Col, message, Row, Space, Spin, Typography
} from 'antd';
import moment from 'moment';
import React, {
  useEffect, useMemo, useState
} from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useMutation, useQuery, useQueryClient
} from 'react-query';
import {
  useNavigate,
  useSearchParams
} from 'react-router-dom';

import { DropdownElement } from 'common/components/DropdownType';
import HeaderPage from 'common/components/HeaderPage';
import Input from 'common/components/Input';
import ManagementInfo from 'common/components/ManagementInfo';
import StatusLabel, { StatusButtons } from 'common/components/StatusLabel';
import {
  createSaleService, getDetailSaleService, updateSaleService, updateSaleStatusService
} from 'common/services/sales';
import { SaleRequestDataTypes } from 'common/services/sales/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { updateSaleSchema } from 'common/utils/schemas';
import roles from 'configs/roles';

type SaleFormTypes = Omit<SaleRequestDataTypes, 'provinceCodes'> &
{ provinceCodes: string[] };
// type SaleFormTypes = SaleRequestDataTypes;

const EditSale: React.FC<ActiveRoles> = ({ roleCreate, roleUpdate, roleOther }) => {
  /* Hooks */
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  /* States */
  const idParams = Number(searchParams.get('id'));

  const [status, setStatus] = useState<number>(1);

  /* Variables */
  const defaultValues = {
    phone: '',
    bankAccountNumber: '',
    provinceCodes: [],
    name: '',
    bankName: '',
  };

  const method = useForm<SaleFormTypes>({
    resolver: yupResolver(updateSaleSchema),
    defaultValues
  });
  const { isDirty } = method.formState;

  /* Queries */
  const { data: saleByIdData, isLoading } = useQuery(
    ['getSaleById', idParams],
    () => {
      if (idParams) {
        return getDetailSaleService(idParams);
      }
      return undefined;
    },
    {
      enabled: !!idParams,
      refetchOnMount: 'always',
    }
  );

  const { mutate: updateSaleByIdMutate, isLoading: updateLoading } = useMutation(
    'updateSaleById',
    async (data: {
      id: number;
      reqData: SaleRequestDataTypes
    }) => updateSaleService(data),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        queryClient.invalidateQueries(['getSaleById', idParams]);
      },
      onError: (error: any) => {
        if (error.length > 0) {
          error.forEach((element: ErrorResponse) => {
            method.setError((element.field as any), { message: element.message });
          });
        } else message.error(t('message.anErrorHasOccurred'));
      }
    }
  );

  const { mutate: createSaleByIdMutate, isLoading: createLoading } = useMutation(
    'createNews',
    createSaleService,
    {
      onSuccess: () => {
        message.success(t('message.createSuccess'));
        navigate(`${ROUTE_PATHS.SALE_MANAGEMENT}`);
      },
      onError: (err: any) => {
        if (err.length > 0) {
          err.forEach((
            e: ErrorResponse
          ) => method.setError(e.field as keyof SaleFormTypes, { message: e.message }));
        }
        message.error(t('message.createError'));
      }
    }
  );

  const { mutate: updateStatusMutate, isLoading: updateStatusLoading } = useMutation(
    ['updateSaleStatus', idParams],
    async (data: {
      id: number, status: number
    }) => updateSaleStatusService(data),
    {
      onSuccess: (_, params) => {
        setStatus(params.status);
        message.success(t('message.updateStatusSuccess'));
        queryClient.invalidateQueries(['getSaleById', idParams]);
      },
      onError: () => {
        message.error(t('message.updateStatusError'));
      }
    }
  );
  /* Functions */
  const onSubmit = async (addingFn?: (id: number) => void) => {
    const isValid = await method.trigger();
    if (!isValid) {
      return;
    }
    const formData = method.getValues();

    const saleData: SaleRequestDataTypes = {
      ...formData,
      provinceCodes: formData.provinceCodes
    };
    if (idParams) {
      updateSaleByIdMutate({
        id: idParams,
        reqData: saleData
      });
    } else {
      createSaleByIdMutate(saleData, {
        onSuccess: (data) => addingFn && addingFn(data.id),
      });
    }
  };

  const handleChangeStatusAndSave = async (stat: number) => {
    if (!idParams) {
      await onSubmit((id) => {
        updateStatusMutate({ id, status: stat });
      });
    } else {
      if (isDirty) {
        await onSubmit();
      }
      updateStatusMutate({ id: idParams, status: stat });
    }
  };

  const submitForm = async () => {
    onSubmit();
    queryClient.invalidateQueries(['getSaleById', idParams]);
  };

  const exceptionSlugs = useMemo(() => saleByIdData?.provinces.map((item) => item.code).join(','), [saleByIdData]);

  /* Effects */
  useEffect(() => {
    if (saleByIdData) {
      const {
        saleData: {
          name, bankName, bankAccountNumber, phone, status: stt
        }, provinces
      } = saleByIdData;
      setStatus(stt);
      const objDefault = {
        name,
        bankName,
        bankAccountNumber,
        phone,
        provinceCodes: provinces.map((item) => item.code)
      };
      method.reset(objDefault);
    } else method.reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saleByIdData]);

  /* Render */
  return (
    <>
      <HeaderPage
        fixed
        title={t('newsDetail.editNewsCategory')}
        rightHeader={(
          <Space size={16}>
            {status && (
              <StatusButtons
                loading={updateStatusLoading}
                status={status}
                isApprove={roleOther.includes(roles.SALE_APPROVED)}
                handleChangeStatus={(stat) => {
                  handleChangeStatusAndSave(stat);
                }}
              />
            )}
            {status !== 13 && (
              <Button
                type="primary"
                disabled={(idParams && !roleUpdate) || (!idParams && !roleCreate)}
                loading={updateLoading || createLoading}
                onClick={method.handleSubmit(submitForm)}
              >
                <SaveOutlined />
                {t('system.save')}
              </Button>
            )}
          </Space>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <FormProvider {...method}>
          <Spin size="large" spinning={isLoading || updateLoading || createLoading}>
            <Row gutter={16} style={{ margin: 0 }}>
              <Col xxl={18} xl={16} lg={16}>
                <Space direction="vertical" size={12} style={{ width: '100%' }}>
                  <Card type="inner">
                    {/* Tiêu đề  */}
                    <Row gutter={16}>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input">
                          <Typography.Text strong>
                            {t('system.name')}
                            {' '}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="name"
                            control={method.control}
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input">
                          <Typography.Text strong>
                            {t('system.phone')}
                            {' '}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="phone"
                            control={method.control}
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input">
                          <Typography.Text strong>
                            {t('system.bankName')}
                            {' '}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="bankName"
                            control={method.control}
                            defaultValue=""
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="p-editPageTemplate_input">
                          <Typography.Text strong>
                            {t('system.bankAccountNumber')}
                            {' '}
                          </Typography.Text>
                          <Typography.Text strong type="danger">
                            *
                          </Typography.Text>
                          <Controller
                            name="bankAccountNumber"
                            defaultValue=""
                            control={method.control}
                            render={({
                              field: { value, onChange },
                              fieldState: { error },
                            }) => (
                              <Input
                                className="u-mt-8"
                                name="slug"
                                value={value}
                                onChange={onChange}
                                error={error?.message}
                                size="large"
                              />
                            )}
                          />
                        </div>
                      </Col>
                      <Col span={24} className="u-mt-10">
                        <Typography.Text strong>
                          {t('provinceLeader.provinceCode')}
                        </Typography.Text>
                        <Typography.Text strong type="danger">
                          *
                        </Typography.Text>
                        <Controller
                          name="provinceCodes"
                          render={({
                            field: { value, onChange },
                            fieldState: { error },
                          }) => (
                            <DropdownElement
                              type="province"
                              placeholder="-----"
                              locale="vi"
                              value={value}
                              onChange={onChange}
                              error={error?.message}
                              isValueSlug
                              isShowSearch
                              slugs={exceptionSlugs}
                              multiple={{
                                allowClear: true,
                              }}
                            />
                          )}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Space>
              </Col>
              <Col xxl={6} xl={8} lg={8}>
                <div className="u-mb-16">
                  {status && <StatusLabel status={status} bigger />}
                </div>
                <ManagementInfo
                  createdDate={saleByIdData ? moment(saleByIdData.saleData?.createdAt || '').fromNow() : ''}
                  createdBy={saleByIdData?.creator?.name || ''}
                  lastUpdated={saleByIdData ? moment(saleByIdData.saleData?.updatedAt || '').fromNow() : ''}
                  lastUpdatedBy={saleByIdData?.updater?.name || ''}
                />
              </Col>
            </Row>
          </Spin>
        </FormProvider>
      </div>
    </>
  );
};

export default EditSale;
