import {
  Card,
  Col, Modal, Row, Space, Typography
} from 'antd';
import { useTranslation } from 'react-i18next';

import { StatusTopUpAttemptLabel } from 'common/components/StatusLabel';
import { TopUpAttemptItemTypes } from 'common/services/topupSMS/types';
import { formatDateDDMMYYYY } from 'common/utils/functions';

interface ModalAttemptDetailProps {
  info: TopUpAttemptItemTypes;
  isOpen?: boolean;
  handleClose: () => void;
}

const FlexRow: React.FC<{
  title?: string,
  content?: string,
  isStatus?: boolean
}> = ({ title, content, isStatus }) => (
  <div style={{
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center'
  }}
  >
    <Typography.Text
      strong
      style={{
        flexBasis: 'fit-content', whiteSpace: 'nowrap', fontSize: '14px', lineHeight: '22px'
      }}
    >
      {title}
      :
    </Typography.Text>
    {isStatus ? (
      <div className="u-ml-12">
        <StatusTopUpAttemptLabel status={Number(content)} />
      </div>
    ) : (
      <Typography.Text className="u-ml-12" style={{ flexBasis: '80%', fontSize: '14px', lineHeight: '22px' }}>
        {content}
      </Typography.Text>
    )}
  </div>
);

const ModalAttemptDetail: React.FC<ModalAttemptDetailProps> = ({
  isOpen, info, handleClose
}) => {
  const { t } = useTranslation();
  const {
    requestId, status, response, postbackParams, postbackAt, createdAt, updatedAt
  } = info;
  return (
    <Modal
      className="modal-detail"
      title={<Typography.Title level={3}>{t('topupAttempt.title')}</Typography.Title>}
      visible={isOpen}
      centered
      width="50%"
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleClose}
      onCancel={handleClose}
    >
      {/* <Spin size="large" spinning={isLoading}> */}
      <Row gutter={16}>
        <div className="site-card-border-less-wrapper">
          <Space direction="vertical" size={12} style={{ width: '100%' }}>
            <Card title={(
              <Typography.Text
                strong
                style={{
                  fontSize: '14px', lineHeight: '22px'
                }}
              >
                {t('topupAttempt.general')}
              </Typography.Text>
            )}
            >
              <Row gutter={16}>
                <Col>
                  <FlexRow
                    title="requestId"
                    content={requestId}
                  />
                </Col>
                <Col className="u-mt-8">
                  <FlexRow
                    title={t('topupAttempt.status')}
                    content={status.toString()}
                    isStatus
                  />
                </Col>
                <Col className="u-mt-8">
                  <FlexRow
                    title={t('topupAttempt.postbackParams')}
                    content={postbackParams}
                  />
                </Col>
                <Col className="u-mt-8">
                  <FlexRow
                    title={t('topupAttempt.createdAt')}
                    content={formatDateDDMMYYYY(createdAt)}
                  />
                </Col>
                <Col className="u-mt-8">
                  <FlexRow
                    title={t('topupAttempt.updatedAt')}
                    content={formatDateDDMMYYYY(updatedAt)}
                  />
                </Col>
                <Col className="u-mt-8">
                  <FlexRow
                    title={t('topupAttempt.postbackAt')}
                    content={formatDateDDMMYYYY(postbackAt)}
                  />
                </Col>

              </Row>
            </Card>
            <Card title={(
              <Typography.Text
                strong
                style={{
                  fontSize: '14px', lineHeight: '22px'
                }}
              >
                {t('topupAttempt.response')}
              </Typography.Text>
            )}
            >
              <Row gutter={16}>
                <Col className="u-mt-8">
                  <FlexRow
                    title={t('topupAttempt.status')}
                    content={response?.data.status}
                  />
                </Col>
                {
                  response?.data.code && (
                    <Col className="u-mt-8">
                      <FlexRow
                        title="message"
                        content={t(`topupAttempt.message${response?.data.code}`)}
                      />
                    </Col>
                  )
                }
                <Col className="u-mt-8">
                  <FlexRow
                    title={t('topupAttempt.successAt')}
                    content={formatDateDDMMYYYY(response?.data.successAt)}
                  />
                </Col>
              </Row>
            </Card>
          </Space>
        </div>
      </Row>
      {/* </Spin> */}
    </Modal>
  );
};

export default ModalAttemptDetail;
