import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Space,
  Typography,
  message
} from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ColumnsType } from 'antd/lib/table';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAppSelector } from 'app/store';
import HeaderPage from 'common/components/HeaderPage';
import PageTable from 'common/components/PageTable';
import {
  FilterDataProps,
  FilterValueProps,
  mappingFilterFields,
  mappingFilterToQuery,
  mappingParamsFilter,
  mappingQueryParamsFilter
} from 'common/components/PageTable/filter';
import StatusLabel from 'common/components/StatusLabel';
import { deleteSaleService, getSaleListService } from 'common/services/sales';
import { SaleDataTypes } from 'common/services/sales/types';
import { ROUTE_PATHS } from 'common/utils/constant';
import { formatDateTime } from 'common/utils/functions';

type SaleProps = Omit<SaleDataTypes['saleData'], 'bankAccountNumber'>;

const SaleManagement: React.FC<ActiveRoles> = ({ roleCreate, roleIndex, roleDelete }) => {
  /* Hooks */
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  /* Search Params */
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useMemo(() => {
    const paramsObj = { ...Object.fromEntries(searchParams.entries()) };
    if (searchParams.has('page')) delete paramsObj.page;
    return paramsObj;
  }, [searchParams]);
  const pageParam = searchParams.get('page');

  /* Selectors */
  const { defaultPageSize, advancedFilter } = useAppSelector((state) => state.system);

  /* States */
  const [currentPage, setCurrentPage] = useState(Number(pageParam));
  const [currentView, setCurrentView] = useState(defaultPageSize);
  const [deleteId, setDeleteId] = useState<number>(0);

  const [keyword, setKeyword] = useState('');
  const [selectedFilterList, setSelectedFilterList] = useState<
    FilterValueProps[]>(mappingQueryParamsFilter(params));

  const returnParamsFilter = useMemo(
    () => mappingParamsFilter(selectedFilterList),
    [selectedFilterList]
  );

  /* React-query */
  const {
    isLoading: listLoading,
    data: listData,
  } = useQuery(
    [
      'salesManagement-list',
      currentPage,
      keyword,
      selectedFilterList,
      currentView
    ],
    () => getSaleListService({
      page: currentPage,
      keyword,
      limit: currentView,
      ...returnParamsFilter
    }),
    { keepPreviousData: true, enabled: !!currentPage }
  );

  const {
    mutate: deleteMutate,
    isLoading: deleteLoading
  } = useMutation(
    ['deleteSale'],
    (ids: number[]) => deleteSaleService(ids),
    {
      onSuccess() {
        message.success(t('message.deleteSuccess'));
        setDeleteId(0);
        queryClient.invalidateQueries(['salesManagement-list', currentPage, keyword]);
      },
      onError() {
        message.success(t('message.deleteError'));
      },
    }
  );

  /* Functions */
  const handleSearch = (val: string) => {
    setKeyword(val);
  };

  const handleSetCurrentPage = (page: number) => {
    setCurrentPage(page);
    setSearchParams({ page: page.toString() });
  };

  const handleSetCurrentView = (view: number) => {
    setCurrentView(view);
  };

  /* Datas */
  const columns: ColumnsType<SaleProps> = useMemo(() => ([
    // --- STT
    {
      title: 'ID',
      key: 'id',
      width: 75,
      align: 'center',
      render: (_name: string, data: SaleProps) => (
        <Typography.Text>
          {data.id}
        </Typography.Text>
      ),
    },
    // --- Tên người dùng
    {
      title: t('system.name'),
      dataIndex: 'name',
      key: 'name',
      render: (_name: string, data: SaleProps) => (
        <Typography.Text>
          {data.name}
        </Typography.Text>
      ),
    },
    // --- Phone
    {
      title: t('system.phone'),
      dataIndex: 'phone',
      key: 'phone',
      render: (_name: string, data: SaleProps) => (
        <Typography.Text>
          {data.phone}
        </Typography.Text>
      ),
    },
    // --- Bank name
    {
      title: t('provinceLeader.bankName'),
      dataIndex: 'bankName',
      key: 'bankName',
      render: (_name: string, data: SaleProps) => (
        <Typography.Text>
          {data.bankName}
        </Typography.Text>
      ),
    },
    // --- Status
    {
      title: t('system.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_name: string, data: SaleProps) => (
        <StatusLabel status={data.status} />
      ),
    },
    // --- Cập nhật
    {
      title: t('system.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: {
        compare: (a: SaleProps, b: SaleProps) => {
          const aDate = new Date(a.createdAt || 0);
          const bDate = new Date(b.createdAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: SaleProps) => (
        <Typography.Text>
          {formatDateTime(data.createdAt)}
        </Typography.Text>
      ),
    },
    {
      title: t('system.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      sorter: {
        compare: (a: SaleProps, b: SaleProps) => {
          const aDate = new Date(a.updatedAt || 0);
          const bDate = new Date(b.updatedAt || 0);
          return aDate.getTime() - bDate.getTime();
        },
      },
      sortDirections: ['descend', 'ascend'],
      render: (_name: string, data: SaleProps) => (
        <Typography.Text>
          {formatDateTime(data.updatedAt)}
        </Typography.Text>
      ),
    },
    // --- Xem
    {
      title: t('system.edit'),
      key: 'action',
      width: 100,
      align: 'center',
      render: (_name: string, data: SaleProps) => (
        <Space>
          <Button
            disabled={!roleIndex}
            icon={<EditOutlined />}
            onClick={() => {
              navigate(`${ROUTE_PATHS.SALE_DETAIL}?id=${data.id}`);
            }}
          />
          <Button
            disabled={!roleDelete}
            icon={<DeleteOutlined />}
            onClick={() => setDeleteId(data.id)}
          />
        </Space>
      ),
    },
  ]), [t, navigate, roleDelete, roleIndex]);

  const tableData: SaleProps[] = useMemo(() => (
    listData?.data.map((item) => ({
      ...item.saleData,
    })) || []), [listData]);

  const handleFilter = (data: FilterValueProps) => {
    const typeFilter = String(data.filter).split('.')[1];
    if ((typeFilter === 'isNull' || typeFilter === 'isNotNull') && selectedFilterList.find((item) => item.key === data.key)) {
      return;
    }
    const counter = selectedFilterList.filter(
      (item) => item.field === data.field && item.filter === data.filter
    ).length;
    setSelectedFilterList([...selectedFilterList, { ...data, index: counter }]);
    handleSetCurrentPage(1);
  };

  const handleDeleteFilter = (key: string, index?: number) => {
    const tempList = selectedFilterList.slice();
    setSelectedFilterList(tempList.filter((item) => !(item.key === key && item.index === index)));
    handleSetCurrentPage(1);
  };

  const filterFields: FilterDataProps[] = useMemo(
    () => mappingFilterFields('sale', advancedFilter),
    [advancedFilter]
  );

  useEffect(() => {
    setSearchParams({
      ...mappingFilterToQuery(selectedFilterList),
      ...pageParam && { page: pageParam }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterList]);

  useEffect(() => {
    if (pageParam) return setCurrentPage(Number(pageParam));
    return setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderPage
        fixed
        title={t('sidebar.activityLogs')}
        rightHeader={(
          <Button type="primary" disabled={!roleCreate} onClick={() => navigate(`${ROUTE_PATHS.SALE_DETAIL}`)}>
            <PlusOutlined />
            {t('system.create')}
          </Button>
        )}
      />
      <div className="t-mainlayout_wrapper">
        <PageTable
          isLoading={listLoading || deleteLoading}
          handleSearch={handleSearch}
          noCheckbox
          filtersDataTable={{
            handleFilter,
            selectedFilterList,
            handleDeleteFilter,
          }}
          tableProps={{
            initShowColumns: ['id', 'name', 'phone', 'bankName', 'status', 'createdAt', 'updatedAt', 'action'],
            columns,
            pageData: tableData,
            currentPage,
            pageSize: currentView,
            handleSetCurrentPage,
            handleSetCurrentView,
            total: listData?.meta.total || 1,
            noBaseCol: true,
            noDeleteLanguage: true,
            filterFields
          }}
        />
      </div>
      <Modal
        visible={!!deleteId}
        onCancel={() => setDeleteId(0)}
        onOk={() => deleteMutate([deleteId])}
      >
        {t('message.confirmDeleteRecord')}
      </Modal>
    </>
  );
};

export default SaleManagement;
