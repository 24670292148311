export const languageList = [
  {
    label: 'Tiếng Việt',
    value: 'vi',
  },
  {
    label: 'English',
    value: 'en',
  },
];

export const samplePage = [...Array(10)].map((_, idx) => ({
  label: `Trang mẫu ${idx + 1}`,
  value: `${idx + 1}`,
}));

export const statusDummy = [
  {
    label: 'Bản nháp',
    value: 1,
  },
  {
    label: 'Gửi duyệt',
    value: 7,
  },
  {
    label: 'Đã duyệt',
    value: 13,
  },
];

export const contactStatusDummy = [
  {
    label: 'Mới',
    value: 1,
  },
  {
    label: 'Chờ duyệt',
    value: 7,
  },
  {
    label: 'Đã duyệt',
    value: 13,
  },
];

export const provinceLeaderStatusDummy = [
  {
    label: 'Active',
    value: 1,
  },
  {
    label: 'Inactive',
    value: 7,
  },
];

export const smsStatusDummy = [
  {
    label: 'topupSMS.validTopUpSMSStatus',
    value: 1,
  },
  {
    label: 'topupSMS.invalidSyntaxTopUpSMSStatus',
    value: 2,
  },
  {
    label: 'topupSMS.invalidCodeTopUpSMSStatus',
    value: 3,
  },
  {
    label: 'topupSMS.invalidTelcoTopUpSMSStatus',
    value: 4,
  },
  {
    label: 'topupSMS.usedCodeTopUpSMSStatus',
    value: 5,
  }
];

export const prizeExchangeStatusDummy = [
  {
    label: 'prizeExchange.pendingStatus',
    value: 1,
  },
  {
    label: 'prizeExchange.exchangingStatus',
    value: 2,
  },
  {
    label: 'prizeExchange.exchangedStatus',
    value: 3,
  },
  {
    label: 'prizeExchange.canceledStatus',
    value: 4,
  },
];

export const prizeExchangePrizeDummy = [
  {
    label: 'prizeExchange.firstPrize',
    value: 1,
  },
  {
    label: 'prizeExchange.secondPrize',
    value: 2,
  },
];

export const telcoDummy = [
  {
    label: 'Viettel',
    value: 1,
  },
  {
    label: 'Vinaphone',
    value: 2,
  },
  {
    label: 'Mobifone',
    value: 3,
  },
  {
    label: 'Vietnamobile',
    value: 4,
  },
  {
    label: 'GTel',
    value: 5,
  },
];

export const topupSmsStatusDummy = [
  {
    label: 'system.processingTopUpStatus',
    value: 1
  },
  {
    label: 'system.successTopUpStatus',
    value: 2
  },
  {
    label: 'system.failedTopUpStatus',
    value: 3
  }
];

export const topupAmountDummy = [
  {
    label: '10',
    value: 10
  },
  {
    label: '20',
    value: 20
  },
  {
    label: '50',
    value: 50
  },
  {
    label: '100',
    value: 100
  },
  {
    label: '200',
    value: 200
  },
];
