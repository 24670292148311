import { InboxOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button, Modal, Space, Typography, message
} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import React, { useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { importCancelThirdAccountTopup } from 'common/services/topupWinner';
import { createData } from 'common/utils/schemas';

interface Props {
  isShow: boolean;
  handleClose: (callback?: () => void) => void;
}

interface FormTypes {
  file?: File[]
}

const PopupImportCancelThirdAccounts: React.FC<Props> = ({ isShow, handleClose }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const method = useForm<FormTypes>({
    resolver: yupResolver(createData),
    mode: 'onChange',
    defaultValues: {
      file: undefined
    }
  });

  const handleCustomClose = () => {
    method.reset();
    handleClose();
  };

  const { mutate: importMutate } = useMutation(
    [''],
    async (params: { file?: File }) => importCancelThirdAccountTopup(params),
    {
      onSuccess: () => {
        message.success(t('message.updateSuccess'));
        handleCustomClose();
        setLoading(false);
      },
      onError: (error) => {
        if (error && Array.isArray(error)) {
          error.forEach((item: ErrorResponse) => {
            if (item.field as keyof FormTypes) {
              const filedData = `${item.field?.charAt(0)?.toUpperCase()}${item.field?.slice(1)}`;
              method.setError(
                item.field as keyof FormTypes,
                { message: `${filedData} ${item.message}` }
              );
            }
          });
        } else {
          handleCustomClose();
        }
      },
    }
  );

  const onSubmit = async () => {
    const formData = method.getValues();
    importMutate({ file: formData?.file?.[0] });
  };

  return (
    <Modal
      title={t('topupPrize.importCancelTransactions')}
      visible={isShow}
      footer={null}
      closable={false}
    >
      <div className="m-modalConfirm">
        <FormProvider {...method}>
          <form noValidate onSubmit={method.handleSubmit(onSubmit)}>
            <div className="u-mt-12">
              <Typography.Text
                style={{ fontSize: 14 }}
                strong
              >
                {t('firstPrize.file')}
              </Typography.Text>
              <Controller
                name="file"
                render={(
                  {
                    field,
                    fieldState: { error },
                  },
                ) => (
                  <Dragger
                    accept=".xls,.xlsx"
                    fileList={field.value}
                    beforeUpload={() => false}
                    onChange={(file) => field.onChange([file.file])}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      {' '}
                      {t('firstPrize.clickFile')}
                    </p>
                    {error && (
                      <span
                        className="a-input_errorMessage"
                      >
                        {error.message}
                      </span>
                    )}
                  </Dragger>
                )}
              />
            </div>
            <Space className="u-mt-12">
              <Button type="primary" loading={loading} htmlType="submit">
                {t('firstPrize.next')}
              </Button>
              <Button onClick={handleCustomClose}>
                {t('firstPrize.cancel')}
              </Button>
            </Space>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default PopupImportCancelThirdAccounts;
